import { clientActionTypes } from "../constants";

export const getAllClients = () => {
  return {
    type: clientActionTypes.GET_ALL_CLIENTS,
  };
};

export const getAllClientsSuccess = (data) => {
  return {
    type: clientActionTypes.GET_ALL_CLIENTS_SUCCESS,
    payload: data,
  };
};

export const getAllClientsError = (error) => {
  return {
    type: clientActionTypes.GET_ALL_CLIENTS_ERROR,
    payload: error,
  };
};

export const getClientReport = (data) => {
  return {
    type : clientActionTypes.GET_CLIENT_REPORT,
    payload: data
  }
}

export const getClientReportSuccess = (data) => {
  return {
    type: clientActionTypes.GET_CLIENT_REPORT_SUCCESS,
    payload: data,
  };
};

export const getClientReportError = (error) => {
  return {
    type: clientActionTypes.GET_CLIENT_REPORT_ERROR,
    payload: error,
  };
};

export const getClientReportClear = () => {
  return {
    type: clientActionTypes.GET_CLIENT_REPORT_CLEAR,
  };
};

// 02-01-23
export const getClientCampaign = (data) => {
  return {
    type: clientActionTypes.GET_CLIENTS_CAMPAIGN,
    payload: data,
  };
};

export const getClientCampaignSuccess = (data) => {
  return {
    type: clientActionTypes.GET_CLIENTS_CAMPAIGN_SUCCESS,
    payload: data,
  };
};

export const getClientCampaignError = (error) => {
  return {
    type: clientActionTypes.GET_CLIENTS_CAMPAIGN_ERROR,
    payload: error,
  };
};

export const getClientCampaignReset = () => {
  return {
    type: clientActionTypes.GET_CLIENTS_CAMPAIGN_RESET,
  };
};


export const getClientBidReport = (data) => {
  return {
    type: clientActionTypes.GET_CLIENTS_BID_REPORT,
    payload: data,
  };
};

export const getClientBidReportSuccess = (data) => {
  return {
    type: clientActionTypes.GET_CLIENTS_BID_REPORT_SUCCESS,
    payload: data,
  };
};

export const getClientBidReportError = (error) => {
  return {
    type: clientActionTypes.GET_CLIENTS_BID_REPORT_ERROR,
    payload: error,
  };
};

export const getClientBidReportReset = () => {
  return {
    type: clientActionTypes.GET_CLIENTS_BID_REPORT_RESET,
  };
};
