import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  ColumnToggle,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { components } from "react-select";
import { MainLoader, Mini } from "./Loader";
import { ClientService } from "../services";
import Datamap from "./datamap";
import { Tooltip } from "react-tooltip";

import moment from "moment";

// const productArray = ["All", "Clicks", "Leads", "Calls"];
const productArray = ["All", "Clicks", "Leads"];
const transactionArray =["All","DR","CR"]
const pageSizeObject = [
  {
    text: "10",
    page: 10,
  },
  {
    text: "25",
    page: 25,
  },
  {
    text: "30",
    page: 30,
  },
  {
    text: "50",
    page: 50,
  },
  {
    text: "100",
    page: 100,
  },
];
const radioOptionsArray = [
  {
    value: "summary",
    label: "Summary Report",
  },
  {
    value: "detail",
    label: "Detail Report",
  },
];

const DynamicTable = ({
  handleFilterGenerateBtn,
  formData,
  filterData,
  pageTitle,
  mode,
  mainLoader,
  filterLoader,
  customColumnsData,
  hideCustomToggleList,
  analyticColumnData,
  ledgerbookdata,
  geoActivityData,
}) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      selectedType: "today",
      fromDate: new Date(),
      toDate: new Date(),
      selectedProduct: "All",
      report_type: "summary",
      campaign_id: "All",
      transaction_type: "CR",
    },
  });
  const { clientCampaignList } = useSelector((state) => state.client);

  const [showFilters, setShowFilters] = useState(false);
  const [campaignIdOption, setCampaignIdOption] = useState([]);

  let selectedProduct = watch("selectedProduct");

  // toggle Filter Btn
  const handleFilterBtn = () => {
    setShowFilters((old) => !old);
  };

  // onChange in date filter
  const getLastMonday = (d) => {
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  };

  const fielddatechange = (timeframe) => {
    setValue("selectedType", timeframe);

    var fromdate;
    var todate;

    if (timeframe === "today") {
      var d = new Date();
      fromdate = d;
      todate = d;
    } else if (timeframe === "yesterday") {
      var d = new Date();
      d.setDate(d.getDate() - 1);
      fromdate = d;
      todate = d;
    } else if (timeframe === "day_bef_yest") {
      var d = new Date();
      d.setDate(d.getDate() - 2);
      fromdate = d;
      todate = d;
    } else if (timeframe === "last_week") {
      var d = new Date();
      d.setDate(d.getDate() - 7);
      var mon = getLastMonday(d);
      fromdate = mon;
      var sun = new Date(mon);
      sun.setDate(mon.getDate() + 6);
      todate = sun;
    } else if (timeframe === "this_week") {
      var d = new Date();
      d.setDate(d.getDate());
      var mon = getLastMonday(d);
      fromdate = mon;
      var sun = new Date();
      sun.setDate(mon.getDate() + 6);
      todate = sun;
    } else if (timeframe === "this_month") {
      fromdate = new Date();
      fromdate.setDate(1);
      var todate = new Date();
      todate = new Date(
        new Date(todate.getFullYear(), todate.getMonth() + 1, 1) - 1
      );
    } else if (timeframe === "last_month") {
      todate = new Date();
      todate = new Date(
        new Date(todate.getFullYear(), todate.getMonth(), 1) - 1
      );

      fromdate = new Date();
      fromdate = new Date(
        new Date(fromdate.getFullYear(), fromdate.getMonth(), 1) - 1
      );
      fromdate.setDate(1);
    } else if (timeframe === "this_year") {
      fromdate = new Date();
      fromdate = new Date(new Date(fromdate.getFullYear(), 0, 1));
      todate = new Date();
      todate = new Date(new Date(todate.getFullYear(), 11, 31));
    }
    setValue("fromDate", fromdate);
    setValue("toDate", todate);
  };

  // get Campaign List Data
  useEffect(() => {
    if (formData && !customColumnsData) {
      dispatch(
        ClientService.getClientCampaignList({
          type: "Active",
        })
      );
    }
  }, [formData, dispatch]);

  useEffect(() => {
    if (clientCampaignList) {
      clientCampaignList?.map((er) => {
        setCampaignIdOption((prev) => [
          ...prev,
          { value: er?.buyer_lead_type_id, label: er?.display_name },
        ]);
      });
    }
  }, [clientCampaignList]);

  useEffect(() => {
    if (selectedProduct) {
      if (clientCampaignList) {
        setCampaignIdOption([]);

        if (geoActivityData) {
          clientCampaignList?.map((er) => {
            setCampaignIdOption((prev) => [
              ...prev,
              { value: er?.buyer_lead_type_id, label: er?.display_name },
            ]);
          });
        } else {
          clientCampaignList?.filter((er) => {
            if (er?.product === selectedProduct) {
              setCampaignIdOption((prev) => [
                ...prev,
                { value: er?.buyer_lead_type_id, label: er?.display_name },
              ]);
            }
          });
        }
      }
    }
  }, [selectedProduct, clientCampaignList]);
  // console.log(
  //   "🚀 ~ file: DynamicTable.js:767 ~ campaignIdOption:",
  //   campaignIdOption
  // );
  // get Filters
  let DateParameter = filterData?.find((er) => er === "date");
  let ProductParameter = filterData?.find((er) => er === "product");
  let CampaignParameter = filterData?.find((er) => er === "campaign_id");
  let reportTypeParameter = filterData?.find((er) => er === "report_type");
  let transactionTypeParameter = filterData?.find(
    (er) => er === "transaction_type"
  );
  // handleGenerate Btn
  const handleGenerateBtn = (data) => {
    // console.log(data, "GenerateBtn");
    handleFilterGenerateBtn({
      fromDate: data.fromDate
        ? moment(new Date(data.fromDate)).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      toDate: data.toDate
        ? moment(new Date(data.toDate)).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      selectedProduct: data.selectedProduct,
      campaign_id: data.campaign_id,
      report_type: data.report_type,
      transaction_type:data?.transaction_type
    });
  };

  // Bootstrap Table Design

  const { ToggleList } = ColumnToggle;

  const { ExportCSVButton } = CSVExport;

  const [columnsData, setColumnsData] = useState([]);
  useEffect(() => {
    if (customColumnsData) {
      setColumnsData(customColumnsData);
    } else if (analyticColumnData) {
      if (getValues("selectedProduct") === "All") {
        const columns = [
          {
            id: "01",
            dataField: "report_date",
            value: "report_date",
            text: "Date",
            label: "Date",
            hidden: getValues("report_type") === "summary" ? true : false,
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            id: "02",
            dataField: "product",
            value: "product",
            text: "Product",
            label: "Product",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            id: "03",
            dataField: "lead_type_name",
            value: "lead_type_name",
            text: "L.O.B",
            label: "L.O.B",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            id: "04",
            dataField: "leadquality",
            value: "leadquality",
            text: "Media Type",
            label: "Media Type",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            id: "05",
            dataField: "Campaign_code",
            value: "Campaign_code",
            text: "Campaign",
            label: "Campaign",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            formatter: (cell, item) => {
              return <>{item.Campaign_code}</>;
            },
            footerFormatter: (cell, row, rowIndex) => `Total :`,
          },
          {
            id: "06",
            dataField: "totalsold",
            value: "totalsold",
            text: "Total # of Actions Leads",
            label: "Total # of Actions Leads",
            footer: "footer 3",
            footerAlign: "center",
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `${formData?.totalresults?.totalsold}`,
          },
          {
            id: "07",
            dataField: "avgCPL",
            value: "avgCPL",
            text: "Avg CPL",
            label: "Avg CPL",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `$${formData?.totalresults?.totalavgCPL}`,
            formatter: (cell, item) => {
              return <p>{`$${item.avgCPL}`}</p>;
            },
            csvFormatter: (cell, row, rowIndex) => `$${row.avgCPL}`,
          },
          {
            id: "08",
            dataField: "WinRate",
            value: "WinRate",
            text: "Win Rate",
            label: "Win Rate",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footer: formData?.totalresults?.totalWinRate,
            footerAlign: "center",
          },
          {
            id: "09",
            dataField: "Clicks",
            value: "Clicks",
            text: "Total # of Actions Clicks",
            label: "Total # of Actions Clicks",
            footer: "footer 3",
            footerAlign: "center",
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `${formData?.totalresults?.totalClicks}`,
          },
          {
            id: "10",
            dataField: "avgCPL",
            value: "avgCPL",
            text: "Avg CPC",
            label: "Avg CPC",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `$${formData?.totalresults?.totalcpc}`,
            formatter: (cell, item) => {
              return <p>{`$${item.CPC}`}</p>;
            },
            csvFormatter: (cell, row, rowIndex) => `$${row.CPC}`,
          },
          {
            id: "11",
            dataField: "avgposcal",
            value: "avgposcal",
            text: "Avg Pos",
            label: "Avg Pos",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `${formData?.totalresults?.totalpos}`,
            formatter: (cell, item) => {
              return <>{item.avgposcal}</>;
            },
            csvFormatter: (cell, row, rowIndex) => `${row.avgposcal}`,
          },

          {
            id: "12",
            dataField: "CTR",
            value: "CTR",
            text: "CTR",
            label: "CTR",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footer: formData?.totalresults?.totalctr,
            footerAlign: "center",
          },

          {
            id: "13",
            dataField: "totalbillable",
            value: "totalbillable",
            text: "Total # of Actions Calls",
            label: "Total # of Actions Calls",
            footer: "footer 3",
            footerAlign: "center",
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `${formData?.totalresults?.totalbillable}`,
          },

          {
            id: "14",
            dataField: "CPQC",
            value: "CPQC",
            text: "CPQC",
            label: "CPQC",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `$${formData?.totalresults?.totalCPQC}`,
            formatter: (cell, item) => {
              return <p>{`$${item.CPQC}`}</p>;
            },
            csvFormatter: (cell, row, rowIndex) => `$${row.CPQC}`,
          },

          {
            id: "15",
            dataField: "avgDuration",
            value: "avgDuration",
            text: "Avg Duration",
            label: "Avg Duration",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footer: formData?.totalresults?.totalcallLengthInSeconds,
            footerAlign: "center",
          },

          {
            id: "16",
            dataField: "soldamount",
            value: "soldamount",
            text: "Total Cost",
            label: "Total Cost",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `$${formData?.totalresults?.totalsoldamount}`,
            formatter: (cell, item) => {
              return <p>{`$${item.soldamount}`}</p>;
            },
            csvFormatter: (cell, row, rowIndex) => `$${row.soldamount}`,
          },

          {
            id: "17",
            dataField: "buyer_name",
            value: "buyer_name",
            text: "Buyer Name",
            label: "Buyer Name",
            sort: true,
            value: "buyer_name",
            hidden: true,
            footer: "",
            footerAlign: "center",
            footerFormatter: (cell, row, rowIndex) => ``,
          },
        ];

        setColumnsData(columns);
      } else if (getValues("selectedProduct") === "Leads") {
        const columns = [
          {
            dataField: "report_date",
            text: "Date",
            label: "Date",
            hidden: getValues("report_type") === "summary" ? true : false,
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "product",
            text: "Product",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "lead_type_name",
            text: "L.O.B",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "leadquality",
            text: "Media Type",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "lead_type_name",
            text: "Campaign",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            formatter: (cell, item) => {
              return (
                <>
                  {item.product}-{item.lead_type_name}-{item.leadquality}
                </>
              );
            },
            footerFormatter: (cell, row, rowIndex) => `Total :`,
          },
          {
            dataField: "totalsold",
            text: "Total # of Actions",
            footer: "footer 3",
            footerAlign: "center",
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `${formData?.totalresults?.totalsold}`,
          },
          {
            dataField: "soldamount",
            text: "Total Cost",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `$${formData?.totalresults?.totalsoldamount}`,
            formatter: (cell, item) => {
              return <p>{`$${item.soldamount}`}</p>;
            },
            csvFormatter: (cell, row, rowIndex) => `$${row.soldamount}`,
          },
          {
            dataField: "avgCPL",
            text: "Avg CPL",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `$${formData?.totalresults?.totalavgCPL}`,
            formatter: (cell, item) => {
              return <p>{`$${item.avgCPL}`}</p>;
            },
            csvFormatter: (cell, row, rowIndex) => `$${row.avgCPL}`,
          },
          {
            dataField: "WinRate",
            text: "Win Rate",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footer: formData?.totalresults?.totalWinRate,
            footerAlign: "center",
          },
        ];

        setColumnsData(columns);
      } else if (getValues("selectedProduct") === "Clicks") {
        const columns = [
          {
            dataField: "report_date",
            text: "Date",
            hidden: getValues("report_type") === "summary" ? true : false,
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "product",
            text: "Product",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "lead_type_name",
            text: "L.O.B",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "leadquality",
            text: "Media Type",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "lead_type_name",
            text: "Campaign",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            formatter: (cell, item) => {
              return (
                <>
                  {item.product}-{item.lead_type_name}-{item.leadquality}
                </>
              );
            },
            footerFormatter: (cell, row, rowIndex) => `Total :`,
          },
          {
            dataField: "Clicks",
            text: "Total # of Actions",
            footer: "footer 3",
            footerAlign: "center",
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `${formData?.totalresults?.totalClicks}`,
          },
          {
            dataField: "Revenue",
            text: "Total Cost",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `$${formData?.totalresults?.totalRevenue}`,
            formatter: (cell, item) => {
              return <p>{`$${item.Revenue}`}</p>;
            },
            csvFormatter: (cell, row, rowIndex) => `$${row.soldamount}`,
          },
          {
            dataField: "CPC",
            text: "Avg CPC",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `$${formData?.totalresults?.totalcpc}`,
            formatter: (cell, item) => {
              return <p>{`$${item.CPC}`}</p>;
            },
            csvFormatter: (cell, row, rowIndex) => `$${row.CPC}`,
          },
          {
            dataField: "CTR",
            text: "CTR",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `$${formData?.totalresults?.totalctr}`,
            formatter: (cell, item) => {
              return <p>{`$${item.CTR}`}</p>;
            },
            csvFormatter: (cell, row, rowIndex) => `$${row.CTR}`,
          },
          {
            dataField: "avgposcal",
            text: "Avg Pos",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `${formData?.totalresults?.totalpos}`,
            formatter: (cell, item) => {
              return <>{item.avgposcal}</>;
            },
            csvFormatter: (cell, row, rowIndex) => `${row.avgposcal}`,
          },
        ];

        setColumnsData(columns);
      } else {
        const columns = [
          {
            dataField: "report_date",
            text: "Date",
            hidden: getValues("report_type") === "summary" ? true : false,
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "product",
            text: "Product",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "lead_type_name",
            text: "L.O.B",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "leadquality",
            text: "Media Type",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
          },
          {
            dataField: "Campaign_code",
            text: "Campaign",
            footer: "",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            formatter: (cell, item) => {
              return <>{item.Campaign_code}</>;
            },
            footerFormatter: (cell, row, rowIndex) => `Total :`,
          },
          {
            dataField: "totalbillable",
            text: "Total # of Actions",
            footer: "footer 3",
            footerAlign: "center",
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `${formData?.totalresults?.totalbillable}`,
          },
          {
            dataField: "soldamount",
            text: "Total Cost",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `$${formData?.totalresults?.totalrevenue}`,
            formatter: (cell, item) => {
              return <p>{`$${item.totalrevenue}`}</p>;
            },
            csvFormatter: (cell, row, rowIndex) => `$${row.totalrevenue}`,
          },
          {
            dataField: "CPQC",
            text: "CPQC",
            footer: "Footer 3",
            footerAlign: "center",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footerFormatter: (cell, row, rowIndex) =>
              `$${formData?.totalresults?.totalCPQC}`,
            formatter: (cell, item) => {
              return <p>{`$${item.CPQC}`}</p>;
            },
            csvFormatter: (cell, row, rowIndex) => `$${row.CPQC}`,
          },
          {
            dataField: "avgDuration",
            text: "Avg Duration",
            headerStyle: (colum, colIndex) => {
              return { textAlign: "center" };
            },
            style: { textAlign: "center" },
            footer: formData?.totalresults?.totalcallLengthInSeconds,
            footerAlign: "center",
          },
        ];
        setColumnsData(columns);
      }
    } else if (ledgerbookdata) {
      const columns = [
        {
          dataField: "date",
          text: "Date",
          headerStyle: (colum, colIndex) => {
            return { textAlign: "center" };
          },
          
        },
        {
          dataField: "description",
          text: "Description",
          headerStyle: (colum, colIndex) => {
            return { textAlign: "center" };
          },
        },
        {
          dataField: "transaction_type",
          text: "Transaction Type",
          headerStyle: (colum, colIndex) => {
            return { textAlign: "center" };
          },
       

        },
        {
          dataField: "Amount",
          text: "Amount",
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { textAlign: "center" };
          },
        },
      ];
      setColumnsData(columns);
    } else {
      if (formData && formData?.results) {
        setColumnsData([]);
        let columns = [];
        let columns2 = [];

        // if Data Found
        if (formData && formData?.results?.length > 0) {
          // table header Condition

          // if totalArray exists
          if (formData?.totalresults) {
            Object?.keys(formData?.results[0])?.map((ed, i) => {
              let data = {
                id: i,
                dataField: ed,
                text: ed,
                label: ed,
                sort: true,
                value: ed,
                hidden: i < 5 ? false : true,
                formatter: (cell, row, rowIndex) => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: cell,
                    }}
                  />
                ),
                csvFormatter: (cell, row, rowIndex) => {
                  var html = `${cell}`;
                  var div = document.createElement("div");
                  div.innerHTML = html;
                  var text = div.textContent || div.innerText || "";

                  return text;
                },
              };

              setColumnsData((prev) => [...prev, data]);
            });

            //  Object?.keys(formData?.totalresults)?.map((tx, i) => {
            //    let data = {
            //      id: i,
            //      footer: "",
            //      footerFormatter: (cell, row, rowIndex) =>
            //        `${formData?.totalresults?.[tx]}`,
            //    };
            //    columns2?.map((er) => {
            //      if (er?.id === i) {
            //        let finalData = { ...er, ...data };
            //        setColumnsData((prev) => [...prev, finalData]);
            //      }
            //    });
            //  });
          }

          // if totalArray Not Exists
          else {
            Object?.keys(formData?.results[0])?.map((ed, i) => {
              let data = {
                id: i,
                dataField: ed,
                value: ed,
                hidden: i < 5 ? false : true,
                formatter: (cell, row, rowIndex) => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: cell,
                    }}
                  />
                ),
                csvFormatter: (cell, row, rowIndex) => {
                  let html = `${cell}`;
                  let div = document.createElement("div");
                  div.innerHTML = html;
                  let text = div.textContent || div.innerText || "";

                  return text;
                },
              };
              columns?.map((er) => {
                if (er?.id === i) {
                  let finalData = { ...er, ...data };
                  setColumnsData((prev) => [...prev, finalData]);
                }
              });
            });
          }
        }
      } else if (formData?.length === 0) {
        setColumnsData([]);
      } else if (
        formData?.value &&
        formData?.value?.newresponsedata?.length > 0
      ) {
        setColumnsData([]);
        let columns = [];
        let columns2 = [];

        Object?.keys(formData?.value?.newresponsedata[0])?.map((ed, i) => {
          let data = {
            id: i,
            dataField: ed,
            text: ed,
            label: ed,
            sort: true,
            value: ed,
            hidden: i < 5 ? false : true,
            formatter: (cell, row, rowIndex) => (
              <div
                dangerouslySetInnerHTML={{
                  __html: cell,
                }}
              />
            ),
            csvFormatter: (cell, row, rowIndex) => {
              let html = `${cell}`;
              let div = document.createElement("div");
              div.innerHTML = html;
              let text = div.textContent || div.innerText || "";

              return text;
            },
          };
          columns2.push(data);
        });

        Object?.keys(formData?.value?.newresponsedata[0])?.map((tx, i) => {
          let data = {
            id: i,
            footer: "",
            footerFormatter: (cell, row, rowIndex) =>
              `${formData?.totalArray?.[tx]}`,
          };
          columns2?.map((er) => {
            if (er?.id === i) {
              let finalData = { ...er, ...data };
              setColumnsData((prev) => [...prev, finalData]);
            }
          });
        });
      } else if (formData && formData?.responsedata?.length === 0) {
        setColumnsData([]);
      }
    }
  }, [formData]);

  // console.log(formData, columnsData);

  const sizePerPageRenderer = ({ currSizePerPage, onSizePerPageChange }) => (
    <div className="btn-group" role="group">
      <select onClick={(e) => onSizePerPageChange(e.target.value)}>
        {pageSizeObject.map((option) => (
          <option
            key={option.text}
            value={option.page}
            className={`btn cus-btn ${
              currSizePerPage === `${option.page}`
                ? "btn-secondary"
                : "btn-secondary"
            }`}
          >
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );

  const options = {
    sizePerPageRenderer,
  };

  const CustomToggleList = ({ columns, onColumnToggle, toggles }) => {
    const [selectedColumn, setSelectedColumn] = useState([]);

    const handleChangeColumn = async (data, props) => {
      onColumnToggle(props?.option?.dataField);
      setSelectedColumn(data);
    };
    useEffect(() => {
      if (analyticColumnData || ledgerbookdata) {
        columnsData?.map((er, i) => {
          console.log(
            "🚀 ~ file: DynamicTable.js:423 ~ columnsData?.map ~ er:",
            columnsData
          );

          if (i > 0 && i < 16) {
            setSelectedColumn((prev) => [...prev, er]);
          }
        });
      } else {
        columnsData?.map((er, i) => {
          if (i < 5) {
            setSelectedColumn((prev) => [...prev, er]);
          }
        });
      }
    }, [columnsData, analyticColumnData, ledgerbookdata]);

    const Option = (props) => {
      const handleChangeInput = (data) => {
        props?.selectProps?.components?.handleChangeColumn();
      };

      return (
        <div>
          <components.Option {...props}>
            <input
              type="checkbox"
              onChange={handleChangeInput}
              checked={props.isSelected}
            />{" "}
            <label>{props.label}</label>
          </components.Option>
        </div>
      );
    };

    return (
      <div
        className="btn-group btn-group-toggle mb-3 btn-group-vertical"
        data-toggle="buttons"
      >
        <Select
          options={columnsData}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
            handleChangeColumn,
          }}
          className="column-toggle"
          onChange={handleChangeColumn}
          allowSelectAll={true}
          value={selectedColumn}
          isClearable={false}
        />
      </div>
    );
  };

  const bootstrapDatamodal = () => {
    return (
      <div className="w-100 mb-2 overflow-auto">
        <ToolkitProvider
          keyField={"id"}
          data={
            formData?.hasOwnProperty("value")
              ? formData?.value?.newresponsedata
              : formData?.results
          }
          columns={columnsData}
          columnToggle
          exportCSV
          // search
        >
          {(props) => (
            <>
              {/* <div class="export-btn">
                {!customColumnsData && (
                  <ExportCSVButton {...props.csvProps}>
                    Export CSV
                  </ExportCSVButton>
                )}
              </div> */}

              <div>
                {!customColumnsData &&
                  !hideCustomToggleList &&
                  ledgerbookdata &&
                  analyticColumnData &&
                  selectedProduct === "All" && (
                    <CustomToggleList {...props.columnToggleProps} />
                  )}
                <BootstrapTable
                  key={formData?.value?.length}
                  {...props.baseProps}
                  noDataIndication="No Data Found."
                  pagination={
                    formData?.hasOwnProperty("value")
                      ? formData?.value?.newresponsedata.length > 10
                        ? paginationFactory(options)
                        : ""
                      : formData?.results?.length > 10
                      ? paginationFactory(options)
                      : ""
                  }
                />
              </div>
            </>
          )}
        </ToolkitProvider>
      </div>
    );
  };

  const bootstrapmodalFunc = React.useMemo(
    () => bootstrapDatamodal(),
    [formData, columnsData]
  );
  // console.log("🚀 ~ file: DynamicTable.js:820 ~ columnsData:", columnsData, formData);

  return (
    <div className="custom_table_width h-100">
      {mainLoader ? (
        <MainLoader />
      ) : (
        <div class="row">
          <div class={pageTitle ? "col-12  pt-4" : "col-12"}>
            <div class="comnbox">
              <div
                id={`${analyticColumnData ? "anlayticTable" : "filterTable"}`}
                class=" client_table"
              >
                {/* Campagins Logic for Title and Filter */}

                <div>
                  {/* Page Title Header Div */}
                  {pageTitle ? (
                    <div className="clientReportTitle">
                      <h3>{pageTitle}</h3>

                      {!customColumnsData && (
                        <div className="refresh_btn_div">
                          <div className="d-flex">
                            <i
                              class="fa fa-refresh refresh_btn"
                              id="refresh"
                              data-tooltip-content="Refresh"
                              onClick={handleGenerateBtn}
                              aria-hidden="true"
                            ></i>
                            <i
                              class="fas fa-filter filters_btn"
                              id="filter-table"
                              data-tooltip-content="Filters"
                              onClick={handleFilterBtn}
                              aria-hidden="true"
                            ></i>

                            <Tooltip
                              place="bottom"
                              className="tool_tip_graph"
                              anchorId="refresh"
                            />
                            <Tooltip
                              place="bottom"
                              className="tool_tip_graph"
                              anchorId="filter-table"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}

                  {/* Filter Div Start */}
                  <div
                    className={`w-100 filter-layout ${
                      showFilters ? "show" : ""
                    }`}
                  >
                    <form onSubmit={handleSubmit(handleGenerateBtn)}>
                      <div
                        className="before-filter"
                        onClick={() => setShowFilters(!showFilters)}
                      ></div>
                      <div className="table-filter-container">
                        <div className="filter-header d-flex justify-content-between flex-nowrap">
                          <h4 className="mb-4">Filters</h4>
                          <div className="">
                            <button
                              type="button"
                              className="btn py-1 px-2 mb-4"
                              onClick={() => setShowFilters(!showFilters)}
                            >
                              <i class="far fa-times"></i>
                            </button>
                          </div>
                        </div>

                        <div className="d-flex flex-wrap mb-3 filter-outer-container">
                          {DateParameter && (
                            <>
                              <div className="filter-container">
                                <Controller
                                  control={control}
                                  name="selectedType"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <select
                                      name="timeframe"
                                      className=""
                                      onChange={(e) =>
                                        fielddatechange(e.target.value)
                                      }
                                      value={value}
                                    >
                                      <option value="today">Today</option>
                                      <option value="yesterday">
                                        Yesterday
                                      </option>
                                      <option value="day_bef_yest">
                                        Day Before Yesterday
                                      </option>
                                      <option value="last_week">
                                        Last Week
                                      </option>
                                      <option value="this_week">
                                        This Week
                                      </option>
                                      <option value="this_month">
                                        This Month
                                      </option>
                                      <option value="last_month">
                                        Last month
                                      </option>
                                      <option value="this_year">YTD</option>
                                      <option value="custom">custom</option>
                                    </select>
                                  )}
                                />
                              </div>
                              <div className="filter-container">
                                <Controller
                                  control={control}
                                  name="fromDate"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <DatePicker
                                      onChange={onChange}
                                      startDate={getValues("fromDate")}
                                      endDate={getValues("toDate")}
                                      selected={value}
                                      showMonthDropdown
                                      useShortMonthInDropdown
                                      // className="form-control"
                                      placeholderText="From Date"
                                      dateFormat="dd-MM-yyyy"
                                      showYearDropdown
                                      locale="en-GB"
                                      wrapperClassName="ClientDatePicker"
                                    />
                                  )}
                                />
                              </div>
                              <div className="filter-container">
                                <Controller
                                  control={control}
                                  name="toDate"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <DatePicker
                                      onChange={onChange}
                                      startDate={getValues("fromDate")}
                                      endDate={getValues("toDate")}
                                      selected={value}
                                      showMonthDropdown
                                      useShortMonthInDropdown
                                      // className="form-control"
                                      placeholderText="To Date"
                                      dateFormat="dd-MM-yyyy"
                                      showYearDropdown
                                      wrapperClassName="ClientDatePicker"
                                      locale="en-GB"
                                    />
                                  )}
                                />
                              </div>
                            </>
                          )}
                          {/* report Type */}
                          {reportTypeParameter && (
                            <div className="filter-container">
                              {radioOptionsArray.map((er, i) => (
                                <div className="filter-radio-div">
                                  <input
                                    type="radio"
                                    id={er.value}
                                    value={er?.value}
                                    label={er.label}
                                    {...register("report_type")}
                                  />
                                  <label htmlFor={er.value}>{er.label}</label>
                                </div>
                              ))}
                            </div>
                          )}

                          {ProductParameter && (
                            <div className="filter-container">
                              <select
                                defaultValue={"All"}
                                {...register("selectedProduct")}
                              >
                                <option value={"All"}>-Select Product- </option>
                                {productArray?.map((items) => (
                                  <option key={items} value={items}>
                                    {" "}
                                    {items}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          {transactionTypeParameter && (
                            <div className="filter-container">
                              <select    
                              defaultValue={"CR"} {...register("transaction_type")}>

                              {transactionArray?.map((items) => (
                                  <option key={items} value={items}>
                                    {items}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                          {CampaignParameter && (
                            <div className="filter-container">
                              <select
                                disabled={
                                  !geoActivityData && selectedProduct === "All"
                                }
                                {...register("campaign_id")}
                              >
                                <option value={"All"}> -All Campaigns- </option>
                                {campaignIdOption?.map((items) => (
                                  <option
                                    key={items?.value}
                                    value={items?.value}
                                  >
                                    {" "}
                                    {items?.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}

                          <div className="filter-footer gap-2 d-flex flex-nowrap">
                            <div className="w-50">
                              <button
                                class="btn btn-warning w-100 clear-btn"
                                //   onClick={handleClearFilter}
                              >
                                Clear
                              </button>
                            </div>
                            <div className="w-50">
                              <button
                                type="submit"
                                className="btn fw-bolder w-100 btn-primary"
                              >
                                Generate
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  {filterLoader ? (
                    <div className="table-data">
                      <Mini />
                    </div>
                  ) : mode === "both" ? (
                    formData?.value?.responsedata?.length === 0 ? (
                      <tr>
                        <td>No Data Found</td>
                      </tr>
                    ) : (
                      <>
                        <div class="g_map">
                          <div class="chartContainer svg_map">
                            <Datamap
                              scope="usa"
                              geographyConfig={{
                                highlightBorderColor: "#bada55",
                                popupTemplate: (geography, data) =>
                                  `<div class='hoverinfo'>${geography.properties.name}\ : ${data.count} <br/> Revenue : ${data.Revenue}`,
                                highlightBorderWidth: 3,
                              }}
                              height={400}
                              width={600}
                              fills={{
                                Republican: "#CC4731",

                                defaultFill: "#EDDC4E",
                              }}
                              data={formData?.value?.responsedata}
                              labels
                            />
                          </div>

                          <div className="svg_table">
                            {columnsData?.length !== 0 && bootstrapmodalFunc}
                          </div>
                        </div>
                      </>
                    )
                  ) : formData?.length === 0 ? (
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                  ) : (
                    <>{columnsData?.length !== 0 && bootstrapmodalFunc}</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DynamicTable;
