export const filterActionTypes = {
  FILTER_APPLY: "FILTER_APPLY",
  FILTER_RESET: "FILTER_RESET",
  FILTER_MAIN_APPLY: "FILTER_MAIN_APPLY",
  FILTER_MAIN_RESET: "FILTER_MAIN_RESET",
  FILTER_LOADER: "FILTER_LOADER",
  FILTER_LOADER_RESET: "FILTER_LOADER_RESET",
  TAKE_SCREENSHOT: "TAKE_SCREENSHOT",
  TAKE_SCREENSHOT_RESET: "TAKE_SCREENSHOT_RESET",
};
