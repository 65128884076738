import React, { useState, useEffect } from "react";
import { AuthenticationService } from "../../services";
import { useDispatch, useSelector } from "react-redux";
// import { errorToaster } from "../../common/toaster";
import { useNavigate } from "react-router-dom";
import styles from "./login.module.css";
import { logoutSuccess } from "../../store/actions";
import { useForm } from "react-hook-form";
import { errorToaster, successToaster } from "../../common/toaster";
import dms_login_logo from "../../assets/logo.png";

function Login() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm();


  const [loader, setLoader] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  const {
    data: userDetails,
    loading,
    error,
  } = useSelector((state) => state.login);


  useEffect(() => {
    let token = global.localStorage.getItem("access_token");
    if (userDetails && token) {
      history("/");
    } else {
      dispatch(logoutSuccess());
      localStorage.clear();
    }
  }, [userDetails, history]);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      // console.log("🚀 ~ file: Login.js:42 ~ useEffect ~ error:", error)
      errorToaster(error);
      // dispatch(clearLoginError());
    }
  }, [error]);

  const onLogin = (data) => {
    dispatch(AuthenticationService.Login({ username: data.firstName, password: data.password }));
  };


  const showPasswordFunc = (event) => {
    setViewPassword((old) => !old);
    event.preventDefault();
  };

  return (
    <div className="container-fluid login-fluid">
      <div className="row mx-0 login-row">
        <div className="col-lg-6 login-col-1">
          <img
            className={`login-img ${styles.logo}`}
            src={dms_login_logo}
            width="280"
            alt="logo"
          />
        </div>
        <div className="col-lg-6 login-col-2">
          <div className={`login-container ${styles.mainDiv}`}>
            <div className={`login-sub-con ${styles.formdiv}`}>
              <form
                className={styles.loginForm}
                onSubmit={handleSubmit(onLogin)}
              >
                <h1 className="fw-bold text mb-4">
                  <i className="fas fa-user fa-sm h2"></i> Login
                </h1>
                <div className={styles.formGroup}>
                  <label>Username</label>
                  <input
                    type="text"
                    className={styles.formControl}
                    name="username"
                    required=""
                    autofocus=""
                    placeholder="Enter your Username"
                    {...register("firstName", { required: true })}
                  />
                </div>
                {errors.firstName && <p>Please check the User Name</p>}
                <div
                  className={`${styles.formGroup} ${styles.passwordShowbtn}`}
                >
                  <label>Password</label>
                  <input
                    type={viewPassword ? "text" : "password"}
                    className={styles.formControl}
                    name="Password"
                    required=""
                    placeholder="Enter your Password"
                    {...register("password", {
                      required: true,
                    })}
                  />
                  <p className={styles.passwordShowbtn}>
                    {!viewPassword ? (
                      <i
                        onClick={showPasswordFunc}
                        class="fas fa-solid fa-eye"
                      ></i>
                    ) : (
                      <i
                        onClick={showPasswordFunc}
                        class="fas fa-solid fa-eye-slash"
                      ></i>
                    )}
                  </p>
                </div>
                {errors.password && <p>Please check the Password</p>}
                <button
                  type="submit"
                  className="btn btn-primary btn-block btn-lg mt-40 w-100"
                  disabled={loader}
                >
                  {loader && <i class="fas fa-spinner fa-pulse"></i>}
                  {loader ? `` : `Login`}
                </button>
              </form>
              <div className={`login-footer ${styles.lo_ft}`}>
                <span> Quote Coverage Inc</span>
                <span>© 2023. All RIGHT RESERVED.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
