import React from "react";

export const MainLoader = () => {
    return (
        <div className="spinnerCLS">
            <section className="loader-container"><span className="loader-main"> </span></section>
        </div>
    );
};

export const Mini = () => {
    return (
        <div className="spinnerCLS">
            <section className="loader-container"><span className="loader-mini"> </span></section>
        </div>
    );
};