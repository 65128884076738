import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router";
import { errorToaster, successToaster } from '../../common/toaster';


const ChangePasswordApiData = async (req) => {
  let query = req;
  let url = `${process.env.REACT_APP_API_URL}/UpdateUser`;

  delete query["name"];
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "content-Type": "application/json",
      session: `${localStorage.getItem("access_token")}`,
      Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
    },
  });

  if (!response.ok) {
    throw new Error("Something went wrong!");
  }

  return response.json();
};

const ChangePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const {
    mutate,
    mutateAsync,
    isLoading: updateLoading,
    error: addError,
  } = useMutation(ChangePasswordApiData);

  const onSubmit = async (data) => {
    const apiData = await mutateAsync({
      userID: location?.state?.user_id,
      opassword: data.oldPassword,
      password: data.password,
    });

    // console.log(apiData);
    if (apiData.status === 1) {
      successToaster(apiData.message)
    }
    if (apiData.status === 0) {
      errorToaster(apiData.message)
    }
  };
  // console.log(errors);
  return (
    <div className="changepassword-box">
      <div class="row mx-0">
        <div class="col-12 pt-4">
          <div class="comnbox">
            <div id="filterTable" class=" client_table">
              <div>
                <div className="clientReportTitle">
                  <h3>Change Password</h3>
                </div>
              </div>
            </div>

            <div>
              <form className="m-4" onSubmit={handleSubmit(onSubmit)}>
                <div class="form-group row">
                  <label for="staticEmail" class="col-sm-3 col-form-label">
                    User Name :
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      readonly
                      disabled
                      class="form-control-plaintext"
                      id="staticEmail"
                      value={location?.state?.username}
                    />
                  </div>
                </div>
                <div class="form-group row mt-4">
                  <label for="oldPassword" class="col-sm-3 col-form-label">
                    Old Password :
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="password"
                      class="form-control"
                      id="oldPassword"
                      {...register("oldPassword", { required: true })}
                      placeholder="Old Password"
                    />
                    {errors?.oldPassword && (
                      <span className="validate-msg">
                        Please enter Old Password !
                      </span>
                    )}
                  </div>
                </div>
                <div class="form-group row mt-4">
                  <label for="password" class="col-sm-3 col-form-label">
                    Password :
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      {...register("password", {
                        required: {
                          value: true,
                          message: "Please enter  Password !",
                        },
                        pattern: {
                          value:
                            /^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/,
                          message:
                            "Please enter secure and strong password. Use Capital and small letter,allowed special character[@,#,$,%,&] and number",
                        },
                      })}
                      placeholder="Password"
                    />
                    {errors?.password && (
                      <span className="validate-msg">
                        {errors?.password?.message}
                      </span>
                    )}
                  </div>
                </div>
                <div class="form-group row mt-4">
                  <label for="confirmPassword" class="col-sm-3 col-form-label">
                    Confirm Password :
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="password"
                      class="form-control"
                      id="confirmPassword"
                      {...register("confirmPassword", {
                        required: {
                          value: true,
                          message: "Please enter Confrim Password !",
                        },

                        validate: (val) => {
                          if (watch("password") !== val) {
                            return "Your passwords do no match";
                          }
                        },
                      })}
                      placeholder="Confirm Password"
                    />
                    {errors?.confirmPassword && (
                      <span className="validate-msg">
                        {errors?.confirmPassword?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-4">
                  {/* <button
                  type="button"
                  className="btn btn-warning mr-2"
                  onClick={() => navigate("/")}
                >
                  Back
                </button> */}
                  {/* <button type="submit" className="btn btn-primary chan-pass-btn">
                  Save
                </button> */}

                  <button
                    type="submit"
                    className="btn btn-primary chan-pass-btn"
                    disabled={updateLoading}
                  >
                    {updateLoading ? <i class="fas fa-spinner fa-pulse"></i> : `Save`}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
