import axios from "axios";
import React, { useState } from "react";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import { MainLoader } from "../../common/Loader";

var statelist = [
  { label: "ALABAMA", value: "AL" },
  { label: "ALASKA", value: "AK" },
  { label: "AMERICAN SAMOA", value: "AS" },
  { label: "ARIZONA", value: "AZ" },
  { label: "ARKANSAS", value: "AR" },
  { label: "CALIFORNIA", value: "CA" },
  { label: "COLORADO", value: "CO" },
  { label: "CONNECTICUT", value: "CT" },
  { label: "DELAWARE", value: "DE" },
  { label: "DISTRICT OF COLUMBIA", value: "DC" },
  { label: "FEDERATED STATES OF MICRONESIA", value: "FM" },
  { label: "FLORIDA", value: "FL" },
  { label: "GEORGIA", value: "GA" },
  { label: "GUAM", value: "GU" },
  { label: "HAWAII", value: "HI" },
  { label: "IDAHO", value: "ID" },
  { label: "ILLINOIS", value: "IL" },
  { label: "INDIANA", value: "IN" },
  { label: "IOWA", value: "IA" },
  { label: "KANSAS", value: "KS" },
  { label: "KENTUCKY", value: "KY" },
  { label: "LOUISIANA", value: "LA" },
  { label: "MAINE", value: "ME" },
  { label: "MARSHALL ISLANDS", value: "MH" },
  { label: "MARYLAND", value: "MD" },
  { label: "MASSACHUSETTS", value: "MA" },
  { label: "MICHIGAN", value: "MI" },
  { label: "MINNESOTA", value: "MN" },
  { label: "MISSISSIPPI", value: "MS" },
  { label: "MISSOURI", value: "MO" },
  { label: "MONTANA", value: "MT" },
  { label: "NEBRASKA", value: "NE" },
  { label: "NEVADA", value: "NV" },
  { label: "NEW HAMPSHIRE", value: "NH" },
  { label: "NEW JERSEY", value: "NJ" },
  { label: "NEW MEXICO", value: "NM" },
  { label: "NEW YORK", value: "NY" },
  { label: "NORTH CAROLINA", value: "NC" },
  { label: "NORTH DAKOTA", value: "ND" },
  { label: "NORTHERN MARIANA ISLANDS", value: "MP" },
  { label: "OHIO", value: "OH" },
  { label: "OKLAHOMA", value: "OK" },
  { label: "OREGON", value: "OR" },
  { label: "PALAU", value: "PW" },
  { label: "PENNSYLVANIA", value: "PA" },
  { label: "PUERTO RICO", value: "PR" },
  { label: "RHODE ISLAND", value: "RI" },
  { label: "SOUTH CAROLINA", value: "SC" },
  { label: "SOUTH DAKOTA", value: "SD" },
  { label: "TENNESSEE", value: "TN" },
  { label: "TEXAS", value: "TX" },
  { label: "UTAH", value: "UT" },
  { label: "VERMONT", value: "VT" },
  { label: "VIRGIN ISLANDS", value: "VI" },
  { label: "VIRGINIA", value: "VA" },
  { label: "WASHINGTON", value: "WA" },
  { label: "WEST VIRGINIA", value: "WV" },
  { label: "WISCONSIN", value: "WI" },
  { label: "WYOMING", value: "WY" },
];

const ClickReport = () => {
  const [statevalue, setStatevalue] = useState();
  const [selectedlob, setSelectedlob] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedPosition, setSelectedPosition] = useState();
  const [errors, setErrors] = useState({});
  const [dataList, setDataList] = useState();
  const [loading, setLoading] = useState(false);
  // console.log(
  //   "🚀 ~ file: ClickReport.js:75 ~ ClickReport ~ dataList:",
  //   dataList?.length
  // );

  //   const lobDataCallApi = () => {
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_API_URL}/lineofbusiness`,
  //         {},
  //         { headers: headers }
  //       )
  //       .then((res) => {
  //         if (res?.data?.status === 1) {
  //           console.log("🚀 ~ file: ClickReport.js:22 ~ .then ~ res:", res);
  //           setLobListData(res?.data?.responsedata?.results);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("error", err);
  //       });
  //   };
  //   const campaignCallApi = () => {
  //     axios
  //       .post(
  //         `${process.env.REACT_APP_API_URL}/getCampaign`,
  //         { type: "Active" },
  //         { headers: headers }
  //       )
  //       .then((res) => {
  //         if (res?.data?.status === 1) {
  //           console.log("🚀 ~ file: ClickReport.js:40 ~ .then ~ res:", res);
  //           setCampaignListData(res?.data?.responsedata);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("error", err);
  //       });
  //   };

  //   useEffect(() => {
  //     lobDataCallApi();
  //     campaignCallApi();
  //   }, []);

  // validateForm

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!selectedlob || selectedlob == "") {
      formIsValid = false;
      errors["selectedlob"] = "*Please Select LOB.";
    }

    // if (this.state.statevalue == "") {
    //   formIsValid = false;
    //   errors["state_name"] = "*Please Select State.";
    // }
    if (!selectedDate || selectedDate == "") {
      formIsValid = false;
      errors["date"] = "*Please Select Time Frame.";
    }
    if (!selectedPosition || selectedPosition == "") {
      formIsValid = false;
      errors["position"] = "*Please Select Position";
    }
    setErrors(errors);
    return formIsValid;
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const headers = {
        "content-Type": "application/json",
        session: `${localStorage.getItem("access_token")}`,
        Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
      };

      let state = "";

      for (let i = 0; i < statevalue?.length; i++) {
        if (i == 0) {
          state = statevalue[i].value;
        } else {
          state = state + "," + statevalue[i].value;
        }
      }
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/getEstimated`,
          {
            lead_type_id: selectedlob,
            state: state,
            time_period: selectedDate,
            postion: selectedPosition,
          },

          {
            headers: headers,
          }
        )
        .then((res) => {
          //   this.setState({ loaded: true });

          if (res.data.status == 1) {
            let responsedata = res.data.responsedata;
            let data = [];

            data.push({
              id: 1,
              CTR: responsedata.CTR,
              BID_PRICE: responsedata.BID_PRICE,
              CPC: responsedata.CPC,
              CLICK_VOLUME: responsedata.CLICK_VOLUME,
              SEARCH_VOLUME: responsedata.SEARCH_VOLUME,
            });

            setDataList(data);
            setLoading(false);
          } else {
            const message = res.data.message;
            // console.log(
            //   "🚀 ~ file: ClickReport.js:195 ~ .then ~ message:",
            //   message
            // );
          }
        });
    }
  };

  const columns = [
    {
      dataField: "BID_PRICE",
      text: "Estimated Bid Price",
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" };
      },
      style: { textAlign: "center" },
      formatter: (cell, item) => {
        return <p>{`$${item.BID_PRICE}`}</p>;
      },
      //    sort: true
    },
    {
      dataField: "CPC",
      text: "Estimated Actual CPC",
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" };
      },
      style: { textAlign: "center" },
      formatter: (cell, item) => {
        return <p>{`$${item.CPC}`}</p>;
      },
    },
    {
      dataField: "CTR",
      text: "Estimated CTR",
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" };
      },
      style: { textAlign: "center" },

      //   sort: true
    },
    {
      dataField: "CLICK_VOLUME",
      text: "Estimated Click Volume",
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" };
      },
      style: { textAlign: "center" },
      // sort: true
    },
  ];

  return (
    <div class="row">
      <div class="col-12 pt-4">
        <div class="comnbox">
          <div id="filterTable" class=" client_table">
            <div>
              <form className="has-validation-callback" onSubmit={submitForm}>
                <div className="clientReportTitle">
                  <h3>Estimated Bidding Report</h3>
                  <div className="box-footer text-center">
                    <button type="submit" className="btn btn-sm btn-primary leftside">
                      <i className="fa fa-send me-2"></i> Generate
                    </button>
                  </div>
                </div>
                <div className="clientReportTitle d-block">
                  <div className="box-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Select LOB: <span className="red">*</span>
                          </label>

                          <div>
                            <select
                              className="form-control"
                              value={selectedlob}
                              onChange={(e) => {
                                setSelectedlob(e.target.value);
                              }}
                              name="product"
                            >
                              <option value="">-Select LOB-</option>
                              <option value="1">Auto insurance</option>
                              <option value="2">Life insurance</option>
                              <option value="3">Health insurance (U65)</option>
                              <option value="4">Home insurance</option>
                              <option value="10">Medicare insurance</option>
                            </select>
                          </div>

                          <div className="errorMsg">{errors?.selectedlob}</div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">States:</label>

                          <Select
                            value={statevalue}
                            isMulti
                            options={statelist}
                            placeholder="-Select All-"
                            onChange={(current_value) => {
                              setStatevalue(current_value);
                            }}
                          ></Select>

                          <div className="errorMsg">{errors?.state_name}</div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Time Frame: <span className="red">*</span>
                          </label>

                          <select
                            name="timeframe"
                            className="form-control"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                          >
                            {/* <option value="today">Select</option> */}
                            <option value="">-Select Time Frame-</option>

                            <option value="7">Last 7 Days</option>
                            <option value="14">Last 14 Days</option>
                            <option value="30">Last 30 Days</option>
                          </select>
                          <div className="errorMsg">{errors?.date}</div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            Position: <span className="red">*</span>{" "}
                          </label>

                          <select
                            name="position"
                            className="form-control"
                            value={selectedPosition}
                            onChange={(e) =>
                              setSelectedPosition(e.target.value)
                            }
                          >
                            {/* <option value="g">Select Position</option> */}
                            <option value="">-Select Position-</option>

                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                          </select>
                          <div className="errorMsg">{errors?.position}</div>
                        </div>
                      </div>
                    </div>

                    <div className="box-footer text-center col-md-5"></div>
                    <div className="box-footer text-center col-md-5"></div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-12">{table}</div>
                  </div> */}
                </div>
              </form>
              <div className="position-relative">
                {loading ? (
                  <div className="py-5 mb-3">
                  <MainLoader />
                  </div>
                ) : (
                  <div className="">
                    {dataList?.length > 0 ? (
                      <BootstrapTable
                        headerClasses="thead-dark"
                        footerClasses="thead-dark"
                        wrapperClasses="table-responsive"
                        striped
                        bootstrap4
                        keyField="id"
                        data={dataList}
                        columns={columns}
                      />
                    ) : dataList?.length === 0 ? (
                      <p>No Data Found</p>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClickReport;
