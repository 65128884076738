import { filterActionTypes } from "../constants";

export const applyFilter = (data) => {
  return {
    type: filterActionTypes.FILTER_APPLY,
    payload: data,
  };
};

export const resetFilter = () => {
  return {
    type: filterActionTypes.FILTER_RESET,
  };
};

export const applyMainFilter = (data) => {
  return {
    type: filterActionTypes.FILTER_MAIN_APPLY,
    payload: data,
  };
};

export const resetMainFilter = () => {
  return {
    type: filterActionTypes.FILTER_MAIN_RESET,
  };
};
export const filterLoader = () => {
  return {
    type: filterActionTypes.FILTER_LOADER,
  };
};
export const resetFilterLoader = () => {
  return {
    type: filterActionTypes.FILTER_LOADER_RESET,
  };
};

export const takeScreenShot = () => {
  return {
    type: filterActionTypes.TAKE_SCREENSHOT,
  };
};

export const resetScreenShot = () => {
  return {
    type: filterActionTypes.TAKE_SCREENSHOT_RESET,
  };
};
