import axios from "axios";
import {
  getAllClients,
  getAllClientsError,
  getAllClientsSuccess,
  getClientBidReport,
  getClientBidReportError,
  getClientBidReportSuccess,
  getClientCampaign,
  getClientCampaignError,
  getClientCampaignSuccess,
  getClientReport,
  getClientReportError,
  getClientReportSuccess,
  loginError,
} from "../store/actions";

export class ClientService {
  static AllClient = (data) => {
    return (dispatch) => {
      dispatch(getAllClients());
      axios
        .post(`${process.env.REACT_APP_API_URL}/getAll_Client`, data, {
          headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(getAllClientsSuccess(response.data.responseData));
          } else {
            dispatch(getAllClientsError(response.data.message));
          }
        })
        .catch((error) => {
          console.log("Login error", error);
          dispatch(getAllClientsError("Login error"));
        });
    };
  };

  static getClientReport = (data, apiKey) => {
    return (dispatch) => {
      dispatch(getClientReport(data));
      axios
        .post(`${process.env.REACT_APP_API_URL}/${apiKey?.apiKey}`, data, {
          headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            if (response?.data?.newresponsedata) {
              dispatch(
                getClientReportSuccess({
                  value: {
                    newresponsedata: response.data.newresponsedata,
                    responseData: response.data.responseData,
                  },
                })
              );
            } else {
              dispatch(getClientReportSuccess(response.data.responseData));
            }
          } else if (response.data.status === 0) {
            if (response.data.responseData?.length === 0) {
              dispatch(getClientReportSuccess({ value: [] }));
            } else {
              dispatch(getClientReportSuccess(response.data.responseData));
            }
          } else {
            dispatch(getClientReportError(response.data.message));
          }
        })
        .catch((error) => {
          console.log("Login error", error);

          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            console.log("Login error");
            // global.localStorage.clear();
            dispatch(getClientReportError("Login error"));
          }
        });
    };
  };

  static getClientCampaignsReport = (data) => {
    return (dispatch) => {
      dispatch(getClientCampaign(data));
      axios
        .post(`${process.env.REACT_APP_API_URL}/getClient_CampaignList`, data, {
          headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(getClientCampaignSuccess(response.data.responseData));
          } else if (response.data.status === 0) {
            dispatch(getClientCampaignSuccess(response.data.responseData));
          } else {
            dispatch(getClientCampaignError(response.data.message));
          }
        })
        .catch((error) => {
          console.log("Login error", error);

          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            console.log("Login error");
            // global.localStorage.clear();
            dispatch(getClientCampaignError("Login error"));
          }
        });
    };
  };

  static getClientBidsReport = (data) => {
    return (dispatch) => {
      dispatch(getClientBidReport(data));
      axios
        .post(`${process.env.REACT_APP_API_URL}/getClient_BidReport`, data, {
          headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(getClientBidReportSuccess(response.data.responseData));
          } else if (response.data.status === 0) {
            dispatch(getClientBidReportSuccess(response.data.responseData));
          } else {
            dispatch(getClientBidReportError(response.data.message));
          }
        })
        .catch((error) => {
          console.log("Login error", error);

          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            console.log("Login error");
            // global.localStorage.clear();
            dispatch(getClientBidReportError("Login error"));
          }
        });
    };
  };

  static getClientCampaignList = (data) => {
    return (dispatch) => {
      dispatch(getClientCampaign());
      axios
        .post(`${process.env.REACT_APP_API_URL}/getCampaign`, data, {
          headers: {
            "content-Type": "application/json",
            session: `${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(
              getClientCampaignSuccess(response.data.responsedata.results)
            );
          } else if (response.data.status === 0) {
            dispatch(
              getClientCampaignSuccess(response.data.responsedata.results)
            );
          } else {
            dispatch(getClientCampaignError(response.data.message));
          }
        })
        .catch((error) => {
          console.log("Login error", error);
          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            console.log("Login error");
            // global.localStorage.clear();
            dispatch(getClientCampaignError("Campaign Not Found !"));
          }
        });
    };
  };
}
