import { combineReducers } from "redux";
import {
  login,
  campaign,
  forgotPassword,
  layout,
  widget,
  ticker,
  clients,
  filter,
  analytics,
  ledgers,
} from "./reducers";

const appReducer = combineReducers({
  campaign: campaign,
  login: login,
  forgotPassword: forgotPassword,
  layout: layout,
  widget: widget,
  ticker: ticker,
  client: clients,
  filter: filter,
  analytic: analytics,
  ledgerBook:ledgers
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    state = initialState;
  }

  return appReducer(state, action);
};

export default rootReducer;
