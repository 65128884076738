import { filterActionTypes } from "../constants";

const initialState = {
  loader: false,
  data: "",
  element: {},
  main: [],
  screenShot: false,
};

export const filter = (state = initialState, action) => {
  switch (action.type) {
    case filterActionTypes.FILTER_APPLY:
      return {
        ...state,
        data: action.payload.req,
        element: action.payload.element,
      };
    case filterActionTypes.FILTER_RESET:
      return {
        ...state,
        data: "",
        element: {},
        main: [],
      };
    case filterActionTypes.FILTER_MAIN_APPLY:
      return {
        ...state,
        main: action.payload,
      };
    case filterActionTypes.FILTER_MAIN_RESET:
      return {
        ...state,
        main: [],
      };
    case filterActionTypes.FILTER_LOADER:
      return {
        ...state,
        loader: true,
      };
    case filterActionTypes.FILTER_LOADER_RESET:
      return {
        ...state,
        loader: false,
      };
    case filterActionTypes.TAKE_SCREENSHOT:
      return {
        ...state,
        screenShot: true,
      };
    case filterActionTypes.TAKE_SCREENSHOT_RESET:
      return {
        ...state,
        screenShot: false,
      };
    default:
      return state;
  }
};
