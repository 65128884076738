export const widgetActions = {
  //WIDGET
  GET_WIDGET: "GET_WIDGET",
  GET_WIDGET_SUCCESS: "GET_WIDGET_SUCCESS",
  GET_WIDGET_ERROR: "GET_WIDGET_ERROR",
  GET_WIDGET_RESET_ERROR: "GET_WIDGET_RESET_ERROR",
  RESET_GET_WIDGET_STATUS: "RESET_GET_WIDGET_STATUS",

  GET_WIDGET_LIST: "GET_WIDGET_LIST",
  GET_WIDGET_LIST_SUCCESS: "GET_WIDGET_LIST_SUCCESS",
  GET_WIDGET_LIST_ERROR: "GET_WIDGET_LIST_ERROR",
  ADDED_WIDGET_DATA: "ADDED_WIDGET_DATA",
  RESET_ADDED_WIDGET_DATA: "RESET_ADDED_WIDGET_DATA",
  ADDED_WIDGET: "ADDED_WIDGET",
  RESET_ADDED_WIDGET: "RESET_ADDED_WIDGET",
  AUTO_REFRESH_WIDGET_LOADING: "AUTO_REFRESH_WIDGET_LOADING",
  AUTO_REFRESH_WIDGET_LIST: "AUTO_REFRESH_WIDGET_LIST",
  AUTO_REFRESH_WIDGET_LIST_ERROR: "AUTO_REFRESH_WIDGET_LIST_ERROR",
  AUTO_REFRESH_WIDGET_ERROR_RESET: "AUTO_REFRESH_WIDGET_ERROR_RESET",

  SAVE_WIDGET_LIST: "SAVE_WIDGET_LIST",
  SAVE_WIDGET_LIST_SUCCESS: "SAVE_WIDGET_LIST_SUCCESS",
  SAVE_WIDGET_LIST_ERROR: "SAVE_WIDGET_LIST_ERROR",
  SAVE_WIDGET_LIST_RESET_TOAST: "SAVE_WIDGET_LIST_RESET_TOAST",
  WIDGET_RESET_INITIAL: "WIDGET_RESET_INITIAL",
};
