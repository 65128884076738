import axios from "axios";
import {
  getCampaign,
  getCampaignSuccess,
  getCampaignError, getCampaignCount,
  getCampaignCountSuccess,
  getCampaignCountError,
  loginError,
  getCampaignList,
  getCampaignListSuccess,
  getCampaignListError,
  getLeadTypeNQuality,
  getLeadTypeNQualitySuccess,
  getLeadTypeNQualityError,
  getCampaignStatus,
  getCampaignStatusSuccess,
  getCampaignStatusError,
  addCampaign,
  addCampaignSuccess,
  addCampaignError
} from "../store/actions";

export class CampaignService {
  static GetCampaign = (data) => {
    return (dispatch) => {
      dispatch(getCampaign());
      axios
        .post(`${process.env.REACT_APP_API_URL}/getCampaign`, data, {
          headers: {
            "content-Type": "application/json",
            session: `${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(getCampaignSuccess(response.data.responsedata.results));
          } else {
            dispatch(getCampaignError(response.data.message));
          }
        })
        .catch((error) => {
          console.log("Login error", error);

          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            console.log("error");
            // global.localStorage.clear();
            dispatch(getCampaignError("Login error"));
          }
        });
    };
  };

  static getCount = (req) => {
    return (dispatch) => {
      dispatch(getCampaignCount());
      axios
        .post(`${process.env.REACT_APP_API_URL}/getAllCount`, req, {
          headers: {
            "content-Type": "application/json",
            session: `${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(getCampaignCountSuccess(response.data.responsedata));
          } else {
            dispatch(getCampaignCountError(response.data.message));
          }
        })
        .catch((error) => {
          console.log("Login error", error);
          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            console.log("error");
            // global.localStorage.clear();
            dispatch(getCampaignCountError("Login error"));
          }
        });
    };
  };
  static getAllCampaignList = (req) => {
    return (dispatch) => {
      dispatch(getCampaignList());
      axios
        .post(`${process.env.REACT_APP_API_URL}/getAllCampaignlist`, req, {
          headers: {
            "content-Type": "application/json",
            session: `${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(getCampaignListSuccess(response.data.responsedata));
          } else {
            dispatch(getCampaignListSuccess(response.data.responsedata));
            dispatch(getCampaignListError(response.data.message));
          }
        })
        .catch((error) => {
          console.log("Login error", error);
          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            console.log("error");
            // global.localStorage.clear();
            dispatch(getCampaignListError("Login error"));
          }
        });
    };
  };
  static getLeadtypeandQuality = () => {
    return (dispatch) => {
      dispatch(getLeadTypeNQuality());
      axios
        .post(`${process.env.REACT_APP_API_URL}/getLeadtypeandQuality`, {}, {
          headers: {
            "content-Type": "application/json",
            session: `${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(getLeadTypeNQualitySuccess(response.data.responsedata));
          } else {
            dispatch(getLeadTypeNQualityError(response.data.message));
          }
        })
        .catch((error) => {
          console.log("Login error", error);
          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            console.log("error");
            // global.localStorage.clear();
            dispatch(getLeadTypeNQualityError("Login error"));
          }
        });
    };
  };
  static CheckCampaignStatus = (req) => {
    return (dispatch) => {
      dispatch(getCampaignStatus());
      axios
        .post(`${process.env.REACT_APP_API_URL}/checkcampaignstatus`, req, {
          headers: {
            "content-Type": "application/json",
            session: `${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(getCampaignStatusSuccess(response.data.responsedata));
          } else {
            dispatch(getCampaignStatusError(response.data.message));
          }
        })
        .catch((error) => {
          console.log("Login error", error);
          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            console.log("error");
            // global.localStorage.clear();
            dispatch(getCampaignStatusError("Login error"));
          }
        });
    };
  };

  static AddCampaign = (req) => {
    return (dispatch) => {
      dispatch(addCampaign());
      axios
        .post(`${process.env.REACT_APP_API_URL}/addCampaign`, req, {
          headers: {
            "content-Type": "application/json",
            session: `${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(addCampaignSuccess({ status: true, msg: response.data.message }));
          } else {
            dispatch(addCampaignError({ status: false, msg: response.data.message }));
          }
        })
        .catch((error) => {
          console.log("Login error", error);
          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            console.log("error");
            // global.localStorage.clear();
            dispatch(addCampaignError("Login error"));
          }
        });
    };
  };
}
