import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// toast.success("Success Notification !", {
//   position: toast.POSITION.TOP_CENTER
// });

// toast.error("Error Notification !", {
//   position: toast.POSITION.TOP_LEFT
// });

// toast.warn("Warning Notification !", {
//   position: toast.POSITION.BOTTOM_LEFT
// });

// toast.info("Info Notification !", {
//   position: toast.POSITION.BOTTOM_CENTER
// });

// toast("Custom Style Notification with css class!", {
//   position: toast.POSITION.BOTTOM_RIGHT,
//   className: 'foo-bar'
// });

export const successToaster = (message) => {
  return toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    className: 'foo-bar'
  });
};

export const errorToaster = (message) => {
  return toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};
