import React from 'react';
import { useMutation, useQuery } from "react-query";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';


let weekday = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'];

const dayWiseCapAPI = async (req) => {
  // console.log("🚀 ~ file: DayWiseCap.js:16 ~ dayWiseCapAPI ~ req:", req)
  let query = {}
  if (req.name === 2) {
    query = req
  } else {
    query = req.queryKey[1]
  }
  let url = query.name === 1
    ? `${process.env.REACT_APP_API_URL}/getDaywisecap`
    : `${process.env.REACT_APP_API_URL}/Updatedaywisecap`

  delete query["name"]
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "content-Type": "application/json",
      session: `${localStorage.getItem("access_token")}`,
      Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
    }
  });

  if (!response.ok) {
    throw new Error("Something went wrong!");
  }

  return response.json();
};


const DayWiseCap = () => {


  let { userId } = useParams();
  const navigate = useNavigate();


  const [capData, setCapData] = React.useState([]);
  const [fields, setFields] = React.useState([]);


  const {
    mutate,
    mutateAsync,
    isLoading: updateLoading,
    error: addError
  } = useMutation(dayWiseCapAPI);

  const getPriceData = async (e) => {
    e.preventDefault()
    var changeValue = {}
    changeValue = await mutateAsync({
      leadID: userId,
      data: fields,
      name: 2
    });

    if (changeValue.status === 1) {
      navigate("/campaign")
      setCapData([])
      setFields([])
    }
  };

  const { data, error, status, isLoading: getPriceDataLoader } = useQuery(["asd", {
    leadID: userId,
    name: 1
  }], dayWiseCapAPI);

  React.useEffect(() => {
    if (data?.status === 1) {

      setCapData(data.responsedata)
      const dataVal = data.responsedata.results;
      var fieldsArray = fields;
      dataVal && dataVal.map(function (map) {
        fieldsArray.push({
          "id": map.id,
          "cap": map.cap,
          "weekdays": map.weekdays,
        });
      });
      setFields(fieldsArray)
    } else if (data?.status === 0) {
      var fieldsArray = fields;
      weekday.map(function (map, i) {
        fieldsArray.push({
          "id": '',
          "cap": '',
          "weekdays": i,
        });
      });
      setFields(fieldsArray)
      setCapData({ ...data.responsedata, results: fieldsArray })
    } else {

      // var fieldsArray = fields;
      // weekday.map(function (map, i) {
      //     fieldsArray.push({
      //         "id": '',
      //         "cap": '',
      //         "weekdays": i,
      //     });
      // });
      // setFields(fieldsArray)
      setFields([])
      setCapData([])

    }
  }, [data])


  var questionNodes = fields && fields.map((question, i) => {
    var updateTextBox = (e) => {
      var stateCopy = Object.assign({}, capData);
      stateCopy.results[i].cap = e.target.value;
      setCapData(stateCopy)
      setFields(stateCopy.results)
    }

    return (
      <div className="col-md-6">
        <div className="form-group" key={question.id}>
          <label
            className="form-label"
          >{weekday[question.weekdays]} : <span className="red">*</span>
          </label>


          <input type="number"
            className="form-control"
            placeholder=""
            defaultValue={question.cap}
            onChange={updateTextBox}
            onKeyUp={updateTextBox}
          />


        </div>
      </div>
    );
  });
  // console.log("🚀 ~ file: DayWiseCap.js:151 ~ questionNodes ~ fields:", fields)



  return (
    <>
      <div className="page comnpadding changepassword-box">
        <div className="page-content">
          <div class="comnbox">
            <div class="page-header">
              <div class="clientReportTitle gap-3 justify-content-start mb-3">
                <Link
                  id="backbtn"
                  data-tooltip-content="Back"
                  to={"/campaign"}
                  className="text-blute"
                >
                  <i className="fa fa-arrow-left"></i>
                </Link>
                <Tooltip
                  place="bottom"
                  className="tool_tip_graph"
                  anchorId="backbtn"
                />
                <h3>DayWiseCap</h3>
              </div>
              <div class="page-header-actions px-2">
                <ol class="breadcrumb">
                  <strong>
                    {capData.Campaign_code &&
                      `[ Campaign : ${capData?.Campaign_code} ]`}
                  </strong>
                </ol>
              </div>
            </div>
            <div className="p-2">
              <div className="row g-4">
                <div className="col-md-12">
                  <div className="form-group">
                 
                    <p>{capData?.Campaign_desc}</p>

                  </div>
                </div>


                {questionNodes}
                <div className="col-md-12 col-xl-12 align-self-end text-end">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={updateLoading}
                    onClick={(e) => getPriceData(e)}
                  >
                    {updateLoading ? (
                      <button className="btn btn-primary">
                        <i className="fa fa-spinner fa-spin"></i>
                      </button>
                    ) : (
                      <i class="fa fa-pencil-square-o" aria-hidden="true">
                        {" Update"}
                      </i>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DayWiseCap