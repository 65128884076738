import React from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { MainLoader } from '../../common/Loader';
import { useDispatch } from 'react-redux';
import { loginSuccess, logoutSuccess, messageText } from '../../store/actions';


const directLoginApiData = async (req) => {
    let query = req.body;
    let url = `${process.env.REACT_APP_API_URL}/getUsersbyid`;
    const response = await fetch(url, {
        method: "POST",
        body: {},
        headers: {
            "content-Type": "application/json",
            session: `${query.id}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
        }
    });
    if (!response.ok) {
        throw new Error("Something went wrong!");
    }
    return response.json();
};



const DirectLogin = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const sessionToken = params.id;


    const {
        mutate,
        mutateAsync,
        isLoading,
        error,
    } = useMutation(directLoginApiData);


    React.useEffect(() => {
        mutateAsync({
            body: { id: sessionToken },
        }).then((res) => {
            if (res.status === 1) {
                localStorage.setItem("access_token", res.responsedata.session);
                dispatch(messageText({
                    type: "success",
                    message: res.message
                }));
                dispatch(loginSuccess(res.responsedata));
                navigate("/")
            } else if (res?.status === 22) {
                localStorage.clear();
                dispatch(messageText({
                    type: "error",
                    message: res.message
                }));
                dispatch(logoutSuccess());
                navigate("/")
            }
        }).catch((err) => {
            // console.log("error Api ", err);
            localStorage.clear();
            window.location.href = '/';
        });
    }, [mutateAsync]);


    return (
        isLoading ? <MainLoader /> : null
    )
}

export default DirectLogin