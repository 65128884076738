import React from "react";
import { BrowserRouter as Router, } from "react-router-dom";
import Layout from "./common/Layout";
import RoutesURL from "./common/Routes";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function App() {
  // toast.configure();
  return (
    <Router basename={process.env.REACT_APP_BASE_NAME}>
      <Layout>
        <ToastContainer />
        <RoutesURL />
      </Layout>
    </Router>
  );
}

export default App;