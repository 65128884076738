import { clientActionTypes } from "../constants";

const initialState = {
  loading: false,
  clientsList: [],
  clientListError: "",
  clientReport: [],
  clientFilterLoading : false,
  clientReportError: "",
  clientReportLoading: false,
  clientCampaignLoading: false,
  clientCampaignList: [],
  clientCampaignError: false,
  clientBidReportList: [],
  clientBidReportLoading: false,
  clientBidReportError: ""
};

export const clients = (state = initialState, action) => {
  switch (action.type) {
    case clientActionTypes.GET_ALL_CLIENTS:
      return {
        ...state,
        loading: true,
      };
    case clientActionTypes.GET_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientsList: action.payload,
        clientListError: "",
      };
    case clientActionTypes.GET_ALL_CLIENTS_ERROR:
      return {
        ...state,
        loading: false,
        clientsList: [],
        clientListError: action.payload,
      };

      case clientActionTypes.GET_CLIENT_REPORT: 
      return {
        ...state,
        clientFilterLoading: action?.payload?.filter ? true : false,        
        clientReportLoading : action?.payload?.filter ? false : true
      }; 

    case clientActionTypes.GET_CLIENT_REPORT_SUCCESS:
      return {
        ...state,
        clientFilterLoading: false,
        clientReportLoading: false,
        clientReport: action.payload,
        clientReportError: "",
      };

    case clientActionTypes.GET_CLIENT_REPORT_ERROR:
      return {
        ...state,
        clientReportLoading: false ,        
        clientFilterLoading: false,
        clientReport: [],
        clientReportError: action.payload,
      };

    case clientActionTypes.GET_CLIENT_REPORT_CLEAR:
      return {
        ...state,
        clientReportLoading: false,
        clientReport: [],
        clientReportError: "",
      };
    // 02-01-23
    case clientActionTypes.GET_CLIENTS_CAMPAIGN:
      return {
        ...state,
        clientFilterLoading: action?.payload?.filter ? true : false, 
        clientCampaignLoading: action?.payload?.filter ? false : true,
        clientCampaignList: [],
        clientCampaignError: "",
      };
    case clientActionTypes.GET_CLIENTS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        clientCampaignLoading: false,        
        clientFilterLoading: false,
        clientCampaignList: action.payload,
        clientCampaignError: "",
      };
    case clientActionTypes.GET_CLIENTS_CAMPAIGN_ERROR:
      return {
        ...state,
        clientCampaignLoading: false,        
        clientFilterLoading: false,
        clientCampaignList: [],
        clientCampaignError: action.payload,
      };
    case clientActionTypes.GET_CLIENTS_CAMPAIGN_RESET:
      return {
        ...state,
        loading: false,
        clientCampaignList: [],
        clientCampaignError: "",
      };


      case clientActionTypes.GET_CLIENTS_BID_REPORT:
        return {
          ...state,
          clientFilterLoading: action?.payload?.filter ? true : false, 
          clientBidReportLoading: action?.payload?.filter ? false : true,
          clientBidReportList: [],
          clientBidReportError: "",
        };
      case clientActionTypes.GET_CLIENTS_BID_REPORT_SUCCESS:
        return {
          ...state,
          clientBidReportLoading: false,        
          clientFilterLoading: false,
          clientBidReportList: action.payload,
          clientBidReportError: "",
        };
      case clientActionTypes.GET_CLIENTS_BID_REPORT_ERROR:
        return {
          ...state,
          clientBidReportLoading: false,        
          clientFilterLoading: false,
          clientBidReportList: [],
          clientBidReportError: action.payload,
        };
      case clientActionTypes.GET_CLIENTS_BID_REPORT_RESET:
        return {
          ...state,
          loading: false,
          clientBidReportList: [],
          clientBidReportError: "",
        };

    default:
      return state;
  }
};