import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import styles from "./CampaignModal.module.css";

var week = new Array(
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
);

var weekdays = [
  { id: 1, key: "Monday" },
  { id: 2, key: "Tuesday" },
  { id: 3, key: "Wednesday" },
  { id: 4, key: "Thursday" },
  { id: 5, key: "Friday" },
  { id: 6, key: "Saturday" },
  { id: 7, key: "Sunday" },
];
var weekdaysCount = new Array("1", "2", "3", "4", "5");

var hoursarray = new Array(
  "00:00",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30"
);

const LeadCampaginDetailsApiData = async (req) => {
  let query = req.body;
  let url =
    req.state === "save"
      ? `${process.env.REACT_APP_API_URL}/getBuyerAddupdateleadcampaign`
      : `${process.env.REACT_APP_API_URL}/getBuyeraddleadcampaigndetail`;

  delete query["name"];
  const response = await fetch(url, {
    method: "POST",
    body: req.state === "save" ? query : JSON.stringify(query),
    headers:
      req.state === "save"
        ? {
          session: `${localStorage.getItem("access_token")}`,
          Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
        }
        : {
          "content-Type": "application/json",
          session: `${localStorage.getItem("access_token")}`,
          Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
        },
  });

  if (!response.ok) {
    throw new Error("Something went wrong!");
  }

  return response.json();
};

const CampaignDetails = () => {
  const location = useLocation();
  const navigate = useNavigate()

  const [fieldData, setFieldData] = useState({
    fromtime_Sunday_1: "01:00",
    totime_Sunday_1: "01:00",
    campaign_link: "",
    open: false,
    monthly_budget: 0,
    daily_budget: 0,
    display_url: "",
    headline: "",
    bullet1: "",
    bullet2: "",
    bullet3: "",
    bullet4: "",
    bullet5: "",
    campaign_ad_id: "",
    load_spinner: false,
    bid_type: "Fixed",
    delivery_checkbox_Monday: "Yes",
    delivery_checkbox_Tuesday: "Yes",
    delivery_checkbox_Wednesday: "Yes",
    delivery_checkbox_Thursday: "Yes",
    delivery_checkbox_Friday: "Yes",
    delivery_checkbox_Saturday: "Yes",
    delivery_checkbox_Sunday: "Yes",
  });

  const [errorsData, setErrorsData] = useState({});
  const [selectedFile, setSelectedFile] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [loadingState, setLoadingState] = useState(false);
  const [campaignCode, setCampaignCode] = useState();
  const [previewState, setPreviewState] = useState(false);
  const [modalState, setModelState] = useState(false);
  const [tabState, setTabState] = useState("tab1");
  const [weekDayDetails, setWeekDayDetails] = useState([]);

  const {
    mutate,
    mutateAsync,
    isLoading: updateLoading,
    error: addError,
  } = useMutation(LeadCampaginDetailsApiData);

  useEffect(() => {
    if (location?.state) {
      mutateAsync({
        body: {
          buyer_lead_type_id: location?.state?.user_id,
        },
      })
        .then((res) => {
          if (res.status === 1) {
            setCampaignCode(res?.responsedata?.Campaign_code);
          } else if (res?.status === 0) {
            setFieldData(res?.responsedata);
            setCampaignCode(res?.responsedata?.Campaign_code);
            setPreviewState(true);
          }
        })
        .catch((err) => {
          console.log("error Api ", err);
        });
    }
  }, [location?.state, mutateAsync]);

  // handleChange of input
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFieldData({ ...fieldData, [name]: value });
  };

  // handleFileChange

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  // handleChangeChk

  const handleChangeChk = (e) => {
    let name = e.target.name;

    setFieldData({
      ...fieldData,
      [name]: fieldData[name] === "No" ? "Yes" : "No",
    });
  };

  // handleChangetab

  const handleChangeTab = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFieldData({ ...fieldData, [name]: value });
  };

  // handleApplyBtn Change

  const handleApplyBtn = () => {
    let fields = fieldData;

    if (selectedOption == "AllWeekdays") {
      for (var i = 1; i <= 5; i++) {
        var fromtime = fields["fromtime_Monday_" + i];
        var totime = fields["totime_Monday_" + i];

        fields["fromtime_Tuesday_" + i] = fromtime;
        fields["fromtime_Wednesday_" + i] = fromtime;
        fields["fromtime_Thursday_" + i] = fromtime;
        fields["fromtime_Friday_" + i] = fromtime;
        fields["fromtime_Saturday_" + i] = "00:00";
        fields["fromtime_Sunday_" + i] = "00:00";

        fields["totime_Tuesday_" + i] = totime;
        fields["totime_Wednesday_" + i] = totime;
        fields["totime_Thursday_" + i] = totime;
        fields["totime_Friday_" + i] = totime;
        fields["totime_Saturday_" + i] = "00:00";
        fields["totime_Sunday_" + i] = "00:00";
      }
    } else if (selectedOption == "AllDays") {
      for (var i = 1; i <= 5; i++) {
        var fromtime = fields["fromtime_Monday_" + i];
        var totime = fields["totime_Monday_" + i];

        fields["fromtime_Tuesday_" + i] = fromtime;
        fields["fromtime_Wednesday_" + i] = fromtime;
        fields["fromtime_Thursday_" + i] = fromtime;
        fields["fromtime_Friday_" + i] = fromtime;
        fields["fromtime_Saturday_" + i] = fromtime;
        fields["fromtime_Sunday_" + i] = fromtime;

        fields["totime_Tuesday_" + i] = totime;
        fields["totime_Wednesday_" + i] = totime;
        fields["totime_Thursday_" + i] = totime;
        fields["totime_Friday_" + i] = totime;
        fields["totime_Saturday_" + i] = totime;
        fields["totime_Sunday_" + i] = totime;
      }
    } else {
      for (var i = 1; i <= 5; i++) {
        fields["fromtime_Tuesday_" + i] = "00:00";
        fields["fromtime_Wednesday_" + i] = "00:00";
        fields["fromtime_Thursday_" + i] = "00:00";
        fields["fromtime_Friday_" + i] = "00:00";
        fields["fromtime_Saturday_" + i] = "00:00";
        fields["fromtime_Sunday_" + i] = "00:00";

        fields["totime_Tuesday_" + i] = "00:00";
        fields["totime_Wednesday_" + i] = "00:00";
        fields["totime_Thursday_" + i] = "00:00";
        fields["totime_Friday_" + i] = "00:00";
        fields["totime_Saturday_" + i] = "00:00";
        fields["totime_Sunday_" + i] = "00:00";
      }
    }
    setFieldData({ ...fieldData, fields });
  };

  // validateForm

  const validateForm = () => {
    let fields = fieldData;
    let errors = {};
    let formIsValid = true;

    if (!fields["campaign_link"]) {
      formIsValid = false;
      errors["campaign_link"] = "*Please Fill campaign Link.";
    }

    // if (!fields["daily_budget"]) {
    //   formIsValid = false;
    //   errors["daily_budget"] = "*Please Fill Daily Budget.";
    // }

    // if (!fields["monthly_budget"]) {
    //   formIsValid = false;
    //   errors["monthly_budget"] = "*Please Fill Monthly Budget.";
    // }

    if (!fields["display_url"]) {
      formIsValid = false;
      errors["display_url"] = "*Please Fill Agency Name.";
    }

    if (!fields["headline"]) {
      formIsValid = false;
      errors["headline"] = "*Please Fill Headline.";
    }

    if (!fields["bullet1"]) {
      formIsValid = false;
      errors["bullet1"] = "*Please Fill Bullet 1.";
    }

    if (!fields["bullet2"]) {
      formIsValid = false;
      errors["bullet2"] = "*Please Fill Bullet 2.";
    }

    if (!fields["bullet3"]) {
      formIsValid = false;
      errors["bullet3"] = "*Please Fill Bullet 3.";
    }

    setErrorsData(errors);
    return formIsValid;
  };

  // handleSubmitBtn

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoadingState(true);

      const formData = new FormData();

      if (selectedFile) {
        formData.append("campaign_logo", selectedFile, selectedFile.name);
      }

      formData.set("campaign_link", fieldData?.campaign_link);
      formData.set("daily_budget", fieldData?.daily_budget);
      formData.set("monthly_budget", fieldData?.monthly_budget);
      formData.set("display_url", fieldData?.display_url);
      formData.set("headline", fieldData?.headline);
      formData.set("bullet1", fieldData?.bullet1);
      formData.set("bullet2", fieldData?.bullet2);
      formData.set("bullet3", fieldData?.bullet3);
      formData.set("bullet4", fieldData?.bullet4);
      formData.set("bullet5", fieldData?.bullet5);
      formData.set("campaign_ad_id", fieldData?.campaign_ad_id);
      formData.set("buyer_lead_type_id", location?.state?.user_id);
      formData.set("bid_type", fieldData?.bid_type);

      //Sunday from - to time
      formData.set("fromtime_Sunday_1", fieldData?.fromtime_Sunday_1);
      formData.set("totime_Sunday_1", fieldData?.totime_Sunday_1);

      formData.set("fromtime_Sunday_2", fieldData?.fromtime_Sunday_2);
      formData.set("totime_Sunday_2", fieldData?.totime_Sunday_2);

      formData.set("fromtime_Sunday_3", fieldData?.fromtime_Sunday_3);
      formData.set("totime_Sunday_3", fieldData?.totime_Sunday_3);

      formData.set("fromtime_Sunday_4", fieldData?.fromtime_Sunday_4);
      formData.set("totime_Sunday_4", fieldData?.totime_Sunday_4);

      formData.set("fromtime_Sunday_5", fieldData?.fromtime_Sunday_5);
      formData.set("totime_Sunday_5", fieldData?.totime_Sunday_5);

      //Monday from - to time
      formData.set("fromtime_Monday_1", fieldData?.fromtime_Monday_1);
      formData.set("totime_Monday_1", fieldData?.totime_Monday_1);

      formData.set("fromtime_Monday_2", fieldData?.fromtime_Monday_2);
      formData.set("totime_Monday_2", fieldData?.totime_Monday_2);

      formData.set("fromtime_Monday_3", fieldData?.fromtime_Monday_3);
      formData.set("totime_Monday_3", fieldData?.totime_Monday_3);

      formData.set("fromtime_Monday_4", fieldData?.fromtime_Monday_4);
      formData.set("totime_Monday_4", fieldData?.totime_Monday_4);

      formData.set("fromtime_Monday_5", fieldData?.fromtime_Monday_5);
      formData.set("totime_Monday_5", fieldData?.totime_Monday_5);

      //Tuesday from - to time
      formData.set("fromtime_Tuesday_1", fieldData?.fromtime_Tuesday_1);
      formData.set("totime_Tuesday_1", fieldData?.totime_Tuesday_1);

      formData.set("fromtime_Tuesday_2", fieldData?.fromtime_Tuesday_2);
      formData.set("totime_Tuesday_2", fieldData?.totime_Tuesday_2);

      formData.set("fromtime_Tuesday_3", fieldData?.fromtime_Tuesday_3);
      formData.set("totime_Tuesday_3", fieldData?.totime_Tuesday_3);

      formData.set("fromtime_Tuesday_4", fieldData?.fromtime_Tuesday_4);
      formData.set("totime_Tuesday_4", fieldData?.totime_Tuesday_4);

      formData.set("fromtime_Tuesday_5", fieldData?.fromtime_Tuesday_5);
      formData.set("totime_Tuesday_5", fieldData?.totime_Tuesday_5);

      //Wednesday from - to time
      formData.set("fromtime_Wednesday_1", fieldData?.fromtime_Wednesday_1);
      formData.set("totime_Wednesday_1", fieldData?.totime_Wednesday_1);

      formData.set("fromtime_Wednesday_2", fieldData?.fromtime_Wednesday_2);
      formData.set("totime_Wednesday_2", fieldData?.totime_Wednesday_2);

      formData.set("fromtime_Wednesday_3", fieldData?.fromtime_Wednesday_3);
      formData.set("totime_Wednesday_3", fieldData?.totime_Wednesday_3);

      formData.set("fromtime_Wednesday_4", fieldData?.fromtime_Wednesday_4);
      formData.set("totime_Wednesday_4", fieldData?.totime_Wednesday_4);

      formData.set("fromtime_Wednesday_5", fieldData?.fromtime_Wednesday_5);
      formData.set("totime_Wednesday_5", fieldData?.totime_Wednesday_5);

      //Thursday from - to time
      formData.set("fromtime_Thursday_1", fieldData?.fromtime_Thursday_1);
      formData.set("totime_Thursday_1", fieldData?.totime_Thursday_1);

      formData.set("fromtime_Thursday_2", fieldData?.fromtime_Thursday_2);
      formData.set("totime_Thursday_2", fieldData?.totime_Thursday_2);

      formData.set("fromtime_Thursday_3", fieldData?.fromtime_Thursday_3);
      formData.set("totime_Thursday_3", fieldData?.totime_Thursday_3);

      formData.set("fromtime_Thursday_4", fieldData?.fromtime_Thursday_4);
      formData.set("totime_Thursday_4", fieldData?.totime_Thursday_4);

      formData.set("fromtime_Thursday_5", fieldData?.fromtime_Thursday_5);
      formData.set("totime_Thursday_5", fieldData?.totime_Thursday_5);

      //Friday from - to time
      formData.set("fromtime_Friday_1", fieldData?.fromtime_Friday_1);
      formData.set("totime_Friday_1", fieldData?.totime_Friday_1);

      formData.set("fromtime_Friday_2", fieldData?.fromtime_Friday_2);
      formData.set("totime_Friday_2", fieldData?.totime_Friday_2);

      formData.set("fromtime_Friday_3", fieldData?.fromtime_Friday_3);
      formData.set("totime_Friday_3", fieldData?.totime_Friday_3);

      formData.set("fromtime_Friday_4", fieldData?.fromtime_Friday_4);
      formData.set("totime_Friday_4", fieldData?.totime_Friday_4);

      formData.set("fromtime_Friday_5", fieldData?.fromtime_Friday_5);
      formData.set("totime_Friday_5", fieldData?.totime_Friday_5);

      //Saturday from - to time
      formData.set("fromtime_Saturday_1", fieldData?.fromtime_Saturday_1);
      formData.set("totime_Saturday_1", fieldData?.totime_Saturday_1);

      formData.set("fromtime_Saturday_2", fieldData?.fromtime_Saturday_2);
      formData.set("totime_Saturday_2", fieldData?.totime_Saturday_2);

      formData.set("fromtime_Saturday_3", fieldData?.fromtime_Saturday_3);
      formData.set("totime_Saturday_3", fieldData?.totime_Saturday_3);

      formData.set("fromtime_Saturday_4", fieldData?.fromtime_Saturday_4);
      formData.set("totime_Saturday_4", fieldData?.totime_Saturday_4);

      formData.set("fromtime_Saturday_5", fieldData?.fromtime_Saturday_5);
      formData.set("totime_Saturday_5", fieldData?.totime_Saturday_5);

      formData.set(
        "delivery_checkbox_Monday",
        fieldData?.delivery_checkbox_Monday
      );
      formData.set(
        "delivery_checkbox_Tuesday",
        fieldData?.delivery_checkbox_Tuesday
      );
      formData.set(
        "delivery_checkbox_Wednesday",
        fieldData?.delivery_checkbox_Wednesday
      );
      formData.set(
        "delivery_checkbox_Thursday",
        fieldData?.delivery_checkbox_Thursday
      );
      formData.set(
        "delivery_checkbox_Friday",
        fieldData?.delivery_checkbox_Friday
      );
      formData.set(
        "delivery_checkbox_Saturday",
        fieldData?.delivery_checkbox_Saturday
      );
      formData.set(
        "delivery_checkbox_Sunday",
        fieldData?.delivery_checkbox_Sunday
      );

      //  call Api
      const apiData = await mutateAsync({ body: formData, state: "save" });

      if (apiData.status === 1) {
        // console.log(apiData);
        navigate("/campaign");
        setLoadingState(false);
      }
    }
  };

  // handleModal State

  const handleModel = () => {
    setModelState(!modalState);
  };

  // getImage url
  const imageCheckUrl = (src) => {
    console.log(process.env.REACT_APP_IMG_URL)
    if (src == null) {
      return process.env.REACT_APP_IMG_URL + "/noimage.png";
    } else {
      const http_array = src.split("http");

      if (http_array.length > 1) {
        return src;
      } else {
        return process.env.REACT_APP_IMG_URL + "/" + src;
      }
    }
  };

  function Modal({ children, shown, close }) {
    return shown ? (
      <div
        className={`modal-container ${styles.modalBackdrop}`}
        onClick={() => {
          // close modal when outside of modal is clicked
          close();
        }}
      >
        <div
          className={styles.sidebar_add_widget_form__1TGwy}
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          {/* <button onClick={close}>Close</button> */}
          {children}
        </div>
      </div>
    ) : null;
  }

  useEffect(() => {
    if (weekdays) {
      let ArrayDetails = [];

      weekdays?.map((items) => {
        let detailsWeek = weekdaysCount?.map((er, i) => {
          var day_value = "";
          var day_to_value = "";

          if (i + 1 == 1) {
            day_value = fieldData[`fromtime_${items?.key}_1`];
            day_to_value = fieldData[`totime_${items?.key}_1`];
          } else if (i + 1 == 2) {
            day_value = fieldData[`fromtime_${items?.key}_2`];
            day_to_value = fieldData[`totime_${items?.key}_2`];
          } else if (i + 1 == 3) {
            day_value = fieldData[`fromtime_${items?.key}_3`];
            day_to_value = fieldData[`totime_${items?.key}_3`];
          } else if (i + 1 == 4) {
            day_value = fieldData[`fromtime_${items?.key}_4`];
            day_to_value = fieldData[`totime_${items?.key}_4`];
          } else if (i + 1 == 5) {
            day_value = fieldData[`fromtime_${items?.key}_5`];
            day_to_value = fieldData[`totime_${items?.key}_5`];
          }

          return (
            <div className="form-group">
              <div class="row my-3 mx-1">
                <div class="col">
                  <label className="form-label">from : </label>

                  {/* {console.log(
                      "key >>>>>>>>>>>>>>>>",
                      `fromtime_${items?.key}_` + (i + 1),
                      i
                    )} */}
                  <select
                    className="form-control"
                    onChange={handleChangeTab}
                    name={`fromtime_${items?.key}_` + (i + 1)}
                    value={fieldData[`fromtime_${items?.key}_` + (i + 1)]}
                  >
                    {hoursarray.map((value) => (
                      <option
                        key={value}
                        value={value}
                        selected={day_value == value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div class="col">
                  <label className="form-label">To : </label>
                  <select
                    className="form-control"
                    onChange={handleChangeTab}
                    name={`totime_${items?.key}_` + (i + 1)}
                  >
                    {hoursarray.map((value) => (
                      <option
                        key={value}
                        value={value}
                        selected={day_to_value == value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          );
        });

        detailsWeek.key = items?.key;

        ArrayDetails.push(detailsWeek);
      });

      setWeekDayDetails(ArrayDetails);
    }
  }, [fieldData]);

  return (
    <>
      <div class="row px-2">
        <div class="col-12 pt-4">
          <div class="comnbox">
            <div id="filterTable" class=" client_table">
              <div>
                <div className="clientReportTitle">
                  <div className="d-flex gap-3 justify-content-start mb-0">
                    <Link
                      id="backbtn"
                      data-tooltip-content="Back"
                      to={"/campaign"}
                      className="text-blute"
                    >
                      <i className="fa fa-arrow-left"></i>
                    </Link>
                    <Tooltip
                      place="bottom"
                      className="tool_tip_graph"
                      anchorId="backbtn"
                    />
                    <h3>{`Campaign Details [${campaignCode}]`}</h3>
                  </div>

                  {previewState ? (
                    <span
                      className="btn btn-icon btn-primary btn-outline"
                      onClick={handleModel}
                    >
                      Preview
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <form className="has-validation-callback" onSubmit={handleSubmit}>
              <div className="box-body">
                <div className="row g-3 my-2 px-3">
                  <div className="col-md-6 col-xl-4">
                    <div className="form-group">
                      <label className="control-label">
                        Campaign Tracking Link : <span className="red">*</span>
                      </label>

                      <input
                        className="form-control"
                        placeholder="Campaign Tracking Link"
                        defaultValue={fieldData.campaign_link}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        readOnly={true}
                        name="campaign_link"
                        type="text"
                      />
                      <div className="errorMsg">
                        {errorsData?.campaign_link}
                      </div>
                    </div>
                  </div>
                  {fieldData.campaign_logo && (
                    <div className="col-md-2 col-xl-1">
                      <div className="form-group">
                        {fieldData.campaign_logo ? (
                          <img
                            src={imageCheckUrl(fieldData.campaign_logo)}
                            style={{ maxHeight: "60px" }}
                            className="img-responsive"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className={`${
                      fieldData.campaign_logo
                      ? "col-md-4 col-xl-3"
                      : "col-md-6 col-xl-4"
                      }`}
                  >
                    <div className="form-group">
                      <label className="control-label">
                        Campaign Logo :{" "}
                        <span className="red">* (Size : 110 * 55)</span>
                      </label>

                      <input
                        className="form-control"
                        placeholder="Campaign Logo"
                        //   defaultValue={fieldData?.campaign_logo}
                        onChange={handleFileChange}
                        name="campaign_logo"
                        type="file"
                      />

                      <div className="errorMsg">
                        {errorsData?.campaign_logo}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-xl-4">
                    <div className="form-group">
                      <label className="control-label">
                        Daily Budget : <span className="red">*</span>
                      </label>

                      <input
                        className="form-control"
                        placeholder="Daily Budget"
                        defaultValue={fieldData?.daily_budget}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        name="daily_budget"
                        type="text"
                      />
                      <div className="errorMsg">{errorsData?.daily_budget}</div>
                    </div>
                  </div> */}

                  {/* <div className="col-md-6 col-xl-4">
                    <div className="form-group">
                      <label className="control-label">
                        Monthly Budget : <span className="red">*</span>
                      </label>

                      <input
                        className="form-control"
                        placeholder="Monthly Budget"
                        defaultValue={fieldData?.monthly_budget}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        name="monthly_budget"
                        type="text"
                      />
                      <div className="errorMsg">
                        {errorsData?.monthly_budget}
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-6 col-xl-4">
                    <div className="form-group">
                      <label className="control-label">
                        Agency Name : <span className="red">*</span>
                      </label>

                      <input
                        className="form-control"
                        placeholder="Agency Name"
                        defaultValue={fieldData?.display_url}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        name="display_url"
                        type="text"
                      />
                      <div className="errorMsg">{errorsData?.display_url}</div>
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-4">
                    <div className="form-group">
                      <label className="control-label">
                        Headline : <span className="red">*</span>
                      </label>

                      <input
                        className="form-control"
                        placeholder="Headline"
                        defaultValue={fieldData?.headline}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        name="headline"
                        type="text"
                      />
                      <div className="errorMsg">{errorsData?.headline}</div>
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-4">
                    <div className="form-group">
                      <label className="control-label">
                        Bullet 1 : <span className="red">*</span>
                      </label>

                      <input
                        className="form-control"
                        placeholder="Bullet 1"
                        defaultValue={fieldData?.bullet1}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        name="bullet1"
                        type="text"
                      />
                      <div className="errorMsg">{errorsData?.bullet1}</div>
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-4">
                    <div className="form-group">
                      <label className="control-label">
                        Bullet 2 : <span className="red">*</span>
                      </label>

                      <input
                        className="form-control"
                        placeholder="Bullet 2"
                        defaultValue={fieldData?.bullet2}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        name="bullet2"
                        type="text"
                      />
                      <div className="errorMsg">{errorsData?.bullet2}</div>
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-4">
                    <div className="form-group">
                      <label className="control-label">
                        Bullet 3 : <span className="red">*</span>
                      </label>

                      <input
                        className="form-control"
                        placeholder="Bullet 3"
                        defaultValue={fieldData?.bullet3}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        name="bullet3"
                        type="text"
                      />
                      <div className="errorMsg">{errorsData?.bullet3}</div>
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-4">
                    <div className="form-group">
                      <label className="control-label">Bullet 4 : </label>

                      <input
                        className="form-control"
                        placeholder="Bullet 4"
                        defaultValue={fieldData?.bullet4}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        name="bullet4"
                        type="text"
                      />
                      <div className="errorMsg">{errorsData?.bullet4}</div>
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-4">
                    <div className="form-group">
                      <label className="control-label">Bullet 5 :</label>

                      <input
                        className="form-control"
                        placeholder="Bullet 5"
                        defaultValue={fieldData?.bullet5}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        name="bullet5"
                        type="text"
                      />
                      <div className="errorMsg">{errorsData?.bullet5}</div>
                    </div>
                  </div>

                  <div className="col-md-6 col-xl-4 display-none">
                    <div className="form-group">
                      <label className="control-label">Bid Type :</label>
                      <div className="d-flex gap-5 mt-3">
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="bid_type"
                              value="Fixed"
                              checked={fieldData?.bid_type === "Fixed"}
                              onChange={handleChange}
                            />{" "}
                            Fixed
                          </label>
                        </div>

                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="bid_type"
                              value="Dynamic"
                              checked={fieldData?.bid_type === "Dynamic"}
                              onChange={handleChange}
                            />{" "}
                            Dynamic
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="row">
                  <div class="col-12 p-0">
                    <div class="comnbox">
                      <div id="filterTable" class=" client_table">
                        <div>
                          <div className="clientReportTitle flex-column">
                            <p>
                              <label className="text-danger">
                                Dynamic Variables available currently:{" "}
                                {"{StateName} , {ZipCode}"}
                              </label>
                            </p>
                            <p>
                              <label className="text-danger">
                                Allowed in : Headline ,Bullet-1 ,Bullet-2
                                ,Bullet-3 ,Bullet-4 ,Bullet-5.
                              </label>
                            </p>
                            <p>
                              <label className="text-danger">
                                Example : Insurance Plans in {"{StateName}"}{" "}
                                from [site.net].
                              </label>
                            </p>
                            <p>
                              <label className="text-danger">
                                Like : Insurance Plans in California from
                                [site.net].
                              </label>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="row">
                  <div class="col-12 p-0">
                    <div class="comnbox">
                      <div id="filterTable" class=" client_table">
                        <div>
                          <div className="clientReportTitle">
                            <h3>Time of Day Detail </h3>
                            <h3 className="notes-text">
                              ( Note : All times are EST )
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div class="tabs">
                    <ul class="tab-links camp-tab">
                      {week?.map((item, i) => (
                        <li
                          className={`${
                            tabState === `tab${i + 1}` ? "active" : ""
                            }`}
                          onClick={() => {
                            setTabState(`tab${i + 1}`);
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>

                    <div class="tab-content-campagins">
                      {weekDayDetails?.map((er, i) => (
                        <div
                          className={`tab ${
                            tabState === "tab" + (i + 1) ? "active" : ""
                            }`}
                        >
                          <div class="row">
                            <div class="col-sm-12 col-lg-8">
                              <div class="example-col">
                                <div>{er}</div>
                              </div>
                            </div>
                            <div className=" col m-4">
                              {er?.key === "Monday" ? (
                                <>
                                  <div class="border border-secondary rounded p-4">
                                    <div class="panel-body">
                                      <div className="radio">
                                        <label>
                                          <input
                                            type="radio"
                                            value="AllWeekdays"
                                            checked={
                                              selectedOption === "AllWeekdays"
                                            }
                                            onChange={(e) => {
                                              setSelectedOption(e.target.value);
                                            }}
                                          />{" "}
                                          Copy to All Weekdays
                                        </label>
                                      </div>
                                      <div className="radio">
                                        <label>
                                          <input
                                            type="radio"
                                            value="AllDays"
                                            checked={
                                              selectedOption === "AllDays"
                                            }
                                            onChange={(e) => {
                                              setSelectedOption(e.target.value);
                                            }}
                                          />{" "}
                                          Copy to All Days
                                        </label>
                                      </div>
                                      <div className="radio">
                                        <label>
                                          <input
                                            type="radio"
                                            value="None"
                                            checked={selectedOption === "None"}
                                            onChange={(e) => {
                                              setSelectedOption(e.target.value);
                                            }}
                                          />{" "}
                                          None
                                        </label>
                                      </div>

                                      <div
                                        className="mt-3 btn btn-primary"
                                        onClick={handleApplyBtn}
                                      >
                                        Apply
                                      </div>
                                    </div>
                                  </div>
                                  <label className="mt-4">
                                    <input
                                      type="checkbox"
                                      checked={
                                        fieldData[
                                        `delivery_checkbox_${er?.key}`
                                        ] === "No"
                                      }
                                      onChange={handleChangeChk}
                                      name={`delivery_checkbox_${er?.key}`}
                                    />{" "}
                                    No Delivery?
                                  </label>
                                </>
                              ) : (
                                <label className="mt-4">
                                  <input
                                    type="checkbox"
                                    checked={
                                      fieldData[
                                      `delivery_checkbox_${er?.key}`
                                      ] === "No"
                                    }
                                    onChange={handleChangeChk}
                                    name={`delivery_checkbox_${er?.key}`}
                                  />{" "}
                                  No Delivery?
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer text-end">
                {/* {this.state.status_exist == 1 ? ( */}
                {loadingState ? (
                  <button className="btn btn-info m-3">
                    <i className="fa fa-spinner fa-spin"></i>
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary m-3">
                    Save
                  </button>
                  //    )
                  //   ) : (
                  //     ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="perview_modal">
        <Modal shown={modalState} close={handleModel}>
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content add_widget_form">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Campaign Preview
                </h5>
              </div>
              <div className="modal-body">
                <div id="offerdiv">
                  <div class="listing">
                    <ul class="list-unstyled">
                      <li>
                        {/* <div class="countlisting">1</div> */}
                        <div className="row">
                          <div className="col-md-4">
                            <div class="listingimg">
                              <img src={imageCheckUrl(fieldData?.campaign_logo)} />
                            </div>
                            <div class="listingbutton">
                              <a href="javascript:void(0)" class="applyoffer">
                                Get Quote
                              </a>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div class="listingtext">
                              <h4>{fieldData?.headline}</h4>
                              <a href="javascript:void(0);">{fieldData?.display_url}</a>
                              
                              <ul class="style1 ccawidgetdescul mt-10">
                                <li>
                                   {fieldData?.bullet1}
                                </li>
                                <li>
                                  {fieldData?.bullet2}
                                </li>
                                <li>
                                  {fieldData?.bullet3}
                                </li>
                                {fieldData?.bullet4 != "" ? (
                                  <li>
                                    {fieldData?.bullet4}
                                  </li>
                                ) : (
                                  ""
                                )}
                                {fieldData?.bullet5 != "" ? (
                                  <li>
                                    {fieldData?.bullet5}
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default CampaignDetails;
