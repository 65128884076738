import React from 'react'
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import Graph from './Graph'
import { CampaignService } from "../../services";
import { MainLoader, Mini } from '../../common/Loader';
import moment from 'moment';
import { errorToaster, successToaster } from '../../common/toaster';
import { clearMessageText } from '../../store/actions';

const DashBoard = () => {


    const dispatch = useDispatch();

    const { campaigns, loading: mainLoading, campaignsError, countLoading, countData, countError, } = useSelector((state) => state.campaign);

    const { messageText } = useSelector((state) => state.login);

    const [selectedType, setSelectedType] = React.useState("today");
    const [campaignId, setCampaignId] = React.useState("");
    const [product, setProduct] = React.useState("");
    const [selectedDate, setSelectedDate] = React.useState({
        FromDate: new Date(),
        ToDate: new Date(),
    });


    const getCountFunc = (req) => {
        dispatch(CampaignService.getCount(req))
    }

    React.useEffect(() => {
        dispatch(CampaignService.GetCampaign({
            "type": "Active"
        }))
        getCountFunc({
            "fromdate": moment(new Date(selectedDate.FromDate)).format("YYYY-MM-DD"),
            "todate": moment(new Date(selectedDate.ToDate)).format("YYYY-MM-DD"),
            "campaign_id": campaignId,
            "product": product
        })
    }, [])

    const fieldchange = (e) => {
        let product = e.target[e.target.selectedIndex].getAttribute("data-name")

        setCampaignId(e.target.value)
        setProduct(product)
        getCountFunc({
            "fromdate": moment(new Date(selectedDate.FromDate)).format("YYYY-MM-DD"),
            "todate": moment(new Date(selectedDate.ToDate)).format("YYYY-MM-DD"),
            "campaign_id": e.target.value,
            "product": product
        })
    }

    const getAllCountData = () => {
        getCountFunc({
            "fromdate": moment(new Date(selectedDate.FromDate)).format("YYYY-MM-DD"),
            "todate": moment(new Date(selectedDate.ToDate)).format("YYYY-MM-DD"),
            "campaign_id": campaignId,
            "product": product
        })
    }

    const getLastMonday = (d) => {
        var day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    };

    const fielddatechange = (timeframe) => {
        setSelectedType(timeframe);

        var fromdate;
        var todate;

        if (timeframe === "today") {
            var d = new Date();
            fromdate = d;
            todate = d;
        } else if (timeframe === "yesterday") {
            var d = new Date();
            d.setDate(d.getDate() - 1);
            fromdate = d;
            todate = d;
        } else if (timeframe === "day_bef_yest") {
            var d = new Date();
            d.setDate(d.getDate() - 2);
            fromdate = d;
            todate = d;
        } else if (timeframe === "last_week") {
            var d = new Date();
            d.setDate(d.getDate() - 7);
            var mon = getLastMonday(d);
            fromdate = mon;
            var sun = new Date(mon);
            sun.setDate(mon.getDate() + 6);
            todate = sun;
        } else if (timeframe === "this_week") {
            var d = new Date();
            d.setDate(d.getDate());
            var mon = getLastMonday(d);
            fromdate = mon;
            var sun = new Date();
            sun.setDate(mon.getDate() + 6);
            todate = sun;
        } else if (timeframe === "this_month") {
            fromdate = new Date();
            fromdate.setDate(1);
            var todate = new Date();
            todate = new Date(
                new Date(todate.getFullYear(), todate.getMonth() + 1, 1) - 1
            );
        } else if (timeframe === "last_month") {
            todate = new Date();
            todate = new Date(
                new Date(todate.getFullYear(), todate.getMonth(), 1) - 1
            );

            fromdate = new Date();
            fromdate = new Date(
                new Date(fromdate.getFullYear(), fromdate.getMonth(), 1) - 1
            );
            fromdate.setDate(1);
        } else if (timeframe === "this_year") {
            fromdate = new Date();
            fromdate = new Date(new Date(fromdate.getFullYear(), 0, 1));
            todate = new Date();
            todate = new Date(new Date(todate.getFullYear(), 11, 31));
            // } else {
            //   var d = new Date();
            //   fromdate = d;
            //   todate = d;
        }

        setSelectedDate({
            FromDate: fromdate,
            ToDate: todate,
        });
        if (timeframe !== "custom") {
            getCountFunc({
                "fromdate": moment(new Date(fromdate)).format("YYYY-MM-DD"),
                "todate": moment(new Date(todate)).format("YYYY-MM-DD"),
                "campaign_id": campaignId,
                "product": product
            })
        }

    };

    const handleChangeend = (date, type) => {
        if (type === 1) {
            setSelectedDate((old) => ({ ...old, FromDate: date }));
        } else {
            setSelectedDate((old) => ({ ...old, ToDate: date }));
        }
    };

    React.useEffect(() => {
        if (messageText?.type === "success") {
            successToaster(messageText?.message)
            dispatch(clearMessageText())
        }
        else if (messageText?.type === "error") {
            errorToaster(messageText?.message)
            dispatch(clearMessageText())
        }
    }, [messageText])


    return (
        <>
            {mainLoading ? <MainLoader /> : (
                <div className="page px-4 comnpadding">
                    <div class="page-header">
                        <div class="page-header-actions">
                            <ol class="breadcrumb">
                            </ol>
                        </div>
                    </div>
                    <div className="page-content container-fluid">
                        <div class="mb-4">
                            <div className="card">
                                <div className="card-block">
                                    <div className="col-xl-12 col-lg-12">
                                        <div className="row">
                                            <div className="col-md-3 margin15b margin15t">
                                                <select className="form-control" onChange={fieldchange} name="campaign_name">
                                                    <option value="">-All Campaigns-</option>
                                                    {/* <option value="" data-name="Calls">All Call Campaigns</option> */}
                                                    <option value="" data-name="Clicks">All Clicks Campaigns</option>
                                                    <option value="" data-name="Leads">All Leads Campaigns</option>
                                                    {campaigns.length > 0 &&
                                                        campaigns.map(item =>
                                                            <option key={item.campaign_name} value={item.buyer_lead_type_id} data-name={item.product}>{item.display_name}</option>
                                                        )}

                                                </select>
                                            </div>
                                            <div className="col-md-3 margin15b margin15t">

                                                <div className="nav-tabs-custom">

                                                    <select name="timeframe" onChange={(e) =>
                                                        fielddatechange(e.target.value)
                                                    }
                                                        value={selectedType}
                                                        className="form-control" >

                                                        <option value="today">Today</option>
                                                        <option value="yesterday">Yesterday</option>
                                                        <option value="day_bef_yest">Day Before Yesterday</option>
                                                        <option value="last_week">Last Week</option>
                                                        <option value="this_week">This Week</option>
                                                        <option value="this_month">This Month</option>
                                                        <option value="last_month">Last month</option>
                                                        <option value="this_year">YTD</option>
                                                        <option value="custom">custom</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {/* <div className="d-flex flex-wrap mb-3 filter-outer-container"> */}

                                            <div className="col-md-2 align-self-center margin15b margin15t">
                                                <div className="nav-tabs-custom">
                                                    <DatePicker
                                                        // selected={selectedDate.FromDate}
                                                        onChange={(e) => handleChangeend(e, 1)}
                                                        // startDate={this.state.startDate}
                                                        // endDate={this.state.endDate}
                                                        // minDate={this.state.startDate}
                                                        startDate={selectedDate.FromDate}
                                                        endDate={selectedDate.ToDate}
                                                        selected={selectedDate.FromDate}
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        // className="form-control"
                                                        placeholderText="From Date"
                                                        dateFormat="dd-MM-yyyy"
                                                        showYearDropdown
                                                        locale="en-GB"
                                                        disabled={
                                                            selectedType !== "custom" ? true : false
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-2 align-self-center bord-lef margin15b margin15t">
                                                <div className="nav-tabs-custom">
                                                    <DatePicker
                                                        // selected={selectedDate.ToDate}
                                                        onChange={(e) => handleChangeend(e, 2)}
                                                        // startDate={this.state.startDate}
                                                        // endDate={this.state.endDate}
                                                        // minDate={this.state.startDate}
                                                        startDate={selectedDate.FromDate}
                                                        endDate={selectedDate.ToDate}
                                                        selected={selectedDate.ToDate}
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        // className="form-control"
                                                        placeholderText="To Date"
                                                        dateFormat="dd-MM-yyyy"
                                                        showYearDropdown
                                                        // locale="en-GB"
                                                        disabled={
                                                            selectedType !== "custom" ? true : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {
                                                selectedType !== "custom" ? null : <div className="col-md-2 align-self-center text-end margin15b margin15t">
                                                    <button type="submit" className="btn btn-primary" data-toggle="tooltip" data-placement="bottom" onClick={getAllCountData} title="Generate"><i className="fa fa-send"></i></button>
                                                </div>
                                            }

                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='row g-4'>
                            <div className='col-xxl-3'>
                                <div class="h-100">
                                    <div className="row g-xxl-0 info-box-con">
                                        {/* <div className="col-xxl-12 col-lg-4"> */}
                                        <div className="counter counter-lg counter-inverse">
                                            {/* <Link to={"/campaign"}> */}
                                            <div style={{ cursor: "pointer" }} className="counter-label">
                                                <i class="far fa-bullhorn fa-xs"></i>Campaigns
                                            </div>
                                            {/* </Link> */}
                                            {countLoading ? (<Mini />) : (
                                                <span className="counter-number">{countData?.campaign_count}</span>
                                            )}

                                        </div>
                                        {/* </div> */}
                                        {/* <div className="col-xxl-12 col-lg-4"> */}
                                        <div className="counter counter-lg counter-inverse">
                                            <div className="counter-label"><i class="far fa-hand-pointer"></i>Clicks</div>


                                            {countLoading ? (<Mini />) : (
                                                <div class="counter-number" >
                                                    {countData?.totalclicks}
                                                </div>
                                            )}


                                        </div>
                                        {/* </div> */}

                                         {/* <div className="col-xxl-12 col-lg-4"> */}
                                         <div className="counter counter-lg counter-inverse">
                                            <div className="counter-label"><i class="far fa-percent"></i>CTR</div>
                                            {countLoading ? (<Mini />) : (
                                                <span className="counter-number">{countData?.ctr} %</span>
                                            )}
                                        </div>
                                        {/* </div> */}

                                        {/* <div className="col-xxl-12 col-lg-4"> */}
                                        {/* <div className="counter counter-lg counter-inverse">
                                            <div className="counter-label"><i class="far fa-phone"></i>Total Calls</div>
                                            {countLoading ? (<Mini />) : (
                                                <span className="counter-number">{countData?.totalcalls}</span>
                                            )}
                                        </div> */}
                                        {/* </div> */}

                                         {/* <div className="col-xxl-12 col-lg-4"> */}
                                         {/* <div className="counter counter-lg counter-inverse">
                                            <div className="counter-label"><i class="far fa-clock"></i>Avg Duration</div>
                                            {countLoading ? (<Mini />) : (
                                                <span className="counter-number">{countData?.avg_duration}</span>
                                            )}
                                        </div> */}
                                        {/* </div> */}
                                        

                                        {/* <div className="col-xxl-12 col-lg-4"> */}
                                        <div className="counter counter-lg counter-inverse">
                                            <div className="counter-label"><i class="far fa-user-headset"></i>Total Leads</div>
                                            {countLoading ? (<Mini />) : (
                                                <span className="counter-number">{countData?.totalleads}</span>

                                            )}
                                        </div>
                                        {/* </div> */}
                                        {/* <div className="col-lg-3">
                                                </div> */}
                                       

                                       
                                        {/* <div className="col-xxl-12 col-lg-4"> */}
                                        <div className="counter counter-lg counter-inverse">
                                            <div className="counter-label"><i class="far fa-chart-line"></i>Conversion Rate</div>
                                            {countLoading ? (<Mini />) : (
                                                <span className="counter-number">{countData?.conversionrate}</span>
                                            )}
                                        </div>
                                        {/* </div> */}
                                        {/* <div className="col-lg-3">
                                                </div>
                                                <div className="col-lg-3">
                                                </div>
                                                <div className="col-lg-3">
                                                </div> */}
                                        {/* <div className="col-xxl-12 col-lg-4"> */}
                                        <div className="counter counter-lg counter-inverse">
                                            <div className="counter-label"><i class="far fa-usd-circle"></i>Total Cost</div>
                                            {countLoading ? (<Mini />) : (
                                                <span className="counter-number">{countData?.totalcost}</span>
                                            )}
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-9'>
                                <div class="">
                                    <div class="clientReportTitle">
                                        <h3 className='my-1'>Leads & Clicks Overview</h3>
                                    </div>
                                    <div className="card ">
                                        <div className="card-block">
                                            <div className="col-xl-12 col-lg-12">

                                                <Graph loading={countLoading} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            )
            }
        </>
    )
}

export default DashBoard