export const campaignActionTypes = {
  GET_CAMPAIGN: "GET_CAMPAIGN",

  GET_CAMPAIGN_SUCCESS: "GET_CAMPAIGN_SUCCESS",
  GET_CAMPAIGN_ERROR: "GET_CAMPAIGN_ERROR",
  CLEAR_CAMPAIGN_DETAILS: "CLEAR_CAMPAIGN_DETAILS",

  GET_COUNT: "GET_COUNT",
  GET_COUNT_SUCCESS: "GET_COUNT_SUCCESS",
  GET_COUNT_ERROR: "GET_COUNT_ERROR",

  GET_CAMPAIGN_LIST: "GET_CAMPAIGN_LIST",
  GET_CAMPAIGN_LIST_SUCCESS: "GET_CAMPAIGN_LIST_SUCCESS",
  GET_CAMPAIGN_LIST_ERROR: "GET_CAMPAIGN_LIST_ERROR",

  GET_LEADTYPE_N_QUALITY: "GET_LEADTYPE_N_QUALITY",
  GET_LEADTYPE_N_QUALITY_SUCCESS: "GET_LEADTYPE_N_QUALITY_SUCCESS",
  GET_LEADTYPE_N_QUALITY_ERROR: "GET_LEADTYPE_N_QUALITY_ERROR",

  GET_CAMPAIGN_STATUS: "GET_CAMPAIGN_STATUS",
  GET_CAMPAIGN_STATUS_SUCCESS: "GET_CAMPAIGN_STATUS_SUCCESS",
  GET_CAMPAIGN_STATUS_ERROR: "GET_CAMPAIGN_STATUS_ERROR",
  GET_CAMPAIGN_STATUS_RESET: "GET_CAMPAIGN_STATUS_RESET",

  ADD_CAMPAIGN: "ADD_CAMPAIGN",
  ADD_CAMPAIGN_SUCCESS: "ADD_CAMPAIGN_SUCCESS",
  ADD_CAMPAIGN_ERROR: "ADD_CAMPAIGN_ERROR",
  ADD_CAMPAIGN_RESET: "ADD_CAMPAIGN_RESET",
}
