export const clientActionTypes = {
  GET_ALL_CLIENTS: "GET_ALL_CLIENTS",
  GET_ALL_CLIENTS_SUCCESS: "GET_ALL_CLIENTS_SUCCESS",
  GET_ALL_CLIENTS_ERROR: "GET_ALL_CLIENTS_ERROR",

  GET_CLIENT_REPORT: "GET_CLIENT_REPORT",
  GET_CLIENT_REPORT_SUCCESS: "GET_CLIENT_REPORT_SUCCESS",
  GET_CLIENT_REPORT_ERROR: "GET_CLIENT_REPORT_ERROR",
  GET_CLIENT_REPORT_CLEAR: "GET_CLIENT_REPORT_CLEAR",

  CLEAR_CLIENT_DETAILS: "CLEAR_CLIENT_DETAILS",

  GET_CLIENTS_CAMPAIGN: "GET_CLIENTS_CAMPAIGN",
  GET_CLIENTS_CAMPAIGN_SUCCESS: "GET_CLIENTS_CAMPAIGN_SUCCESS",
  GET_CLIENTS_CAMPAIGN_ERROR: "GET_CLIENTS_CAMPAIGN_ERROR",
  GET_CLIENTS_CAMPAIGN_RESET: "GET_CLIENTS_CAMPAIGN_RESET",

  GET_CLIENTS_BID_REPORT: "GET_CLIENTS_BID_REPORT",
  GET_CLIENTS_BID_REPORT_SUCCESS: "GET_CLIENTS_BID_REPORT_SUCCESS",
  GET_CLIENTS_BID_REPORT_ERROR: "GET_CLIENTS_BID_REPORT_ERROR",
  GET_CLIENTS_BID_REPORT_RESET: "GET_CLIENTS_BID_REPORT_RESET",
};
