import { campaignActionTypes } from "../constants";

export const getCampaign = () => {
  return {
    type: campaignActionTypes.GET_CAMPAIGN,
  };
};

export const getCampaignSuccess = (data) => {
  return {
    type: campaignActionTypes.GET_CAMPAIGN_SUCCESS,
    payload: data,
  };
};

export const getCampaignError = (error) => {
  return {
    type: campaignActionTypes.GET_CAMPAIGN_ERROR,
    payload: error,
  };
};
export const clearCampaignDetails = () => {
  return {
    type: campaignActionTypes.CLEAR_CAMPAIGN_DETAILS,
  };
};

export const getCampaignCount = () => {
  return {
    type: campaignActionTypes.GET_COUNT,
  };
};

export const getCampaignCountSuccess = (data) => {
  return {
    type: campaignActionTypes.GET_COUNT_SUCCESS,
    payload: data,
  };
};

export const getCampaignCountError = (error) => {
  return {
    type: campaignActionTypes.GET_COUNT_ERROR,
    payload: error,
  };
};
export const getCampaignList = () => {
  return {
    type: campaignActionTypes.GET_CAMPAIGN_LIST,
  };
};

export const getCampaignListSuccess = (data) => {
  return {
    type: campaignActionTypes.GET_CAMPAIGN_LIST_SUCCESS,
    payload: data,
  };
};

export const getCampaignListError = (error) => {
  return {
    type: campaignActionTypes.GET_CAMPAIGN_LIST_ERROR,
    payload: error,
  };
};

export const getLeadTypeNQuality = () => {
  return {
    type: campaignActionTypes.GET_LEADTYPE_N_QUALITY,
  };
};

export const getLeadTypeNQualitySuccess = (data) => {
  return {
    type: campaignActionTypes.GET_LEADTYPE_N_QUALITY_SUCCESS,
    payload: data,
  };
};

export const getLeadTypeNQualityError = (error) => {
  return {
    type: campaignActionTypes.GET_LEADTYPE_N_QUALITY_ERROR,
    payload: error,
  };
};
export const getCampaignStatus = () => {
  return {
    type: campaignActionTypes.GET_CAMPAIGN_STATUS,
  };
};

export const getCampaignStatusSuccess = (data) => {
  return {
    type: campaignActionTypes.GET_CAMPAIGN_STATUS_SUCCESS,
    payload: data,
  };
};

export const getCampaignStatusError = (error) => {
  return {
    type: campaignActionTypes.GET_CAMPAIGN_STATUS_ERROR,
    payload: error,
  };
};

export const getCampaignStatusReset = () => {
  return {
    type: campaignActionTypes.GET_CAMPAIGN_STATUS_RESET,
  };
};
export const addCampaign = () => {
  return {
    type: campaignActionTypes.ADD_CAMPAIGN,
  };
};

export const addCampaignSuccess = (data) => {
  return {
    type: campaignActionTypes.ADD_CAMPAIGN_SUCCESS,
    payload: data,
  };
};

export const addCampaignError = (error) => {
  return {
    type: campaignActionTypes.ADD_CAMPAIGN_ERROR,
    payload: error,
  };
};

export const addCampaignReset = () => {
  return {
    type: campaignActionTypes.ADD_CAMPAIGN_RESET,
  };
};