import React from 'react';
import { useMutation } from "react-query";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useLocation } from "react-router";
import styles from "./CampaignModal.module.css";
import { Multiselect } from 'multiselect-react-dropdown';
import { Tooltip } from 'react-tooltip';

// query.name = 1 => filter Listing
// query.name = 2 => filter remove 
// Update Filter
// query.name = 3 => getting filter Old Data
// query.name = 4 => update filter
// Add New Filter
// query.name = 5 => filter by type
// query.name = 6 => filter by field Name
// query.name = 4 => update filter

const filterAPI = async (req) => {
    // console.log("🚀 ~ file: DayWiseCap.js:16 ~ filterAPI ~ req:", req)
    let query = req
    // if (req.name === 2) {
    //     query = req
    // } else {
    //     query = req
    // }
    let url = query.name === 1
        ? `${process.env.REACT_APP_API_URL}/getFilter`
        : query.name === 2 ? `${process.env.REACT_APP_API_URL}/removeFilter`
            : query.name === 3 ? `${process.env.REACT_APP_API_URL}/getFilterbyid`
                : query.name === 4 ? `${process.env.REACT_APP_API_URL}/UpdateFilter`
                    : query.name === 5 ? `${process.env.REACT_APP_API_URL}/getFilterbytype`
                        : `${process.env.REACT_APP_API_URL}/getFiltervalue`

    delete query["name"]
    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(query),
        headers: {
            "content-Type": "application/json",
            session: `${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
        }
    });

    if (!response.ok) {
        throw new Error("Something went wrong!");
    }

    return response.json();
};


const Filter = () => {



    let { userId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const multiselectRef = React.useRef();

    const [capData, setCapData] = React.useState([]);
    const [fields, setFields] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [modalInput, setModalInput] = React.useState("");
    const [modalContent, setModalContent] = React.useState({});

    // Add New Filter
    const [filterType, setFilterType] = React.useState([]);
    const [filterValue, setFilterValue] = React.useState([]);
    const [fltBody, setFltBody] = React.useState([]);
    const [flt_field_condition, setFlt_field_condition] = React.useState([]);
    const [deleteLoader, setDeleteLoader] = React.useState(false);
    const [submitLoader, setSubmitLoader] = React.useState(false);



    const {
        mutate,
        mutateAsync,
        isLoading: updateLoading,
        error: addError
    } = useMutation(filterAPI);

    const submitForm = async (e, comeFrom) => {
        e.preventDefault()
        let val = ""
        if (typeof modalInput?.field_value === "string") {
            val = modalInput.field_value
        } else {
            val = modalInput.field_value.map((obj) => obj.id).toString()
        }
        var changeValue = {}
        setSubmitLoader(true)
        if (comeFrom) {
            changeValue = await mutateAsync({
                itemID: userId,
                field_name: modalInput.field_name,
                field_condition: modalInput.field_condition,
                field_value: val,
                name: 4
            });
        } else {
            changeValue = await mutateAsync({
                itemID: modalContent.item,
                field_condition: modalInput.field_condition,
                field_value: val,
                name: 4
            });
        }

        if (changeValue.status === 1) {
            setSubmitLoader(false)
            navigate(`/filter/${userId}`)
            setOpenModal(false)
            setModalInput("")
            setModalContent({})
            setFilterType([])
            setFilterValue([])
            setFltBody([])
            setFlt_field_condition([])
        } else {
            setSubmitLoader(false)
        }
    };

    // const { data, error, status, isLoading: getPriceDataLoader } = useQuery(["asd", {
    //     leadID: userId,
    //     name: 1
    // }], filterAPI);

    const callListAPI = () => {
        mutateAsync({
            leadID: userId,
            name: 1
        }).then((res) => {
            // console.log(res);
            if (res.status === 1) {
                setCapData(res.responsedata)

                setFields(res.responsedata.results)
            } else if (res?.status === 0) {
                setFields([])
                setCapData(res?.responsedata)
            }
        }).catch((err) => {
            console.log("error Api ", err);
        });
    }
    // Create Filter
    // call when select filter option 
    const getFilterByTypeFunc = () => {
        mutateAsync({
            itemID: userId,
            name: 5
        }).then((res) => {
            // console.log(res);
            if (res.status === 1) {
                setFilterType(res.responsedata)

                // setFields([])
            } else if (res?.status === 0) {
                // setFields([])
                setFilterType([])
            }
        }).catch((err) => {
            console.log("error Api ", err);
        });
    }

    // call when field_name body option 
    const getFilterValueFunc = (field_name) => {
        mutateAsync({
            field_name: field_name,
            itemID: userId,
            name: 6
        }).then((res) => {
            // console.log(res);
            if (res.status === 1) {
                setFilterValue(res.responsedata)
            } else if (res?.status === 0) {
                setFilterValue([])
            }
        }).catch((err) => {
            console.log("error Api ", err);
        });
    }

    // Update Filter Function 

    const getFilterByIdFunc = (id) => {
        mutateAsync({
            itemID: id.toString(),
            campID: userId,
            name: 3
        }).then((res) => {
            // console.log(res);
            if (res.status === 1) {

                setModalInput(res.responsedata.results)
                setFltBody(res.responsedata.body)
                let data = {
                    body: res.responsedata.body,
                    match_condition: res.responsedata.match_condition,
                    match_condition_new: res.responsedata.match_condition_new,
                }
                setFilterValue(data)
                setFlt_field_condition(res.responsedata.results.field_condition)
                // setFilterType(res.responsedata)

                // setFields([])
            } else if (res?.status === 0) {
                // setFields([])
                // setFilterType([])
            }
        }).catch((err) => {
            console.log("error Api ", err);
        });
    }

    const fieldchange = (e) => {
        let fields = {};
        fields["field_name"] = e.target.value;
        fields["field_condition"] = "";
        fields["field_value"] = "";
        getFilterValueFunc(e.target.value)
        setModalInput(fields)
    }
    const change = (e) => {
        let fields = modalInput;
        let body = filterValue.body;
        fields[e.target.name] = e.target.value;
        // console.log("body", body)
        // console.log("e.target.value:", e.target.value)


        setFlt_field_condition(e.target.value)

        if (e.target.value) {
            if (body && fields.field_value) {
                // console.log(1);
                fields['field_value'] = "";
                multiselectRef.current.resetSelectedValues();
            }
            if (e.target.value === "anyof") {
                // console.log(2);
                fields['field_value'] = body;
                setFltBody(body)
                setModalInput(fields)
            } else if (e.target.value === "notanyof") {
                // console.log(3);
                fields['field_value'] = body;
                setFltBody(body)
                setModalInput(fields)
            } else {
                // console.log(4);
                fields['field_value'] = "";
                setFltBody(body)
                setModalInput(fields)
            }
        } else {
            // console.log(5);
            fields['field_value'] = "";
            setModalInput(fields)
        }
        // console.log("fields", fields)
    }

    const onRemove = (selectedList, removedItem) => {
        let fields = modalInput;
        fields['field_value'] = selectedList;
        setModalInput(fields)
        // console.log(selectedList)
    }
    const onSelect = (selectedList, selectedItem) => {
        let fields = modalInput;
        fields['field_value'] = selectedList;
        setModalInput(fields)
    }

    const handleChange = (e) => {
        let fields = modalInput;
        fields[e.target.name] = e.target.value;
        setModalInput(fields)
    }


    React.useEffect(() => {
        if (location) {
            callListAPI()
        }
    }, [location, mutateAsync])



    const deleteFilterItem = async (e) => {
        e.preventDefault()
        var changeName = {}
        setDeleteLoader(true)
        changeName = await mutateAsync({
            itemId: modalContent.item,
            name: 2
        });
        if (changeName.status === 1) {
            setOpenModal((oldState) => !oldState)
            setModalContent("")
            setModalInput("")
            callListAPI()
            setDeleteLoader(false)
        } else {
            setDeleteLoader(false)
        }
    }


    const openWidgetModal = (comeFrom, title, item) => {
        // console.log("🚀 ~ file: Campaign.js:82 ~ openWidgetModal ~ item:", item)

        // var value = ""
        // if (comeFrom === 5) {
        //     value = item.display_name
        //     getFilterByTypeFunc()
        // }
        setModalContent({
            title,
            item,
            comeFrom
        })
        // setModalInput(value)
        setOpenModal((oldState) => !oldState)
        if (comeFrom === 3) {
            getFilterByIdFunc(item)
        }
        if (comeFrom === 5) {
            getFilterByTypeFunc()
        }
    }

    const closeModalFunc = () => {
        setSubmitLoader(false)
        setOpenModal(false)
        setModalInput("")
        setModalContent({})
        setFilterType([])
        setFilterValue([])
        setFltBody([])
        setFlt_field_condition([])
    }

    function Modal({ children, shown, close }) {
        return shown ? (
            <div
                className={`modal-container ${styles.modalBackdrop}`}
                onClick={() => {
                    // close modal when outside of modal is clicked
                    close();
                }}
            >
                <div
                    className={styles.sidebar_add_widget_form__1TGwy}
                    onClick={(e) => {
                        // do not close modal if anything inside modal content is clicked
                        e.stopPropagation();
                    }}
                >
                    {/* <button onClick={close}>Close</button> */}
                    {children}
                </div>
            </div>
        ) : null;
    }

    const addWidgetModal = () => {

        // console.log("filterValue", filterValue);
        // console.log("modalInput", modalInput);
        // console.log("flt_field_condition", flt_field_condition);

        return (
            <Modal
                shown={openModal}
                close={closeModalFunc}
            >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content add_widget_form">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                {modalContent.title}
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form>
                                {modalContent.comeFrom === 2 && (
                                    <>
                                        <h6>Are You sure ?</h6>
                                        <p>You want Delete this Item?</p>
                                        <center>
                                            <button
                                                type="submit"
                                                className="btn btn-primary mt-3"
                                                disabled={deleteLoader}
                                                onClick={(e) => deleteFilterItem(e)}
                                            >
                                                {deleteLoader && <i class="fas fa-spinner fa-pulse"></i>}
                                                {deleteLoader ? `` : <i class="fa fa-trash" aria-hidden="true">{" Delete"}</i>}

                                            </button>
                                        </center>
                                    </>
                                )}
                                {modalContent.comeFrom === 3 && (
                                    <>
                                        <p>Filter : <span className='fw-bold'>{modalInput?.field_name}</span></p>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Filter Condition: <span className="red">*</span></label>
                                            <select className="form-control"
                                                onChange={change}
                                                name="field_condition">
                                                <option value="">-Select Filter Condition-</option>
                                                {filterValue && filterValue?.match_condition_new && filterValue?.match_condition_new.length > 0 ?
                                                    filterValue?.match_condition_new.map(item =>
                                                        <option key={item.key} value={item.key} selected={item.key == modalInput.field_condition}>{item.display_name}</option>
                                                    )
                                                    : ''}

                                            </select>
                                        </div>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Filter Value: <span className="red">*</span></label>
                                            {fltBody && (flt_field_condition === "anyof" || flt_field_condition === "notanyof") ?
                                                <Multiselect
                                                    ref={multiselectRef}
                                                    options={fltBody} // Options to display in the dropdown
                                                    selectedValues={modalInput.field_value} // Preselected value to persist in dropdown
                                                    onSelect={onSelect} // Function will trigger on select event
                                                    onRemove={onRemove} // Function will trigger on remove event
                                                    displayValue="name" // Property name to display in the dropdown options
                                                />
                                                : (fltBody && (flt_field_condition === "=" || flt_field_condition === "!=")) ?
                                                    <Multiselect
                                                        ref={multiselectRef}
                                                        options={fltBody} // Options to display in the dropdown
                                                        selectedValues={modalInput.field_value} // Preselected value to persist in dropdown
                                                        onSelect={onSelect} // Function will trigger on select event
                                                        onRemove={onRemove} // Function will trigger on remove event
                                                        displayValue="name"
                                                        singleSelect={true} // Property name to display in the dropdown options
                                                    />
                                                    :
                                                    (flt_field_condition && (flt_field_condition === "=" || flt_field_condition === "!=")) ?
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Filter value"
                                                            defaultValue={modalInput.field_value}
                                                            onChange={handleChange}
                                                            onKeyUp={handleChange}
                                                            name="field_value"
                                                        />
                                                        :
                                                        <textarea
                                                            className="form-control"
                                                            placeholder="Filter value"
                                                            defaultValue={modalInput.field_value}
                                                            onChange={handleChange}
                                                            onKeyUp={handleChange}
                                                            name="field_value"
                                                        />
                                            }
                                        </div>

                                        <center>
                                            <button
                                                type="submit"
                                                className="btn btn-primary mt-3"
                                                disabled={submitLoader}
                                                onClick={(e) => submitForm(e, false)}
                                            >
                                                {submitLoader && <i class="fas fa-spinner fa-pulse"></i>}
                                                {submitLoader ? `` : <i className="fa fa-edit" aria-hidden="true">{" Update"}</i>}

                                            </button>
                                        </center>
                                    </>
                                )}
                                {modalContent.comeFrom === 5 && (
                                    <>
                                        <label>Filter:</label>
                                        <select className="form-control"
                                            onChange={fieldchange}
                                            value={modalInput?.field_name}
                                            name="field_name">
                                            <option value="">-Select Filter-</option>
                                            {filterType && filterType?.results && filterType?.results.length > 0 ?
                                                filterType?.results.map(item =>
                                                    <option key={item.field_name} value={item.field_name} >{item.display_name}</option>
                                                )
                                                : ''}

                                        </select>
                                        <label>Filter Condition: <span className="red">*</span></label>
                                        <select className="form-control"
                                            onChange={change}
                                            name="field_condition">
                                            <option value="">-Select Filter Condition-</option>
                                            {filterValue && filterValue?.match_condition_new && filterValue?.match_condition_new.length > 0 ?
                                                filterValue?.match_condition_new.map(item =>
                                                    <option key={item.key} value={item.key} selected={item.key == modalInput.field_condition}>{item.display_name}</option>
                                                )
                                                : ''}

                                        </select>
                                        <label>Filter Value: <span className="red">*</span></label>
                                        {fltBody && (flt_field_condition === "anyof" || flt_field_condition === "notanyof") ?
                                            <Multiselect
                                                ref={multiselectRef}
                                                options={fltBody} // Options to display in the dropdown
                                                selectedValues={modalInput.field_value} // Preselected value to persist in dropdown
                                                onSelect={onSelect} // Function will trigger on select event
                                                onRemove={onRemove} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                            />
                                            : (fltBody && (flt_field_condition === "=" || flt_field_condition === "!=")) ?
                                                <Multiselect
                                                    ref={multiselectRef}
                                                    options={fltBody} // Options to display in the dropdown
                                                    selectedValues={modalInput.field_value} // Preselected value to persist in dropdown
                                                    onSelect={onSelect} // Function will trigger on select event
                                                    onRemove={onRemove} // Function will trigger on remove event
                                                    displayValue="name"
                                                    singleSelect={true} // Property name to display in the dropdown options
                                                />
                                                :
                                                (flt_field_condition && (flt_field_condition === "=" || flt_field_condition === "!=")) ?
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Filter value"
                                                        defaultValue={modalInput.field_value}
                                                        onChange={handleChange}
                                                        onKeyUp={handleChange}
                                                        name="field_value"
                                                    />
                                                    :
                                                    <textarea
                                                        className="form-control"
                                                        placeholder="Filter value"
                                                        defaultValue={modalInput.field_value}
                                                        onChange={handleChange}
                                                        onKeyUp={handleChange}
                                                        name="field_value"
                                                    />
                                        }

                                        <center>
                                            <button
                                                type="submit"
                                                className="btn btn-primary mt-3"
                                                disabled={submitLoader}
                                                onClick={(e) => submitForm(e, true)}
                                            >
                                                {submitLoader && <i class="fas fa-spinner fa-pulse"></i>}
                                                {submitLoader ? `` : <i class="fa fa-floppy-o" aria-hidden="true">{" Save"}</i>}

                                            </button>
                                        </center>
                                    </>
                                )}

                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };
    const viewModal = React.useMemo(
        () => addWidgetModal(),
        [
            openModal,
            modalInput,
            fltBody,
            flt_field_condition,
            filterType,
            filterValue,
            submitLoader,
            deleteLoader
        ]
    );
    return (
      <div className="page comnpadding px-2">
        <div className="page-content container-fluid mt-3">
          <div className="comnbox">
            <div class="page-header">
              <div className="d-flex gap-3 mb-3">
                <div class="clientReportTitle gap-3 justify-content-start mb-0">
                  <Link
                    id="backbtn"
                    data-tooltip-content="Back"
                    to={"/campaign"}
                    className="text-blute"
                  >
                    <i className="fa fa-arrow-left"></i>
                  </Link>
                  <Tooltip
                    place="bottom"
                    className="tool_tip_graph"
                    anchorId="backbtn"
                  />
                  <h3>{capData?.product} Filter</h3>
                </div>
                <div
                  className={"btn btn-icon btn-primary btn-outline"}
                  onClick={() => openWidgetModal(5, "Add Filter", null)}
                >
                  <i class="fa fa-plus"></i>
                </div>
              </div>
              <div class="page-header-actions ps-1">
                <ol class="breadcrumb">
                  <strong>
                    {capData?.Campaign_code &&
                      `[ Campaign : ${capData?.Campaign_code} ]`}
                  </strong>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <table className="table table-striped" id="cus-filter">
                  {fields.length > 0 ? (
                    <>
                      <thead className="bg-primary-100">
                        <tr>
                          <th>Filter</th>
                          <th>Filter Condition</th>
                          <th>Filter Value</th>
                          <th>Make a Change</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map((item) => (
                          <tr key={item.id}>
                            <td>{item.display_name}</td>
                            <td>
                              {item.field_condition_display_name} (
                              {item.field_condition})
                            </td>

                            <td>{item.field_value}</td>
                            <td>
                              <button
                                type="submit"
                                className="btn btn-sm btn-warning me-2"
                                onClick={() =>
                                  openWidgetModal(3, "Edit Filter", item.id)
                                }
                              >
                                <i className="fa fa-edit"></i>
                              </button>

                              <button
                                type="submit"
                                className="btn btn-sm btn-outline-danger"
                                onClick={() =>
                                  openWidgetModal(2, "Delete", item.id)
                                }
                              >
                                <i className="fa fa-trash"></i>{" "}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    capData?.Campaign_code && (
                      <p colSpan={2}>Data Not Found !</p>
                    )
                  )}
                </table>
              </div>
            </div>
            {viewModal}
          </div>
        </div>
      </div>
    );
}

export default Filter