import axios from "axios";
// import { parseInt } from "lodash";
import {
  getWidget,
  getWidgetSuccess,
  getWidgetError,
  // resetLogin,
  getWidgetList,
  getWidgetListSuccess,
  getWidgetListError,
  saveWidgetList,
  saveWidgetListSuccess,
  saveWidgetListError,
  addedWidgetData,
  layoutChangeSuccess,
  addedWidget,
  autoWidgetRefreshList,
  autoWidgetRefreshListError,
  autoWidgetRefreshLoading, loginError,
  resetFilterLoader
} from "../store/actions";

// let filter = { FromDate: "10-12-2022", ToDate: "22-12-2022" };

export class widgetService {
  static getWidget = (req) => {
    // console.log("🚀 ~ getWidget ~ req", req);
    return (dispatch) => {
      dispatch(getWidget());
      axios
        .post(
          `${process.env.REACT_APP_API_URL + "/" + req.key + "?key=" + req.label
          }`,
          {
            FromDate: req.filter.FromDate,
            ToDate: req.filter.ToDate,
            graph: req?.graphType?.label
          },
          // {},
          {
            headers: {
              "content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
            },
          }
        )
        .then((response) => {
          // console.log(
          //   "🚀 ~ file: widget.js:41 ~ widgetService ~ .then ~ response",
          //   response
          // );
          if (response.data.status === 1) {
            dispatch(addedWidgetData({
              ...req,
              axiesData: response.data?.axiesData
            }));
            dispatch(addedWidget(req));
            if (req.mode === "map") {
              dispatch(
                getWidgetSuccess({
                  value: {
                    newresponsedata: response.data.newresponsedata,
                    responseData: response.data.responseData,
                  },
                  message: response.data.message,
                  id: req.id,
                  mode: req.mode,
                  axiesData: response.data?.axiesData
                })
              );
            } else {
              if (req?.graphType) {
                dispatch(
                  getWidgetSuccess({
                    value: response.data.responseData,
                    message: response.data.message,
                    id: req.id,
                    mode: req.mode,
                    graphType: req.graphType,
                    axiesData: response.data?.axiesData
                  })
                );
              } else {
                dispatch(
                  getWidgetSuccess({
                    value: response.data.responseData,
                    message: response.data.message,
                    id: req.id,
                    mode: req.mode,
                    axiesData: response.data?.axiesData
                  })
                );
              }
            }
          } else if (response.data.status === 0) {
            dispatch(addedWidgetData({
              ...req,
              axiesData: response.data?.axiesData
            }));
            dispatch(addedWidget(req));
            if (req.mode === "map") {
              dispatch(
                getWidgetSuccess({
                  value: {
                    newresponsedata: [],
                    responseData: [],
                  },
                  message: response.data.message,
                  id: req.id,
                  mode: req.mode,
                  axiesData: response.data?.axiesData
                })
              );
            } else {
              if (req?.graphType) {
                dispatch(
                  getWidgetSuccess({
                    value: response.data.responseData,
                    message: response.data.message,
                    id: req.id,
                    mode: req.mode,
                    graphType: req.graphType,
                    axiesData: response.data?.axiesData
                  })
                );
              } else {
                dispatch(
                  getWidgetSuccess({
                    value: response.data.responseData,
                    message: response.data.message,
                    id: req.id,
                    mode: req.mode,
                    axiesData: response.data?.axiesData
                  })
                );
              }
            }
          } else {
            dispatch(getWidgetError(response.data.message));
          }
        })
        .catch((error) => {
          // console.log(
          //   "🚀 ~ file: widget.js:69 ~ widgetService ~ return ~ error",
          //   error
          // );
          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            dispatch(
              getWidgetError({
                id: req.index,
                message: "Widget error",
              })
            );
          }
        });
    };
  };

  static autoRefreshGetWidget = (req) => {
    // console.log("🚀 ~ autoRefreshGetWidget ~ req", req);
    return (dispatch) => {
      dispatch(autoWidgetRefreshLoading());
      axios
        .post(
          `${process.env.REACT_APP_API_URL + "/" + req.key + "?key=" + req.label
          }`,
          req.filter,
          // {},
          {
            headers: {
              "content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
            },
          }
        )
        .then((response) => {
          if (req?.loader) {
            dispatch(resetFilterLoader())
          }
          if (response.data.status === 1) {
            // console.log(JSON.parse(JSON.stringify(response.data.responseData)));
            if (req.mode === "map") {
              dispatch(
                autoWidgetRefreshList({
                  value: {
                    newresponsedata: response.data.newresponsedata,
                    responseData: response.data.responseData,
                  },
                  message: response.data.message,
                  id: req.index,
                  axiesData: response.data?.axiesData
                })
              );
            } else {
              dispatch(
                autoWidgetRefreshList({
                  value: response.data.responseData,
                  message: response.data.message,
                  id: req.index,
                  axiesData: response.data?.axiesData
                })
              );
            }
          } else if (response.data.status === 0) {
            // let data = {
            //   message: response.data.message,
            //   value: response.data.responseData,
            //   id: req.index,
            // };
            if (req.mode === "map") {
              dispatch(
                autoWidgetRefreshList({
                  value: {
                    newresponsedata: response.data.newresponsedata,
                    responseData: response.data.responseData,
                  },
                  message: response.data.message,
                  id: req.index,
                  axiesData: response.data?.axiesData
                })
              );
            } else {
              dispatch(
                autoWidgetRefreshList({
                  message: response.data.message,
                  value: response.data.responseData,
                  id: req.index,
                  axiesData: response.data?.axiesData
                })
              );
            }
          } else {
            dispatch(autoWidgetRefreshListError(response.data.message));
          }
        })
        .catch((error) => {
          // console.log(
          //   "🚀 ~ file: widget.js:215 ~ widgetService ~ return ~ error",
          //   error
          // );
          if (req?.loader) {
            dispatch(resetFilterLoader())
          }
          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            dispatch(
              autoWidgetRefreshListError({
                id: req.index,
                message: "Widget error",
              })
            );
          }
        });
    };
  };

  static getWidgetList = (req) => {
    // console.log("🚀 ~ file: Widget.js ~ line 6 ~ widgetService ~ req", req);
    return (dispatch) => {
      dispatch(getWidgetList());
      axios
        .post(
          `${process.env.REACT_APP_API_URL + "/" + req}`,
          {},
          {
            headers: {
              "content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(
              layoutChangeSuccess(
                JSON.parse(response.data.responseData.results.widgetData)
              )
            );
            dispatch(
              getWidgetListSuccess({
                value: JSON.parse(
                  response.data.responseData.results.widgetData
                ),
                message: response.data.message,
              })
            );
            // dispatch(
            //   applyMainFilter(
            //     JSON.parse(response.data.responseData.results.filterData)
            //   )
            // );
          } else if (response.data.status === 0) {
            dispatch(
              getWidgetListSuccess({
                value: response.data.responseData,
                message: response.data.message,
              })
            );
          } else {
            dispatch(getWidgetListError(response.data.message));
          }
        })
        .catch((error) => {
          if (error?.response?.data) {
            if (error.response.data === "Unauthorized.") {
              dispatch(loginError("Session Expired !"));
              global.localStorage.clear();
            } else {
              dispatch(getWidgetListError(error.response.data));
            }
          }
        });
    };
  };

  static saveWidgetData = (req) => {
    // console.log("🚀 ~ file: Widget.js ~ line 6 ~ widgetService ~ req", req);
    return (dispatch) => {
      dispatch(saveWidgetList());
      axios
        .post(
          `${process.env.REACT_APP_API_URL + "/" + req.url}`,
          { widgetData: req.data, filterData: req.filterData },
          {
            headers: {
              "content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status === 1) {
            dispatch(
              saveWidgetListSuccess({
                value: response.data.responseData,
                message: response.data.message,
              })
            );
          } else if (response.data.status === 0) {
            dispatch(
              saveWidgetListSuccess({
                value: response.data.responseData,
                message: response.data.message,
              })
            );
          } else {
            dispatch(saveWidgetListError(response.data.message));
          }
        })
        .catch((error) => {
          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            dispatch(saveWidgetListError(error.response.data));
          }
        });
    };
  };
}
