import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Headers/Headers";
// import Sidebar from "../components/Sidebar/Sidebar";

function Layout({ children }) {
  const location = useLocation();
  var PathsArrays = ["/login", "/forgotPassword"];
  return (
    <>
      {!PathsArrays.includes(location.pathname) ?
        location.pathname.toLowerCase().includes("/apispecs") ?
          <>{children}</>
          : location.pathname.toLowerCase().includes("/directLogin") ?
            <>{children}</> :
            (
              <>
                <Header />
                <div className="container-fluid g-0 page-body-wrapper">
                  {/* {location?.pathname === "/" ? <Sidebar /> : ""} */}
                  <div className="main-panel">
                    {children}
                  </div>
                </div>
              </>
            ) : (
          <>{children}</>
        )}
    </>
  );
}

export default Layout;
