import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CampaignService } from "../../services";
import { Link } from 'react-router-dom';
import DynamicTable from '../../common/DynamicTable';
import styles from "./CampaignModal.module.css";
import { useMutation, useQueryClient } from "react-query";
import { Tooltip } from "react-tooltip";

const changeCampaignName = async (req) => {
  let query = req
  let url = req.name === 1
    ? `${process.env.REACT_APP_API_URL}/CampaignChangeDisplayname`
    : req.name === 2 ? `${process.env.REACT_APP_API_URL}/Updateprice`
      : `${process.env.REACT_APP_API_URL}/CampaignChangeStatus`

  delete query["name"]
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "content-Type": "application/json",
      session: `${localStorage.getItem("access_token")}`,
      Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
    }
  });

  if (!response.ok) {
    throw new Error("Something went wrong!");
  }

  return response.json();
};



const Campaign = () => {

  const dispatch = useDispatch();
  const queryClient = useQueryClient();


  const { campaigns,
    loading,
    campaignsError,
    countLoading,
    countData,
    countError,
    campaignListLoading: mainLoading,
    campaignListData: c_ListData,
    campaignListError,
    LNQData,
    LNQError,
    LNQLoading,
  } = useSelector((state) => state.campaign);
  const { userdata } = useSelector((state) => state.login.data);



  const [campaignType, setCampaignType] = React.useState("All");
  const [openModal, setOpenModal] = React.useState(false);
  const [openContent, setOpenContent] = React.useState({});
  const [modalInput, setModalInput] = React.useState("");
  const [campaignListData, setCampaignListData] = React.useState("");
  const [activeLeads, setActiveLeads] = React.useState({});

  const getAllCampaignListFunc = (req) => {
    dispatch(CampaignService.getAllCampaignList(req))
  }

  React.useEffect(() => {
    getAllCampaignListFunc({
      "pageNumber": 1,
      "perpage": 20,
      "type": "All"
    })
    dispatch(CampaignService.getLeadtypeandQuality())
  }, [])

  React.useEffect(() => {
    setCampaignListData(c_ListData)
    if (activeLeads.lead_type_name) {
      let oldData = c_ListData?.results?.filter((chk) => chk.lead_type_name === activeLeads.lead_type_name)
      if (oldData) {
        setCampaignListData({
          Records: oldData.length,
          results: oldData
        })
      } else {
        setCampaignListData({
          Records: 0,
          results: []
        })
      }
    }
  }, [c_ListData])

  React.useEffect(() => {
    if (LNQData?.lead_results) {
      setActiveLeads(LNQData?.lead_results[0])
      let oldData = c_ListData?.results?.filter((chk) => chk.lead_type_name === LNQData?.lead_results[0].lead_type_name)
      if (oldData) {
        setCampaignListData({
          Records: oldData.length,
          results: oldData
        })
      } else {
        setCampaignListData({
          Records: 0,
          results: []
        })
      }
    }
  }, [LNQData])

  const [showToaster, setShowToaster] = React.useState(false)


  const fieldchange = (e) => {
    let val = e.target.value

    setCampaignType(val)
    let oldData = []
    if (val === "All") {
      oldData = c_ListData?.results?.filter((chk) =>
        chk.lead_type_name === activeLeads.lead_type_name)
    } else {
      oldData = c_ListData?.results?.filter((chk) =>
        chk.lead_type_name === activeLeads.lead_type_name &&
        chk.status === val)
    }
    setCampaignListData({
      Records: oldData ? oldData.length : 0,
      results: oldData ? oldData : []
    })
    // getAllCampaignListFunc({
    //   "pageNumber": 1,
    //   "perpage": 20,
    //   "type": val
    // })
  }

  const openWidgetModal = (comeFrom, title, item) => {
    var value = ""
    if (comeFrom === 1) {
      value = item.display_name
    }
    setOpenContent({
      title,
      item,
      comeFrom
    })
    setModalInput(value)
    setOpenModal((oldState) => !oldState)
  }


  const columns = [
    {
      dataField: "buyer_lead_type_id",
      text: "Id",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" };
      },
    },
    {
      dataField: "lead_type_name",
      text: "Line of Business",
      headerStyle: (colum, colIndex) => {
        return { width: "90px", textAlign: "center" };
      },
      formatter: (cell, item) => {
        return <>{item.lead_type_name}</>;
      },
    },
    {
      dataField: "lead_type_name",
      text: "Campaign Name",
      headerStyle: (colum, colIndex) => {
        return { width: "220px", textAlign: "center" };
      },
      formatter: (cell, item) => {
        return (
          <>
            {" "}
            <b>
              <span className="text-danger">[{item.display_name}]</span>
              <span
                className="btn btn-sm btn-outline btn-default"
                onClick={() =>
                  openWidgetModal(1, "Change Display Name", item)
                }
                id={`change-name-${item?.buyer_lead_type_id}`}
                data-tooltip-content="Change Display Name"
                title="Change Display Name"
              >
                <i className="fa fa-edit"></i>

                <Tooltip
                  place="bottom"
                  className="tool_tip_graph"
                  anchorId={`change-name-${item?.buyer_lead_type_id}`}
                />
              </span>
            </b>
          </>
        );
      },
    },
    {
      dataField: "product",
      text: "Product",
      headerStyle: (colum, colIndex) => {
        return { width: "65px", textAlign: "center" };
      },
    },

    {
      dataField: "status",
      text: "Status",
      hidden: userdata.userType !== "Aggregate" ? false : true,
      headerStyle: (colum, colIndex) => {
        return { width: "155px", textAlign: "center" };
      },
      formatter: (cell, item) => {
        return (
          <>
            {item.status == "Draft" ? (
              <div>
                <span
                  className="btn btn-sm btn-outline btn-dark"
                  id={`draft-${item?.buyer_lead_type_id}`}
                  data-tooltip-content="Draft"
                  data-toggle="tooltip"
                  title={item.status}
                >
                  <i className="fa fa-file" aria-hidden="true"></i>
                  <Tooltip
                    place="bottom"
                    className="tool_tip_graph"
                    anchorId={`draft-${item?.buyer_lead_type_id}`}
                  />
                </span>

                <span
                  className="btn btn-sm btn-outline btn-default"
                  // onClick={() =>
                  //   this.onOpenModal_status(item.buyer_lead_type_id, "Draft")
                  // }
                  onClick={() =>
                    openWidgetModal(3, "Change Campaign Status", item)
                  }
                >
                  Change Status
                </span>
              </div>
            ) : (
              ""
            )}
            {item.status == "Inactive" ? (
              <div>
                <span
                  className="btn btn-sm btn-outline btn-danger"
                  data-toggle="tooltip"
                  id={`inactive-${item?.buyer_lead_type_id}`}
                  data-tooltip-content="Inactive"
                  title={item.status}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                  <Tooltip
                    place="bottom"
                    className="tool_tip_graph"
                    anchorId={`inactive-${item?.buyer_lead_type_id}`}
                  />
                </span>

                <span
                  className="btn btn-sm btn-outline btn-default"
                  // onClick={() =>
                  //   this.handleChangeStatus(item.buyer_lead_type_id, "Active")
                  // }
                  onClick={() =>
                    openWidgetModal(3, "Change Campaign Status", item)
                  }
                >
                  Inactive
                </span>
              </div>
            ) : (
              ""
            )}
            {item.status == "Archived" ? (
              <div>
                <span
                  className="btn btn-sm btn-outline btn-default"
                  data-toggle="tooltip"
                  id={`archived-${item?.buyer_lead_type_id}`}
                  data-tooltip-content="Archived"
                  title={item.status}
                >
                  <i className="fa fa-archive" aria-hidden="true"></i>
                  <Tooltip
                    place="bottom"
                    className="tool_tip_graph"
                    anchorId={`archived-${item?.buyer_lead_type_id}`}
                  />
                </span>

                <span className="btn btn-sm btn-outline btn-default">
                  Archived
                </span>
              </div>
            ) : (
              ""
            )}
            {item.status == "Active" ? (
              <div>
                <span
                  className="btn btn-sm btn-outline btn-success"
                  data-toggle="tooltip"
                  id={`active-${item?.buyer_lead_type_id}`}
                  data-tooltip-content="Active"
                  title={item.status}
                >
                  <i className="fa fa-check" aria-hidden="true"></i>
                  <Tooltip
                    place="bottom"
                    className="tool_tip_graph"
                    anchorId={`active-${item?.buyer_lead_type_id}`}
                  />
                </span>

                <span
                  className="btn btn-sm btn-outline btn-default"
                  onClick={() =>
                    openWidgetModal(3, "Change Campaign Status", item)
                  }
                // onClick={() =>
                //   this.handleChangeStatus(
                //     item.buyer_lead_type_id,
                //     "Inactive"
                //   )
                // }
                >
                  Active
                </span>
              </div>
            ) : (
              ""
            )}
            {item.status == "RequestActivation" ? (
              <div className="button-group-j">
                <span
                  className="btn btn-sm btn-outline btn-default"
                  data-toggle="tooltip"
                  id={`RequestActivation-${item?.buyer_lead_type_id}`}
                  data-tooltip-content="Request Activation"
                  title="Request Activation"
                >
                  <i className="fa fa-cog fa-spin" aria-hidden="true"></i>
                  <Tooltip
                    place="bottom"
                    className="tool_tip_graph"
                    anchorId={`RequestActivation-${item?.buyer_lead_type_id}`}
                  />
                </span>

                <span className="btn btn-sm btn-outline btn-default">
                  Request Activation
                </span>
              </div>
            ) : (
              ""
            )}
            {item.status == "ReadytoGoLive" ? (
              <div className="button-group-j">
                <span
                  className="btn btn-sm btn-outline btn-success"
                  id={`ReadytoGoLive-${item?.buyer_lead_type_id}`}
                  data-tooltip-content="Ready to Go Live"
                  data-toggle="tooltip"
                  title="Ready to Go Live"
                >
                  <i className="fa fa-cog fa-spin" aria-hidden="true"></i>
                  <Tooltip
                    place="bottom"
                    className="tool_tip_graph"
                    anchorId={`ReadytoGoLive-${item?.buyer_lead_type_id}`}
                  />
                </span>

                <span className="btn btn-sm btn-outline btn-default">
                  Ready to Go Live
                </span>
              </div>
            ) : (
              ""
            )}{" "}
          </>
        );
      },
    },
    {
      dataField: "Sys_lead_quality_type",
      text: "Media Channel",
      headerStyle: (colum, colIndex) => {
        return { width: "75px", textAlign: "center" };
      },
    },
    {
      dataField: "min_price_asked",
      hidden: userdata.userType !== "Aggregate" ? false : true,
      text: "Amount",
      headerStyle: (colum, colIndex) => {
        return { width: "120px", textAlign: "center" };
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex gap-0 align-items-center justify-content-center">
            {row.bid_type == "Fixed" ? cell : ""}
            {row.status == "Archived" ? (
              ""
            ) : row.bid_type == "Fixed" ? (
              <>
                <br />
                <span
                  className="btn btn-sm"
                  id={`edit-price-${row?.buyer_lead_type_id}`}
                  data-tooltip-content="Edit Price"
                  onClick={() =>
                    openWidgetModal(
                      2,
                      `${"Price Update for " + row.lead_type_name}`,
                      row
                    )
                  }
                >
                  <i className="fa fa-edit"></i>
                  <Tooltip
                    place="bottom"
                    className="tool_tip_graph"
                    anchorId={`edit-price-${row?.buyer_lead_type_id}`}
                  />
                </span>
              </>
            ) : row.product == "Clicks" ? (
              <Link
                to={"/dynamicprice/" + row.buyer_lead_type_id}
                className="btn btn-sm btn-outline btn-primary"
              >
                Dynamic Price
              </Link>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      dataField: "base_price",
      hidden: userdata.userType !== "Aggregate" ? false : true,
      text: "Avg Price",
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
    },
    {
      dataField: "make_a_change",
      hidden: userdata.userType !== "Aggregate" ? false : true,
      text: "Make a Change",
      headerStyle: (colum, colIndex) => {
        return { width: "225px", textAlign: "right" };
      },
      formatter: (cell, row) => {
        // console.log("🚀 ~ file: Campaign.js:461 ~ Campaign ~ cell:", row);

        return (
          <div className="button-group-j text-end">
            {row.auth_token ? (
              <Link
                to={
                  "/apispecs/" +
                  row?.buyer_lead_type_id +
                  "/" +
                  row.auth_token +
                  "/" +
                  row.display_name
                }
                id={`api-${row?.buyer_lead_type_id}`}
                data-tooltip-content="Api"
                className="btn btn-sm btn-outline btn-warning btn-lg  me-2"
                target="blank"
              >
                <i class="fa fa-mixcloud"></i>
                <Tooltip
                  place="bottom"
                  className="tool_tip_graph"
                  anchorId={`api-${row?.buyer_lead_type_id}`}
                />
              </Link>
            ) : (
              ""
            )}
            {
              <Link
                to={"/daywisecap/" + row?.buyer_lead_type_id}
                id={`dow-cap-${row?.buyer_lead_type_id}`}
                data-tooltip-content="DOW Cap"
                className="btn btn-sm btn-outline btn-warning btn-lg me-2"
              >
                <i class="far fa-calendar-week"></i>
                <Tooltip
                  place="bottom"
                  className="tool_tip_graph"
                  anchorId={`dow-cap-${row?.buyer_lead_type_id}`}
                />
              </Link>
            }

            {row.product == "Clicks" ? (
              <Link
                to={"/campaignads"}
                state={{ user_id: row?.buyer_lead_type_id }}
                id={`campaignads-${row?.buyer_lead_type_id}`}
                data-tooltip-content="Campaign"
                className="btn btn-sm btn-outline btn-warning btn-lg  me-2"
              >
                <i class="far fa-bullhorn"></i>
                <Tooltip
                  place="bottom"
                  className="tool_tip_graph"
                  anchorId={`campaignads-${row?.buyer_lead_type_id}`}
                />
              </Link>
            ) : (
              ""
            )}

            {
              <Link
                id={`filter-${row?.buyer_lead_type_id}`}
                data-tooltip-content="Filters"
                to={"/filter/" + row?.buyer_lead_type_id}
                className="btn btn-sm btn-outline btn-warning btn-lg"
              >
                <i class="far fa-filter"></i>
                <Tooltip
                  place="bottom"
                  className="tool_tip_graph"
                  anchorId={`filter-${row?.buyer_lead_type_id}`}
                />
              </Link>
            }
          </div>
        );
      },
    },
  ];

  const modalInputFunc = (e) => {
    setModalInput(e.target.value)
  }


  const {
    mutate,
    mutateAsync,
    isLoading: updateLoading,
    error: addError
  } = useMutation(changeCampaignName);

  const handleUpdateData = async (e) => {
    e.preventDefault()
    var changeName = {}
    if (openContent.comeFrom === 1) {

      changeName = await mutateAsync({
        display_name: modalInput,
        itemId: openContent.item.buyer_lead_type_id,
        name: 1
      });

    } else if (openContent.comeFrom === 2) {

      changeName = await mutateAsync({
        price: modalInput,
        itemId: openContent.item.buyer_lead_type_id,
        name: 2
      });

    } else {

      changeName = await mutateAsync({
        status: modalInput,
        itemId: openContent.item.buyer_lead_type_id,
        name: 3
      });

    }
    if (changeName.status === 1) {
      setOpenModal((oldState) => !oldState)
      setOpenContent("")
      setModalInput("")
      getAllCampaignListFunc({
        "pageNumber": 1,
        "perpage": 20,
        "type": campaignType
      })
    }
  };

  function Modal({ children, shown, close }) {
    return shown ? (
      <div
        className={`modal-container ${styles.modalBackdrop}`}
        onClick={() => {
          // close modal when outside of modal is clicked
          close();
        }}
      >
        <div
          className={styles.sidebar_add_widget_form__1TGwy}
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          {/* <button onClick={close}>Close</button> */}
          {children}
        </div>
      </div>
    ) : null;
  }

  const addWidgetModal = () => {
    return (
      <Modal
        shown={openModal}
        close={() => {
          setOpenModal((oldState) => !oldState)
        }}
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content add_widget_form">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {openContent.title}
              </h5>
            </div>
            <div className="modal-body">
              <form>
                {openContent.comeFrom !== 3 ? (
                  <>
                    <div className="row mx-0">
                      {openContent.comeFrom === 2 && (<p>Quality Type : {openContent.item.Sys_lead_quality_type}</p>)}
                      {openContent.comeFrom === 2 && (<p>basePrice : {openContent.item.base_price}</p>)}
                      {openContent.comeFrom === 2 && (<p>Current Price : {openContent.item.min_price_asked}</p>)}
                    </div>
                    <div className="row mx-0">
                      <div className="col-md-12 mb-6">
                        <input
                          type={openContent.comeFrom === 1 ? "text" : "number"}
                          // type="text"
                          autoFocus="autoFocus"
                          placeholder={openContent.comeFrom === 1 ? "Enter Display Name..." : "Enter Price..."}
                          class="form-control mb-3"
                          onChange={(e) => modalInputFunc(e)}
                          value={modalInput}
                        />
                      </div>
                    </div>
                  </>
                ) : (

                  <select
                    className="form-control "
                    name="status"
                    onChange={(e) => modalInputFunc(e)}
                    value={modalInput}
                  >
                    <option value="">Please Select Status</option>
                    {(openContent.item.status === "Active" || openContent.item.status === "Inactive") ?
                      <>
                        <option value="Active">Active</option>
                        <option value="Inactive">In-active</option>
                      </>
                      :
                      <>
                        <option value="Draft">Save Campaign</option>
                        <option value="ReadytoGoLive">Ready to GoLive</option>
                        <option value="RequestActivation">
                          Request Activation
                        </option>
                      </>
                    }

                  </select>
                )}
                <center>
                  <button
                    type="submit"
                    className="btn btn-primary mt-3"
                    disabled={updateLoading}
                    onClick={(e) => handleUpdateData(e)}
                  >
                    {updateLoading && <i class="fas fa-spinner fa-pulse"></i>}
                    {updateLoading ? `` : <i class="fa fa-pencil-square-o" aria-hidden="true">{" Update"}</i>}

                  </button>
                </center>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  const addWidgetModalFunc = React.useMemo(
    () => addWidgetModal(),
    [
      openModal,
      modalInput
    ]
  );

  const filterDataFunc = (item) => {
    setActiveLeads(item)
    let oldData = c_ListData?.results?.filter((chk) => chk.lead_type_name === item.lead_type_name)
    setCampaignListData({
      Records: oldData ? oldData.length : 0,
      results: oldData ? oldData : []
    })
    // getAllCampaignListFunc({
    //   "pageNumber": 1,
    //   "perpage": 20,
    //   "type": "All"
    // })
    setCampaignType("All")
  }

  // console.log(columns,campaignListData );

  return (
    <>
      <div className="page px-2 comnpadding">
        <div className="page-content container-fluid pt-3">
          <div class="comnbox mb-4">
            <div className="card">
              <div className="card-block">
                <div className="col-xl-12 col-lg-12">
                  <div className="row  justify-content-between">
                    {LNQData &&
                      LNQData.lead_results &&
                      LNQData.lead_results.length > 0 &&
                      LNQData.lead_results.map((obj) => {
                        return (
                          <div className="col-md-2 ">
                            <div
                              className={
                                activeLeads.lead_type_name ===
                                  obj.lead_type_name
                                  ? "filter-b-j active"
                                  : "filter-b-j"
                              }
                              onClick={() => filterDataFunc(obj)}
                            >
                              <div className="fil-icon">
                                <img
                                  src={obj.lead_type_icon}
                                  className="rounded"
                                  alt=""
                                />
                              </div>
                              <h6>{obj.lead_type_name}</h6>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="d-flex justify-content-between gap-3 px-3">
              <div className="clientReportTitle mb-0">
                <h3>Campaigns</h3>
              </div>
              <div className="filter-sel-j">
                <select
                  className="form-control"
                  onChange={fieldchange}
                  name="type"
                  value={campaignType}
                >
                  <option value="All">-All-</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">In-active</option>
                  <option value="Draft">Draft</option>
                  <option value="Archived">Archived</option>
                  <option value="ReadytoGoLive">Ready to GoLive</option>
                  <option value="RequestActivation">
                    Request Activation
                  </option>
                  <option value="SaveCampaign">Save Campaign</option>
                </select>
              </div>

              <div className="text-end">
                <Link
                  to={"/addCampaign"}
                  id="add-campaign"
                  data-tooltip-content="Add Campaign"
                  className={"btn btn-icon btn-primary btn-outline"}
                >
                  <i className="fa fa-plus"></i>
                  <Tooltip
                    place="bottom"
                    className="tool_tip_graph"
                    anchorId="add-campaign"
                  />
                </Link>
              </div>
            </div>
            <DynamicTable
              mainLoader={mainLoading}
              formData={campaignListData}
              filterLoader={false}
              //   handleFilterGenerateBtn={handleFilterGenerateBtn}
              filterData={[]}
              customColumnsData={columns}
              mode={"data"}
            />
          </div>
          {addWidgetModalFunc}
        </div>
      </div>
    </>
  );
}

export default Campaign