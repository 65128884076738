import { ledgerbookActionTypes } from "../constants";

export const getLedgerbooks = () => {
  return {
    type: ledgerbookActionTypes.LEDGER_BOOK_LOADING,
  };
};


export const getLedgerbookSuccess = (data) => {
  return {
    type: ledgerbookActionTypes.LEDGER_BOOK__SUCCESS,
    payload: data,
  };
};

export const getLedgerbookError = (data) => {
  return {
    type: ledgerbookActionTypes.LEDGER_BOOK__ERROR,
    payload: data,
  };
};

export const getLedgerbookReset = () => {
  return {
    type: ledgerbookActionTypes.LEDGER_BOOK__RESET,
  };
};
