import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicTable from "../../common/DynamicTable";
import { AnalyticsService } from "../../services";
import moment from "moment/moment";

const Analytics = () => {
  const backToTopRef = React.useRef(null);
  const dispatch = useDispatch();
  const [filterLoader, setFilterLoader] = useState(false);

  let token = localStorage.getItem("access_token");
  const {
    data: analyticsRawData,
    loading,
    error,
  } = useSelector((state) => state.analytic);

  useEffect(() => {
    let req = {
      type: "getBuyerAnalytics",
      filter: {
        product: "All",
        campaign_id: "All",
        from_date: moment(new Date()).format("YYYY-MM-DD"),
        to_date: moment(new Date()).format("YYYY-MM-DD"),
        report_type: "summary",
      },
      token: token,
    };
    {
      token && dispatch(AnalyticsService.AnalyticsList(req));
    }
  }, [dispatch]);
  useEffect(() => {
    if (error) {
      setFilterLoader(false);
    }
  }, [error]);

  useEffect(() => {
    setFilterLoader(false);
  }, [analyticsRawData]);

  const handleFilterGenerateBtn = (data) => {
    setFilterLoader(true);
    const filter = ["date", "campaign_id", "product", "report_type"];

    let formData = {};
    let ProductParameter = filter && filter?.find((er) => er === "product");

    let DateParameter = filter && filter?.find((er) => er === "date");
    let campaignIdParameter =
      filter && filter?.find((er) => er === "campaign_id");

    let reportTypeParameter =
      filter && filter?.find((er) => er === "report_type");

    if (DateParameter) {
      formData.from_date = moment(data?.fromDate).format("YYYY-MM-DD");
      formData.to_date = moment(data?.toDate).format("YYYY-MM-DD");
    }
    if (ProductParameter) {
      formData.product = data.selectedProduct ? data.selectedProduct : "All";
    }
    if (campaignIdParameter) {
      formData.campaign_id = data.campaign_id ? data.campaign_id : "All";
    }
    if (reportTypeParameter) {
      formData.report_type = data.report_type ? data.report_type : "summary";
    }
    // console.log(formData);
    let req = {
      type: "getBuyerAnalytics",
      filter: formData,
      token,
    };
    {
      token && dispatch(AnalyticsService.AnalyticsList(req));
    }
  };

  return (
    <div className="m-2" ref={backToTopRef}>
      <DynamicTable
        mainLoader={loading && !filterLoader}
        backToTopRef={backToTopRef}
        formData={analyticsRawData?.responsedata}
        filterLoader={filterLoader}
        handleFilterGenerateBtn={handleFilterGenerateBtn}
        pageTitle="Analytics"
        filterData={["date", "campaign_id", "product", "report_type"]}
        mode={"data"}
        analyticColumnData={true}
      />
    </div>
  );
};

export default Analytics;
