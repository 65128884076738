import { analyticsActionTypes } from "../constants";

export const getAnalytics = () => {
  return {
    type: analyticsActionTypes.ANALYTICS_LOADING,
  };
};


export const getAnalyticsSuccess = (data) => {
  return {
    type: analyticsActionTypes.ANALYTICS_SUCCESS,
    payload: data,
  };
};

export const getAnalyticsError = (data) => {
  return {
    type: analyticsActionTypes.ANALYTICS_ERROR,
    payload: data,
  };
};

export const getAnalyticsReset = () => {
  return {
    type: analyticsActionTypes.ANALYTICS_RESET,
  };
};
