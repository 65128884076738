export const authActions = {
  //Logout
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR",
  MESSAGE_TEXT: "MESSAGE_TEXT",
  MESSAGE_TEXT_RESET: "MESSAGE_TEXT_RESET",

  //Login Actions
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_INVALID: "LOGIN_INVALID",
  RESET_LOGIN: "RESET_LOGIN",
  CLEAR_LOGIN_ERROR: "CLEAR_LOGIN_ERROR",
  FUNDS: "FUNDS",
};
