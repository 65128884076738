/* App.js */
import React from "react";
import { useSelector } from "react-redux";
import CanvasJSReact from "../../assets/canvasjs.react";
import { MainLoader, Mini } from "../../common/Loader";

// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Graph = ({ loading }) => {

  const { countData } = useSelector((state) => state?.campaign);
  // console.log("🚀 ~ file: Graph.js:12 ~ Graph ~ countData:", countData)

  const toggleDataSeries = (e) => {
    if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    e.chart.render();
  };

  const options = {
    animationEnabled: true,
    // exportEnabled: true,
    theme: "light2", //"light1", "dark1", "dark2"
    // title: {
    //     text: "Simple Column Chart with Index Labels"
    // },
    height: 420,
    axisY: {
      includeZero: true,
    },
    toolTip: {
      shared: true,
    },
    toolTip: {
      shared: true,
    },
    legend: {
      fontSize: 13,
      fontColor: "#000",
      cursor: "pointer",
      itemclick: toggleDataSeries,
      // verticalAlign: "top"
    },

    data: countData?.canvasdata,
  };

  return (
    <div className="m-2 mt-4">
      {loading ? <Mini /> :
        (
          <CanvasJSChart
            options={options}
          /* onRef={ref => this.chart = ref} */
          />
       )}
    </div>
  );
};

export default Graph;