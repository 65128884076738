import { campaignActionTypes } from "../constants";

const initialState = {
  loading: false,
  campaigns: [],
  campaignsError: "",
  countLoading: false,
  countData: [],
  countError: "",
  campaignListLoading: false,
  campaignListData: [],
  campaignListError: "",
  LNQLoading: false,
  LNQData: [],
  LNQError: "",
  CampaignStatusLoading: false,
  CampaignStatusData: [],
  CampaignStatusError: "",
  AddCampaignLoading: false,
  AddCampaignData: false,
  AddCampaignMsg: "",
  AddCampaignError: "",
};

export const campaign = (state = initialState, action) => {
  switch (action.type) {
    case campaignActionTypes.GET_CAMPAIGN:
      return {
        ...state,
        loading: true,
      };
    case campaignActionTypes.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: action.payload,
        campaignsError: "",
      };
    case campaignActionTypes.GET_CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        campaignsError: action.payload,
        campaigns: []
      };

    case campaignActionTypes.CLEAR_CAMPAIGN_DETAILS:
      return {
        ...state,
        campaignsError: "",
        campaigns: []
      };
    case campaignActionTypes.GET_COUNT:
      return {
        ...state,
        countLoading: true,
      };
    case campaignActionTypes.GET_COUNT_SUCCESS:
      return {
        ...state,
        countLoading: false,
        countData: action.payload,
        countError: "",
      };
    case campaignActionTypes.GET_COUNT_ERROR:
      return {
        ...state,
        countLoading: false,
        countError: action.payload,
      };
    case campaignActionTypes.GET_CAMPAIGN_LIST:
      return {
        ...state,
        campaignListLoading: true,
      };
    case campaignActionTypes.GET_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        campaignListLoading: false,
        campaignListData: action.payload,
        campaignListError: "",
      };
    case campaignActionTypes.GET_CAMPAIGN_LIST_ERROR:
      return {
        ...state,
        campaignListLoading: false,
        campaignListError: action.payload,
      };
    case campaignActionTypes.GET_LEADTYPE_N_QUALITY:
      return {
        ...state,
        LNQLoading: true,
      };
    case campaignActionTypes.GET_LEADTYPE_N_QUALITY_SUCCESS:
      return {
        ...state,
        LNQLoading: false,
        LNQData: action.payload,
        LNQError: "",
      };
    case campaignActionTypes.GET_LEADTYPE_N_QUALITY_ERROR:
      return {
        ...state,
        LNQLoading: false,
        LNQError: action.payload,
      };




    case campaignActionTypes.GET_CAMPAIGN_STATUS:
      return {
        ...state,
        CampaignStatusLoading: true,
      };
    case campaignActionTypes.GET_CAMPAIGN_STATUS_SUCCESS:
      return {
        ...state,
        CampaignStatusLoading: false,
        CampaignStatusData: action.payload,
        CampaignStatusError: "",
      };
    case campaignActionTypes.GET_CAMPAIGN_STATUS_ERROR:
      return {
        ...state,
        CampaignStatusLoading: false,
        CampaignStatusError: action.payload,
      };
    case campaignActionTypes.GET_CAMPAIGN_STATUS_RESET:
      return {
        ...state,
        CampaignStatusLoading: false,
        CampaignStatusData: [],
        CampaignStatusError: "",
      };


    case campaignActionTypes.ADD_CAMPAIGN:
      return {
        ...state,
        AddCampaignLoading: true,
      };
    case campaignActionTypes.ADD_CAMPAIGN_SUCCESS:
      return {
        ...state,
        AddCampaignLoading: false,
        AddCampaignData: action.payload.status,
        AddCampaignMsg: action.payload.msg,
        AddCampaignError: "",
      };
    case campaignActionTypes.ADD_CAMPAIGN_ERROR:
      return {
        ...state,
        AddCampaignLoading: false,
        AddCampaignError: action.payload.status,
        AddCampaignMsg: action.payload.msg,
      };
    case campaignActionTypes.ADD_CAMPAIGN_RESET:
      return {
        ...state,
        AddCampaignLoading: false,
        AddCampaignData: false,
        AddCampaignError: "",
        AddCampaignError: "",
      };

    default:
      return state;
  }
};
