import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { CampaignService } from "../../services";
import { addCampaignReset, getCampaignStatusReset } from '../../store/actions';
import { useForm } from "react-hook-form";
import { Tooltip } from 'react-tooltip';
import { successToaster } from '../../common/toaster';


const AddCompaign = () => {


  const { register, handleSubmit, formState: { errors } } = useForm();
  console.log("🚀 ~ file: addCompaign.js:15 ~ AddCompaign ~ errors:", errors)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { LNQLoading,
    LNQData,
    LNQError,
    CampaignStatusData,
    CampaignStatusError,
    CampaignStatusLoading,
    AddCampaignLoading,
    AddCampaignData,
    AddCampaignError,
    AddCampaignMsg,
  } = useSelector((state) => state.campaign);

  const [lobArray, setLobArray] = React.useState([]);
  const [productArray, setProductArray] = React.useState([]);
  const [qualityMediaArray, setQualityMediaArray] = React.useState([]);

  const [lob, setLob] = React.useState("");
  const [product, setProduct] = React.useState("");
  const [qualityMedia, setQualityMedia] = React.useState(3);
  const [status, setStatus] = React.useState("ReadytoGoLive");
  const [displayName, setDisplayName] = React.useState("");
  const [bidPrice, setBidPrice] = React.useState(0);
  const [basePrice, setBasePrice] = React.useState("");



  const getAllCampaignListFunc = () => {
    dispatch(CampaignService.getLeadtypeandQuality())
  }

  const fieldchange = (e) => {
    let fields = e.target.name
    let productVal = e.target.value

    if (fields === "lob") {
      setLob(productVal)
    } else if (fields === "product") {
      setProduct(productVal)
    } else if (fields === "qualityMedia") {
      setQualityMedia(productVal)
    } else {
      setStatus(productVal)
    }
  }

  React.useEffect(() => {
    if (lob !== "") {
      dispatch(CampaignService.CheckCampaignStatus({
        "lead_type": lob,
        "quality_type": qualityMedia
      }))
    }

    let lobName = lobArray.find(obj => obj.lead_type_id == lob)?.lead_type_name
    let productName = productArray.find(obj => obj.product_id == product)?.name
    let qualityMediaName = qualityMediaArray.find(obj => obj.quality_id == qualityMedia)?.name
    let campaignName = (productName ? productName + "-" : "") + (lobName ? lobName + "-" : "") + (qualityMediaName ? qualityMediaName : "")
    // setDisplayName(campaignName)

  }, [lob, product, qualityMedia])

  React.useEffect(() => {
    getAllCampaignListFunc()
    dispatch(getCampaignStatusReset())
    setBasePrice("")
  }, [])

  React.useEffect(() => {
    if (CampaignStatusData?.base_price) {
      setBasePrice(CampaignStatusData?.base_price)
    } else {
      setBasePrice("")
    }
  }, [CampaignStatusData])


  React.useEffect(() => {
    if (LNQData) {
      setLobArray(LNQData.lead_results)
      setProductArray(LNQData.product_results)
      setQualityMediaArray(LNQData.quality_results)
    }
  }, [LNQData])

  React.useEffect(() => {
    if (AddCampaignData) {
      setTimeout(() => {
        dispatch(addCampaignReset())
      }, 1000);
      navigate("/campaign")
      setLob("")
      setProduct("")
      setDisplayName("")
      setBidPrice(0)
      setBasePrice("")
      setQualityMedia("")
      setStatus("")
      successToaster(AddCampaignMsg)
    }
  }, [AddCampaignData])


  const onLogin = (data) => {
    // console.log("🚀 ~ file: addCompaign.js:85 ~ onLogin ~ data:", data)
    let productName = productArray.find(obj => obj.product_id == data.product)?.name

    let req = {
      "product": productName,
      "lead_type_id": data.lob,
      "quality_id": qualityMedia,
      "base_price": basePrice,
      "bid_price": data.bid_price,
      "status": status,
      "display_name": displayName
    }
    // let req = {
    //   "product": productName,
    //   "lead_type_id": data.lob,
    //   "quality_id": data.qualityMedia,
    //   "base_price": basePrice,
    //   "bid_price": data.bid_price,
    //   "status": data.status,
    //   "display_name": displayName
    // }
    dispatch(CampaignService.AddCampaign(req))

  };

  const bidPriceFunc = (val) => {
    setBidPrice(val)
  }

  return (
    <div className="page comnpadding changepassword-box">
      <div className="page-content">
        <form onSubmit={handleSubmit(onLogin)}>
          <div class="comnbox">
            <div className="col-xl-12 col-lg-12 p-2">
              <div className="clientReportTitle gap-3 justify-content-start mb-3">
                <Link
                  id="backbtn"
                  data-tooltip-content="Back"
                  to={"/campaign"}
                  className="text-blute"
                >
                  <i className="fa fa-arrow-left"></i>
                </Link>
                <Tooltip
                  place="bottom"
                  className="tool_tip_graph"
                  anchorId="backbtn"
                />
                <h3>Add Campagin</h3>
              </div>
              <div className="row g-4">
                <div className="col-md-6">
                  <div class="form-label">Product</div>
                  <select
                    className="form-control"
                    {...register("product", { required: true })}
                    value={product}
                    onChange={fieldchange}
                    name="product"
                  >
                    <option value="">-Select Product-</option>
                    {productArray &&
                      productArray.length > 0 &&
                      productArray.map((item) => (
                        <option
                          key={item.product_id}
                          value={item.product_id}
                          data-name={item.name}
                        >
                          {item.name}
                        </option>
                      ))}
                  </select>
                  {errors.product && <p>
                    <label className="text-danger">
                      Please Select Product !
                    </label>
                  </p>}
                </div>
                <div className="col-md-6">
                  <div class="form-label">Line of Business</div>
                  <select
                    className="form-control"
                    {...register("lob", { required: true })}
                    value={lob}
                    onChange={fieldchange}
                    name="lob"
                  >
                    <option value="">-Select Line of Business-</option>
                    {lobArray &&
                      lobArray.length > 0 &&
                      lobArray.map((item) => (
                        <option
                          key={item.lead_type_id}
                          value={item.lead_type_id}
                          data-name={item.lead_type_name}
                        >
                          {item.lead_type_name}
                        </option>
                      ))}
                  </select>
                  {errors.lob && <p>
                    <label className="text-danger">
                      Please Select Line of Business !
                    </label>
                  </p>}
                </div>
                {/* <div className="col-md-6">
                  <div class="form-label">Media Channel</div>
                  <select
                    className="form-control"
                    {...register("qualityMedia", { required: true })}
                    value={qualityMedia}
                    onChange={fieldchange}
                    name="qualityMedia"
                  >
                    <option value="">-Select Media Channel-</option>
                    {qualityMediaArray &&
                      qualityMediaArray.length > 0 &&
                      qualityMediaArray.map((item) => (
                        <option
                          key={item.quality_id}
                          value={item.quality_id}
                          data-name={item.name}
                        >
                          {item.name}
                        </option>
                      ))}
                  </select>
                  {errors.qualityMedia && <p>
                    <label className="text-danger">
                      Please Select Media Channel !
                    </label>
                  </p>}
                </div> */}

                {/* <div className="col-md-6">
                  <div class="form-label">Base Price</div>
                  <input
                    type="text"
                    // {...register("base_price", { required: true, })}
                    className="form-control"
                    readOnly
                    placeholder="Base Price"
                    defaultValue={basePrice}
                    name="base_price"
                  />
                </div>
                {errors.base_price && <p>Please Add base Price !</p>} */}

                <div className="col-md-6">
                  <div class="form-label">Bid Price</div>
                  <input
                    type="number"
                    {...register("bid_price", { required: true, validate: (value) => value > (basePrice - 1) })}
                    className="form-control"
                    placeholder="Bid Price"
                    value={bidPrice}
                    min={0}
                    onChange={(e) => bidPriceFunc(e.target.value)}
                    // onKeyUp={this.handleChange}
                    name="bid_price"
                  />
                  {errors.bid_price && <p>
                    <label className="text-danger">
                     Please Add Bid Price Greater then or equal to {basePrice}  !
                    </label>
                  </p>}
                </div>

                <div className="col-md-6">
                  <div class="form-label">Display Name</div>
                  <input
                    {...register("display_name", { required: true, })}
                    type="text"
                    className="form-control"
                    placeholder="Display Name"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    // onKeyUp={(e) => setDisplayName(e.target.value)}
                    name="display_name"
                  />
                  {errors.display_name && <p>
                    <label className="text-danger">
                      Please Add Display Name !
                    </label>
                  </p>}
                </div>
                {/* <div className="col-md-6">
                  <div class="form-label">Status</div>
                  <select
                    class="form-control"
                    {...register("status", { required: true })}
                    value={status}
                    onChange={fieldchange}
                    name="status"
                  >
                    <option value="Draft">Save Campaign</option>
                    <option value="ReadytoGoLive">Ready to GoLive</option>
                    <option value="RequestActivation">Request Activation</option>
                  </select>
                </div>
                {errors.status && <p>Please Select Status !</p>} */}

                <div className="col-md-12 text-end align-self-end">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                  // disabled={loader}
                  >
                    {/* {loader && <i class="fas fa-spinner fa-pulse"></i>}
                                    {loader ? `` : `Login`} */}
                    <i class="fa fa-floppy-o">{" Save"}</i>
                  </button>
                </div>
              </div>
              {/* <div className={"btn btn-icon btn-primary btn-outline"}>

                                    <i class="fa fa-floppy-o">{" Save"}</i>
                                </div> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddCompaign