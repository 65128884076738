import axios from "axios";
import {
  // tickerLoading,
  // tickerSuccess,
  // tickerError,
  // getWidgetError,
  getAnalytics,
  getAnalyticsSuccess,
  getAnalyticsError,
  loginError,
} from "../store/actions";

export class AnalyticsService {
  static AnalyticsList = (req) => {
    // console.log("🚀 ~ file: ticker.js:46 ~ TickerService ~ req", req);
    return (dispatch) => {
      dispatch(getAnalytics());
      axios
        .post(`${process.env.REACT_APP_API_URL + "/" + req.type}`, req.filter, {
          headers: {
            "content-Type": "application/json",
            session: `${req.token}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
          },
        })
        .then((response) => {
          console.log("response.data", response.data);
          if (response.data.status === 1) {
            dispatch(getAnalyticsSuccess(response.data));
          } else if (response.data.message === "Record not found") {
            console.log("sdf");
            dispatch(getAnalyticsSuccess(response.data));
          } else {
            dispatch(getAnalyticsError(response.data.message));
          }
        })
        .catch((error) => {
          console.log(
            "🚀 ~ file: analytics.js:36 ~ AnalyticsService ~ return ~ error",
            error
          );
          if (error.response.data === "Unauthorized.") {
            dispatch(loginError("Session Expired !"));
            global.localStorage.clear();
          } else {
            console.log("Login error");
            // global.localStorage.clear();
            dispatch(getAnalyticsError("No Data Found !"));
          }
        });
    };
  };
}
