import React from 'react';
import { useMutation, useQuery } from "react-query";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

let weekday = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'];

const priceAPI = async (req) => {
    let query = {}
    if (req.name === 2) {
        query = req
    } else {
        query = req.queryKey[1]
    }
    let url = query.name === 1
        ? `${process.env.REACT_APP_API_URL}/getdynamicprice`
        : `${process.env.REACT_APP_API_URL}/getupdatedynamicprice`

    delete query["name"]
    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(query),
        headers: {
            "content-Type": "application/json",
            session: `${localStorage.getItem("access_token")}`,
            Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
        }
    });

    if (!response.ok) {
        throw new Error("Something went wrong!");
    }

    return response.json();
};



const DynamicPrice = () => {

    let { userId } = useParams();
    const navigate = useNavigate();

    const [priceData, setPriceData] = React.useState([]);
    const [fields, setFields] = React.useState([]);


    const {
        mutate,
        mutateAsync,
        isLoading: updateLoading,
        error: addError
    } = useMutation(priceAPI);

    const getPriceData = async (e) => {
        e.preventDefault()
        var changeValue = {}
        changeValue = await mutateAsync({
            campaign_id: userId,
            data: fields,
            name: 2
        });

        if (changeValue.status === 1) {
            navigate("/campaign")
            setPriceData([])
            setFields([])
        }
    };

    const { data, error, status, isLoading: getPriceDataLoader } = useQuery(["asd", {
        campaign_id: userId,
        name: 1
    }], priceAPI);

    React.useEffect(() => {
        if (data?.status === 1) {
            setPriceData(data.responsedata)
            const dataVal = data.responsedata.campaign_price;
            var fieldsArray = fields;
            dataVal && dataVal.map(function (map) {
                fieldsArray.push({
                    priceid: map.priceid,
                    statecode: map.statecode,
                    stateprice: map.stateprice,
                    statename: map.statename,
                    errorsmsg: "",
                });
            });
            setFields(fieldsArray)
        } else {

            setPriceData([])
            setFields([])

        }
    }, [data])

    var questionNodes = fields && fields.map((question, i) => {
        var updateTextBox = (e) => {
            var stateCopy = Object.assign({}, priceData);
            stateCopy.campaign_price[i].stateprice = e.target.value;
            setFields(stateCopy.campaign_price)
            setPriceData(stateCopy);
        };

        return (
            <div className="col-sm-6 col-md-3" key={question.priceid}>
                <div class="example example-grid p-1">
                    <div class="row">
                        <div class="col-lg-3 align-self-center text-end">
                            <label className='mb-3'>
                                {question.statecode} : <span className="red">*</span>
                            </label>
                        </div>
                        <div class="col-lg-9 mt--5">
                            <input
                                type="number"
                                step="0.1"
                                className="form-control"
                                // placeholder={question.statename}
                                defaultValue={question.stateprice}
                                onChange={updateTextBox}
                                onKeyUp={updateTextBox}
                            />
                            <div className="errorMsg">{question.errorsmsg}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });


    return (
      <>
        <div className="page px-2 comnpadding">
          <div className="page-content container-fluid">
            <div class="comnbox">
              <div class="page-header">
                <div class="clientReportTitle  gap-3  justify-content-start mb-3">
                  <Link
                    id="backbtn"
                    data-tooltip-content="Back"
                    to={"/campaign"}
                    className="text-blute"
                  >
                    <i className="fa fa-arrow-left"></i>
                  </Link>
                  <Tooltip
                    place="bottom"
                    className="tool_tip_graph"
                    anchorId="backbtn"
                  />
                  <h3>Dynamic Price</h3>
                </div>
                <div class="page-header-actions">
                  <ol class="breadcrumb ps-2">
                    <strong>
                      {priceData.Campaign_code &&
                        `[ Campaign : ${priceData.Campaign_code} ]`}
                    </strong>
                    <div></div>
                  </ol>
                </div>
              </div>
              <div className="row">
                {questionNodes}
                <div className="col-12 text-end">
                  <button
                    type="submit"
                    className="btn btn-primary mt-3"
                    disabled={updateLoading}
                    onClick={(e) => getPriceData(e)}
                  >
                    {updateLoading ? (
                      <button className="btn btn-primary">
                        <i className="fa fa-spinner fa-spin"></i>
                      </button>
                    ) : (
                      <i class="fa fa-pencil-square-o" aria-hidden="true">
                        {" Update"}
                      </i>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default DynamicPrice