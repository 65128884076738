import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicTable from "../../common/DynamicTable";
import { AnalyticsService } from "../../services";
import moment from "moment/moment";
import { LedgersBooksService } from "../../services/ledgerbook";

const LedgerBook= () => {
    const backToTopRef = React.useRef(null);
    const dispatch = useDispatch();
    const [filterLoader, setFilterLoader] = useState(false);
  
    let token = localStorage.getItem("access_token");
    const {
      data: ledgersRawData,
      loading,
      error,
    } = useSelector((state) => state.ledgerBook);

    useEffect(() => {
      let req = {
        type: "getPaymentHistory",
        filter: {
          from_date: moment(new Date()).format("YYYY-MM-DD"),
          to_date: moment(new Date()).format("YYYY-MM-DD"),
        },
        token: token,
      };
      {
        token && dispatch(LedgersBooksService.LedgersList(req));
      }
    }, [dispatch]);
    
    useEffect(() => {
      if (error) {
        setFilterLoader(false);
      }
    }, [error]);
  
    useEffect(() => {
      setFilterLoader(false);
    }, [ledgersRawData]);
  
    const handleFilterGenerateBtn = (data) => {
      setFilterLoader(true);
      const filter = ["date","transaction_type"];
  
      let formData = {};
  
      let DateParameter = filter && filter?.find((er) => er === "date");
      let transactionTypeTypeParameter = filter && filter?.find((er) => er === "transaction_type");
      
      if (DateParameter) {
        formData.from_date = moment(data?.fromDate).format("YYYY-MM-DD");
        formData.to_date = moment(data?.toDate).format("YYYY-MM-DD");
      }
      if (transactionTypeTypeParameter) {
        formData.transaction_type = data.transaction_type;
      }
      let req = {
        type: "getPaymentHistory",
        filter: formData,
        token,
      };
      {
        token && dispatch(LedgersBooksService.LedgersList(req));
      }
    };
  return (
    <div className="m-2" ref={backToTopRef}>
    <DynamicTable
      mainLoader={loading && !filterLoader}
      backToTopRef={backToTopRef}
      formData={ledgersRawData?.responsedata}
      filterLoader={filterLoader}
      handleFilterGenerateBtn={handleFilterGenerateBtn}
      pageTitle="Ledger Book"
      filterData={["date","transaction_type"]}
      ledgerbookdata={true}
    />
  </div>
  )
}

export default LedgerBook