import { PaymentElement } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { updateFunds } from "../../store/actions";

export default function CheckoutForm({ resetModal, amount }) {
  const stripe = useStripe();
  const elements = useElements();
  let dispatch = useDispatch();

  const { PBKey } = useSelector((state) => state.login);

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: `${window.location.origin}/completion`,
      },
      redirect: 'if_required'
    });
    // console.log("🚀 ~ file: CheckoutForm.js:31 ~ handleSubmit ~ response:", response)
    if (response.error) {
      setMessage(response.error.message);
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/getStripeResponse`, {
        method: "POST",
        body: JSON.stringify({
          "paymentResponse": response.paymentIntent,
          amount
        }),
        headers: {
          "content-Type": "application/json",
          session: `${localStorage.getItem("access_token")}`,
          Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
        },
      }).then(async (result) => {
        var clientSecret = await result.json();
        if (clientSecret.status === 1) {
          dispatch(updateFunds({
            "funds": clientSecret.responsedata.getBalance,
            "PBKey": PBKey
          }))
        }
        resetModal()
      }).catch((err) => {
        resetModal()
        console.log(err);
      });
    }

    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occured.");
    // }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" >
      <PaymentElement id="payment-element" />
      <center>
        <button
          // type="submit"
          onClick={(e) => handleSubmit(e)}
          className="btn btn-primary mt-3"
          disabled={isProcessing || !stripe || !elements}
          id="submit"
        >
          {isProcessing ? "Processing ... " : "Pay now"}
        </button>
        {/* <button disabled={isProcessing || !stripe || !elements} id="submit">
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Pay now"}
          </span>
        </button> */}
      </center>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
