import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicTable from "../../common/DynamicTable";
import { AnalyticsService } from "../../services";
import moment from "moment/moment";

const Geo_Activity = () => {
  const backToTopRef = React.useRef(null);
  const dispatch = useDispatch();
  const [filterLoader, setFilterLoader] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([]);

  let token = localStorage.getItem("access_token");
  const {
    data: analyticsRawData,
    loading,
    error,
  } = useSelector((state) => state.analytic);

  useEffect(() => {
    let req = {
      type: "getGeoActivityMap",
      filter: {
        campaign_id: "",
        from_date: moment(new Date()).format("YYYY-MM-DD"),
        to_date: moment(new Date()).format("YYYY-MM-DD"),
      },
      token: token,
    };
    {
      token && dispatch(AnalyticsService.AnalyticsList(req));
    }
  }, []);
  useEffect(() => {
    if (error) {
      setFilterLoader(false);
    }
  }, [error]);

  useEffect(() => {
    setFilterLoader(false);
    if (analyticsRawData) {
      let newData = {
        value: analyticsRawData,
        totalArray: {
          state: "Total",
          count: analyticsRawData?.totalCount,
          Revenue: analyticsRawData?.totalRevenue,
        },
      };

      setAnalyticsData(newData);
    }
  }, [analyticsRawData]);

  const handleFilterGenerateBtn = (data) => {
    setFilterLoader(true);
    const filter = ["date", "campaign_id"];

    let formData = {};
    let DateParameter = filter && filter?.find((er) => er === "date");
    let campaignIdParameter =
      filter && filter?.find((er) => er === "campaign_id");

    if (DateParameter) {
      formData.from_date = moment(data?.fromDate).format("YYYY-MM-DD");
      formData.to_date = moment(data?.toDate).format("YYYY-MM-DD");
    }
    if (campaignIdParameter) {
      formData.campaign_id = data?.campaignId
        ? data?.campaignId.map((el) => el.value)
        : "";
    }

    let req = {
      type: "getGeoActivityMap",
      filter: formData,
      token,
    };
    {
      token && dispatch(AnalyticsService.AnalyticsList(req));
    }
  };

  return (
    <div className="m-2" ref={backToTopRef}>
      <DynamicTable
        mainLoader={loading && !filterLoader}
        backToTopRef={backToTopRef}
        formData={analyticsData}
        filterLoader={filterLoader}
        handleFilterGenerateBtn={handleFilterGenerateBtn}
        pageTitle="Geo Activity Map"
        filterData={["date", "campaign_id"]}
        mode={"both"}
        geoActivityData={true}
        hideCustomToggleList={true}
      />
    </div>
  );
};

export default Geo_Activity;
